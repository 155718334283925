import React from 'react';
import { TouchableOpacity, SafeAreaView } from 'react-native';
import { ScrollView } from 'react-navigation';
import { useNavigation } from 'react-navigation-hooks';
import { FontAwesome } from '@expo/vector-icons';
import gql from 'graphql-tag';

import { Container } from '../../components/Container';
import { ListCard } from '../../components/ListCard';
import { TechniquesList } from '../../components/TechniquesList';
import { colors } from '../../designSystem';
import { NavigationScreenType } from '../../utils/navigationUtils';
import { ErrorMessage, missingDataError } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { useLessonGroupScreenQuery } from '../../graphql/types';
import { curriculumLessonGroup } from '../../graphql/fragments';

export const LessonGroupScreenQuery = gql`
  query LessonGroupScreen($lessonGroupId: Int!) {
    lessonGroup(id: $lessonGroupId) {
      ...CurriculumLessonGroup
    }
  }
  ${curriculumLessonGroup}
`;

export const LessonGroupScreen: NavigationScreenType = () => {
  const { navigate, getParam } = useNavigation();
  const moduleTitle = getParam('moduleTitle');

  const { data, loading, error } = useLessonGroupScreenQuery({
    variables: {
      lessonGroupId: getParam('lessonGroupId'),
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.lessonGroup || !data.lessonGroup.lessons)
    return <ErrorMessage error={missingDataError('LessonGroupScreen')} />;

  const lessonGroup = data.lessonGroup;
  const lessonGroupTitle = lessonGroup.title;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <Container>
          <TouchableOpacity
            onPress={() => {
              navigate('LessonIntroScreen', {
                lessonGroupVideos: JSON.stringify(
                  lessonGroup.lessonGroupVideos
                ),
              });
            }}
          >
            <ListCard
              header={'INTRODUCTION'}
              title={moduleTitle + ': ' + lessonGroupTitle}
              inverse
            >
              <FontAwesome name="youtube-play" size={24} color={colors.white} />
            </ListCard>
          </TouchableOpacity>
          <TechniquesList
            restrictAccess
            techniques={lessonGroup.lessons.map(lesson => ({
              type: 'lesson',
              lesson,
              moduleTitle,
              lessonGroupTitle,
            }))}
          />
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
};

LessonGroupScreen.navigationOptions = ({ navigation }) => ({
  title: navigation.getParam('moduleTitle'),
});
