import React from 'react';
import { ScrollView, SafeAreaView } from 'react-native';
import { colors, rhythm } from '../../designSystem';
import { Container } from '../../components/Container';
import { VideoCard } from '../../components/VideoCard';
import { useNavigation } from 'react-navigation-hooks';
import { LessonGroupVideoFragment } from '../../graphql/types';

export const LessonIntroScreen: React.FC = () => {
  const { getParam } = useNavigation();

  const lessonGroupVideos = JSON.parse(
    getParam('lessonGroupVideos')
  ) as LessonGroupVideoFragment[];

  return (
    <ScrollView
      style={{ backgroundColor: colors.lightGrey, marginBottom: rhythm[5] }}
    >
      <SafeAreaView style={{ flex: 1, paddingTop: rhythm[3] }}>
        <Container>
          {lessonGroupVideos.map(lessonGroupVideo => {
            const [title, description] = (
              lessonGroupVideo.description || ''
            ).split('\n');
            if (!lessonGroupVideo.videoUrl || !lessonGroupVideo.s3ThumbnailUrl)
              throw new Error(
                'lesson group video should have video url & thumbnail'
              );
            return (
              <VideoCard
                key={lessonGroupVideo.id}
                header=""
                title={title}
                description={description}
                videoUrl={lessonGroupVideo.videoUrl}
                posterSource={lessonGroupVideo.s3ThumbnailUrl}
              />
            );
          })}
        </Container>
      </SafeAreaView>
    </ScrollView>
  );
};
