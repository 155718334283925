import gql from 'graphql-tag';
import { userInfo, settings, messagesWithAttachments } from './fragments';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    user {
      ...User
    }
  }
  ${userInfo}
`;

export const SETTINGS_SCREEN_QUERY = gql`
  query Settings {
    userSettings @client {
      ...Settings
    }
  }
  ${settings}
`;

export const CHAT_SINGLE_THREAD = gql`
  query ChatThread($threadId: Int!) {
    messageThread(id: $threadId) {
      ...CurrentThreadInfo
    }
  }
  fragment CurrentThreadInfo on MessageThreadType {
    id
    subject
    ...MessagesWithAttachments
    student {
      id
      user {
        id
        ...User
      }
      segments {
        id
        createdAt
      }
    }
    mentor {
      id
      user {
        id
        ...User
      }
    }
  }
  ${messagesWithAttachments}
`;
export const CHAT_ALL_THREADS = gql`
  query ChatAllThreads($relationshipId: Int, $hasAttachments: Boolean = false) {
    messageThreads(
      relationshipId: $relationshipId
      hasAttachments: $hasAttachments
    ) {
      ...CarouselData
    }
  }
  fragment CarouselData on MessageThreadType {
    id
    subject
    unreadCount
    s3ThumbnailUrl
    lastMessageAt
    exercises {
      id
    }
  }
`;

export const CHAT_ACTIVITY = gql`
  query ChatActivity($relationshipId: Int, $since: DateTime) {
    chats(relationshipId: $relationshipId, since: $since) {
      since
      chats {
        __typename
        ... on MessageType {
          ...MessageUpdate
        }
        ... on MessageThreadType {
          ...MessageThreadUpdate
        }
        ... on MessageAttachmentType {
          ...MessageAttachmentUpdate
        }
      }
    }
  }

  fragment MessageThreadUpdate on MessageThreadType {
    id
    subject
    s3ThumbnailUrl
    unreadCount
    lastActivity
    exercises {
      id
    }
  }
  fragment MessageUpdate on MessageType {
    id
    contents
    read
    createdAt
    complete
    attachments {
      id
      s3Url
      s3ThumbnailUrl
      complete
    }
    sender {
      id
      userType
      ...User
    }
    messageThreadId
  }
  fragment MessageAttachmentUpdate on MessageAttachmentType {
    id
    s3Url
    s3ThumbnailUrl
    messageId
    complete
  }
`;
