import { createStackNavigator } from 'react-navigation-stack';
import { MobileVideoCamera } from '../components/camera/MobileVideoCamera';
import { headerOptions } from '../designSystem';

export const StorybookNavigator = createStackNavigator(
  {
    MobileVideoCamera: {
      screen: MobileVideoCamera,
      navigationOptions: {
        header: null,
      },
    },
  },
  {
    defaultNavigationOptions: headerOptions,
  }
);
