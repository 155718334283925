import {
  startOfDay,
  compareDesc,
  isToday,
  differenceInDays,
  addMinutes,
  startOfMinute,
  format,
} from 'date-fns';
import reduce from 'ramda/src/reduce';
import reduced from 'ramda/src/reduced';
import addIndex from 'ramda/src/addIndex';
import { StudentMentorRelationshipType } from '../graphql/types';

export const dateMDY = (d: Date) => format(d, 'M/DD/YY');
export const isRelationshipActive = (
  relationship?: Pick<StudentMentorRelationshipType, 'endedAt'> | null
) => !!relationship && new Date(relationship.endedAt) >= new Date();

const countStreak = (streak: number, day: Date, i: number, arr: Date[]) => {
  if (i === 0) {
    return 1;
  }

  const prevDay = arr[i - 1];

  const dateDiff = differenceInDays(prevDay, day);

  if (dateDiff === 0) {
    return streak;
  } else if (dateDiff === 1) {
    return streak + 1;
  } else {
    return reduced(streak);
  }
};

export const streak = (records: Date[]): number => {
  const days = records.map(startOfDay).sort(compareDesc);

  if (!isToday(days[0])) {
    return 0;
  }

  const reduceWithIndex = addIndex(reduce);

  // @ts-ignore
  return reduceWithIndex(countStreak, 0, days);
};

export const roundTimeUpToNearestMinutes = (
  dirtyDate: Date,
  interval: number
) => {
  const seconds = dirtyDate.getSeconds();
  const minutes = dirtyDate.getMinutes() + seconds / 60;
  const roundedMinutes = Math.ceil(minutes / interval) * interval;
  const difference = roundedMinutes - minutes;

  return addMinutes(startOfMinute(dirtyDate), difference);
};

export const yesterdayDate = () =>
  (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
