import { Machine, assign } from 'xstate';
import { Camera } from 'expo-camera';
import * as Permissions from 'expo-permissions';
import { MountError } from 'expo-camera/build/Camera.types';
import { Platform } from '@unimodules/core';
import { trackError, trackEvent } from '../utils/analytics';

export interface CameraContext {
  camera: Camera | null;
  videoUri: string | null;
}

interface CameraStateSchema {
  states: {
    requestPermissions: {
      states: {
        pending: {};
        failure: {};
      };
    };
    capture: {
      states: {
        loading: {};
        ready: {
          states: {
            idle: {};
            active: {};
          };
        };
        failure: {};
      };
    };
    review: {};
  };
}

export type CameraEvent =
  | { type: 'PERMISSION_GRANTED' }
  | { type: 'PERMISSION_DENIED' }
  | { type: 'CAMERA_READY'; camera: Camera }
  | { type: 'PRESS_RECORD' }
  | { type: 'CANCEL_RECORD' }
  | { type: 'FINISH_RECORD' }
  | { type: 'UPLOAD_ROLL_VIDEO'; uri: string }
  | { type: 'FAIL'; error: MountError }
  | { type: 'GOTO_CAPTURE' }
  | { type: 'SENT' };

export const cameraMachine = Machine<
  CameraContext,
  CameraStateSchema,
  CameraEvent
>({
  id: 'camera',
  initial: 'requestPermissions',
  context: {
    camera: null,
    videoUri: null,
  },
  states: {
    requestPermissions: {
      initial: 'pending',
      states: {
        pending: {
          invoke: {
            id: 'requestPermissions',
            onDone: {
              target: '#camera.capture',
            },
            onError: {
              target: 'failure',
            },
            src: (context, event) => {
              return Permissions.askAsync(
                Permissions.CAMERA,
                Permissions.AUDIO_RECORDING
              ).then(response => {
                if (response.status === 'granted') {
                  return response.status;
                } else {
                  trackError(`Camera issue ${JSON.stringify(response.status)}`);
                  throw response.status;
                }
              });
            },
          },
        },
        failure: {},
      },
    },
    capture: {
      initial: 'loading',
      states: {
        loading: {
          on: {
            CAMERA_READY: {
              target: 'ready.idle',
              actions: 'setCamera',
            },
          },
        },
        ready: {
          states: {
            idle: {
              on: {
                PRESS_RECORD: 'active',
                UPLOAD_ROLL_VIDEO: {
                  target: '#camera.review',
                  actions: assign({
                    videoUri: (context, event) => {
                      trackEvent('upload-cameraroll-video');
                      return event.uri;
                    },
                  }),
                },
              },
            },
            active: {
              invoke: {
                id: 'recordVideo',
                src: (context, event) => {
                  trackEvent('camera-start-recording');
                  return context.camera.recordAsync({
                    quality:
                      Platform.OS === 'ios'
                        ? Camera.Constants.VideoQuality['720p']
                        : Camera.Constants.VideoQuality['480p'],
                  });
                },
                onDone: {
                  target: '#camera.review',
                  actions: assign({
                    videoUri: (context, event) => {
                      return event.data.uri;
                    },
                  }),
                  cond: (context, event) => event.data.uri !== null,
                },
                onError: {
                  target: '#camera.capture.failure',
                  actions: (context, event) => {
                    trackError(event.data);
                    trackEvent('camera-capture-failure');
                  },
                },
              },
              on: {
                CANCEL_RECORD: {
                  target: '#camera.capture.ready.idle',
                  actions: (context, event) => {
                    trackEvent('camera-cancel-recording');
                    context.camera && context.camera.stopRecording();
                  },
                },
                FINISH_RECORD: {
                  actions: (context, event) => {
                    trackEvent('camera-finish-recording');
                    if (!context.camera) throw new Error('null camera');
                    context.camera.stopRecording();
                  },
                },
              },
            },
          },
        },
        failure: {},
      },
      on: {
        FAIL: '.failure',
      },
    },
    review: {
      on: {
        GOTO_CAPTURE: 'capture',
        SENT: {
          actions: () => {
            trackEvent('camera-upload-complete');
          },
        },
      },
    },
  },
});
