import { AsyncStorage, Platform } from 'react-native';
import { createAppContainer } from '@react-navigation/native';
import { StudentNavigator } from './studentApp';
import { SignedOutNavigator } from './signedOutApp';
import { AuthLoadingScreen } from '../screens/AuthLoadingScreen';
import { MentorNavigator } from './mentorApp';
import { StorybookNavigator } from './storybookApp';
import { createSwitchNavigator } from 'react-navigation';
import { createBrowserApp } from '@react-navigation/web';
import { addLoginRouteForWeb } from '../utils/navigationUtils';
import { trackError } from '../utils/analytics';

const createApp = Platform.select({
  web: config => createBrowserApp(config, { history: 'browser' }),
  default: config => createAppContainer(config),
});

const switchNav = addLoginRouteForWeb(
  createSwitchNavigator(
    {
      SignedOutApp: SignedOutNavigator,
      StudentApp: StudentNavigator,
      MentorApp: MentorNavigator,
      AuthLoading: AuthLoadingScreen,
      StorybookApp: StorybookNavigator,
    },
    {
      initialRouteName: 'AuthLoading',
      // initialRouteName: 'StorybookApp',
    }
  )
);

export const AppContainer = createApp(switchNav);

const persistenceKey = 'persistenceKey';
const persistNavigationState = async navState => {
  try {
    await AsyncStorage.setItem(persistenceKey, JSON.stringify(navState));
  } catch (err) {
    trackError(err);
    // handle the error according to your needs
  }
};
const loadNavigationState = async () => {
  // await AsyncStorage.removeItem(persistenceKey)
  const jsonString = await AsyncStorage.getItem(persistenceKey);
  return JSON.parse(jsonString);
};

export const getPersistenceFunctions = () =>
  // {
  //   AsyncStorage.clear();
  //   return undefined;
  // };
  __DEV__
    ? {
        persistNavigationState,
        loadNavigationState,
      }
    : undefined;
