import Maybe from 'graphql/tsutils/Maybe';

/**
 * Sometimes you gotta go deep
 */
export type Pick2<T, K1 extends keyof T, K2 extends keyof T[K1]> = {
  [P1 in K1]: {
    [P2 in K2]: T[K1][P2];
  };
};

/**
 * "Just the numbers, ma'am" -> returns number[] and nothing else
 * @param data some maybe array of maybe numbers from GraphQL
 */
export const numsFromQueryArray = (
  data: Maybe<number>[] | null | undefined
): number[] => (data || []).filter(isNum);

/**
 * Checks if n is fo' sho' a TypeScript number
 * @param n maybe a number, or maybe undefined
 */
export const isNum = (n: Maybe<number> | undefined): n is number =>
  n !== null && n !== undefined && !isNaN(n);
