import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '../../designSystem';
import { Button } from '../../components/Button';
import { ziggeoApiKey } from '../../utils/environment';
import { RecordVideoScreenStrings as S } from '../../strings';

interface WebVideoRecorderProps {
  finalizeUpload: () => void;
  onCancel: () => void;
  messageAttachmentId: number;
  submitButtonText: string;
}

const ziggeoWidth = 640;

export const WebVideoRecorder: React.FC<WebVideoRecorderProps> = ({
  onCancel,
  finalizeUpload,
  messageAttachmentId,
  submitButtonText,
}) => {
  const [ZiggeoRecorder, setZiggeoRecorder] = useState<
    typeof React.Component | null
  >(null);
  const [isUploadingToZiggeo, setIsUploaded] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);

  const handleUploaded = () => {
    setIsFinalizing(false);
    setIsUploaded(true);
  };
  const resetUpload = () => {
    setIsFinalizing(false);
    setIsUploaded(false);
  };

  // lazy load ziggeo
  import('react-ziggeo-mobile-friendly').then(({ ZiggeoRecorder }) => {
    setZiggeoRecorder(() => ZiggeoRecorder);
  });

  if (!ZiggeoRecorder) {
    return null;
  }

  return (
    <View style={styles.container}>
      <ZiggeoRecorder
        apiKey={ziggeoApiKey()}
        height={360}
        width={ziggeoWidth}
        custom-data={{ messageAttachmentId }}
        early-rerecord={true}
        skipinitialonrerecord={true}
        skipinitial={false}
        custom-covershots={false}
        onVerified={handleUploaded}
        onError={resetUpload}
        onRerecord={resetUpload}
        // wait for onVerified, not onUploaded
        // onUploaded={() => console.log('uploaded but not verified')}
      />
      <View style={styles.buttonContainer}>
        <Button title={'Cancel'} onPress={onCancel} />
        <Button
          disabled={!isUploadingToZiggeo || isFinalizing}
          title={
            isFinalizing
              ? S.ReviewVideo.sendToMentorButtonUploading
              : submitButtonText
          }
          onPress={() => {
            // because we've had a few people pound this thing
            // quickly apparently....
            if (!isFinalizing) finalizeUpload();
            setIsFinalizing(true);
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingTop: 30,
    backgroundColor: colors.lightGrey,
    height: '100%',
  },
  buttonContainer: {
    marginTop: 30,
    flexDirection: 'row',
    width: ziggeoWidth,
    justifyContent: 'space-around',
  },
});
