import React from 'react';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { rhythm } from '../designSystem';

const hitSlop = rhythm[1];

interface ExerciseViewButtonProps {
  onPress: () => void;
  hasRightSibling?: boolean;
}

export const ExerciseViewButton = ({
  onPress,
  hasRightSibling,
}: ExerciseViewButtonProps) => (
  <TouchableOpacity
    style={{ marginHorizontal: hasRightSibling ? rhythm[1] : rhythm[2] }}
    hitSlop={{
      top: hitSlop,
      left: hitSlop,
      bottom: hitSlop,
      right: hitSlop,
    }}
    onPress={() => onPress()}
  >
    <MaterialCommunityIcons size={24} name="playlist-music" color="white" />
  </TouchableOpacity>
);
