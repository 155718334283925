import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { isToday } from 'date-fns';

import { rhythm, colors, fontSize, fonts } from '../designSystem';
import { Button } from '../components/Button';
import { Progress } from '../components/SkinnyProgress';
import { StudentHomeScreenStrings as S } from '../strings';
import { SegmentType, Maybe } from '../graphql/types';
import { trackEvent } from '../utils/analytics';

interface PracticeSessionWidgetProps {
  segment: Maybe<SegmentType>;
}

const StartPracticeButton: React.FC = () => {
  const { navigate } = useNavigation();
  return (
    <Button
      title={S.PracticeSessionWidget.button}
      onPress={() => navigate('SessionCreatorScreen')}
    />
  );
};

const ResumePracticeButton: React.FC<{ segmentProgress: number }> = ({
  segmentProgress,
}) => {
  const { navigate } = useNavigation();
  const onPress = () => {
    trackEvent('practice-resume-session');
    navigate('PracticeSessionScreen');
  };
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Progress progress={segmentProgress} />
        <Text style={styles.title}>
          {S.PracticeSessionWidget.titleInProgress}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const PracticeSessionWidget: React.FC<PracticeSessionWidgetProps> = ({
  segment,
}) => {
  if (segment && isToday(segment.createdAt)) {
    const { completedExerciseIds, newExerciseIds, reviewExerciseIds } = segment;
    if (!completedExerciseIds || !newExerciseIds || !reviewExerciseIds)
      throw new Error('Malformed segment passed to PracticeSessionWidget');

    const segmentProgressPercent =
      completedExerciseIds.length /
      (newExerciseIds.length + reviewExerciseIds.length);

    const inProgress = segmentProgressPercent < 1;

    if (inProgress)
      return <ResumePracticeButton segmentProgress={segmentProgressPercent} />;
  }
  return <StartPracticeButton />;
};

const styles = StyleSheet.create({
  container: {
    marginBottom: rhythm[3],
    backgroundColor: colors.accent,
    borderRadius: rhythm[2],
    overflow: 'hidden',
  },
  title: {
    paddingHorizontal: rhythm[2],
    paddingVertical: rhythm[2],
    ...fonts.bold,
    fontSize: fontSize[1],
    alignSelf: 'center',
    color: colors.white,
  },
  column: {
    flex: 1,
    paddingHorizontal: rhythm[2],
  },
  body: {
    ...fonts.regular,
    fontSize: fontSize[0],
    color: colors.white,
  },
  bodyTitle: {
    ...fonts.bold,
  },
});
