import { useState } from 'react';
import { Dimensions, Platform } from 'react-native';

const THRESHOLD =
  Platform.OS === 'web' || (Platform.OS === 'ios' && Platform.isPad)
    ? 800
    : 568;

export const useIsMobileLayout: () => [boolean, () => void] = () => {
  const getIsMobile: () => boolean = () => {
    // iPhone SE landscape width (smallest common phone)
    return Dimensions.get('window').width < THRESHOLD;
  };

  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(getIsMobile());

  const updateIsMobileLayout = () => setIsMobileLayout(getIsMobile());

  return [isMobileLayout, updateIsMobileLayout];
};
