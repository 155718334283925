import { isToday, format, isYesterday } from 'date-fns';

import { yesterdayDate } from '../dateUtils';
import { booleanParam } from '../navigationUtils';
import { ChatMessageType } from './types';
export type ChatUserRole = 'mentor' | 'student';

export const formatDate = date => {
  if (isToday(date)) {
    return format(date, 'h:mm A');
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return format(date, 'MM/D/YY');
  }
};

/**
 * Checks to see if param is really true,
 * not just "false" (which is true...)
 * @param param param from navigation.getParam
 */

export function chatParams(getParam) {
  // only mentor chat needs relationshipId
  const relationshipId = parseInt(getParam('relationshipId', -1));

  const mainThreadId = parseInt(getParam('mainThreadId', -1));
  // if (mainThreadId < 0) throw new Error('main thread not supplied');

  const currentThreadId = parseInt(
    getParam('currentThreadId', false) || mainThreadId
  );

  const showProfile = booleanParam(getParam('showProfile'));

  const baseTitle = getParam('baseTitle', '');
  const subject = getParam('subject', '');
  const exerciseId = getParam('exerciseId', undefined);
  const isMainThread = mainThreadId === currentThreadId;
  return {
    showProfile,
    mainThreadId,
    currentThreadId,
    relationshipId,
    baseTitle,
    subject,
    isMainThread,
    exerciseId,
  };
}

export const attachmentForMessage = (message: ChatMessageType) =>
  message.attachment &&
  message.attachment.s3Url &&
  message.attachment.s3ThumbnailUrl && {
    video: message.attachment.s3Url,
    posterImage: message.attachment.s3ThumbnailUrl,
  };

export const hasUnprocessedAttachment = (message: ChatMessageType) =>
  message.contents.length === 0 &&
  message.sent &&
  !attachmentForMessage(message);

export const newerThanYesterday = (message: ChatMessageType) =>
  yesterdayDate() < new Date(message.createdAt);

export const hasRecentUnprocessedAttachment = (message: ChatMessageType) =>
  hasUnprocessedAttachment(message) && newerThanYesterday(message);

export const textForMessage = (message: ChatMessageType) => {
  if (hasUnprocessedAttachment(message)) {
    return newerThanYesterday(message)
      ? 'Video uploaded, but still processing...'
      : 'Error processing video';
  }

  return message.contents;
};
