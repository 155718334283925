import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-navigation';

import { rhythm, colors, fontSize, fonts } from '../../designSystem';
import {
  CalendarHeatmap,
  PracticeRecord,
} from '../../components/CalendarHeatmap';
import { Loading } from '../../components/Loading';
import { Container } from '../../components/Container';
import { LiveStreamAnnouncement } from '../../components/Livestream';
import { StudentHomeScreenStrings as S } from '../../strings';
import gql from 'graphql-tag';
import { userInfo, segment } from '../../graphql/fragments';
import {
  useStudentHomeScreenQuery,
  Maybe,
  SegmentType,
} from '../../graphql/types';
import { ErrorMessage, MissingDataError } from '../../components/ErrorMessage';
import { PracticeSessionWidget } from '../../components/PracticeSessionWidget';
import { streak } from '../../utils/dateUtils';
import { LandscapeConstrainer } from '../../components/LandscapeConstrainer';

export const STUDENT_HOME_SCREEN_QUERY = gql`
  query StudentHomeScreen {
    lastSegment {
      id
      createdAt
      newExerciseIds
      completedExerciseIds
      reviewExerciseIds
    }

    user {
      ...User
      student {
        id
        studentType
        segments {
          id
          createdAt
        }
      }
    }
  }
  ${userInfo}
  # ${segment}
`;

const isSegment = (
  s: Maybe<Pick<SegmentType, 'id' | 'createdAt'>>
): s is PracticeRecord => s && s.id && s.createdAt;

export const StudentHomeScreen: React.FC = () => {
  const { data, loading, error } = useStudentHomeScreenQuery();
  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.user || !data.user.student || !data.user.student.segments)
    return <MissingDataError type="Home Screen" />;

  const streakLength = streak(
    data.user.student.segments.map(s => s && s.createdAt)
  );

  return (
    <Container>
      <LandscapeConstrainer>
        <LiveStreamAnnouncement />
        <SafeAreaView style={styles.safeArea}>
          <View style={styles.calendar}>
            <Text style={styles.calendarTitle}>
              {streakLength > 0
                ? `${streakLength}-day ${S.Streak.title}`
                : 'Practice Calendar'}
            </Text>
            <CalendarHeatmap
              records={data.user.student.segments.filter(isSegment)}
            />
          </View>
          <PracticeSessionWidget segment={data.lastSegment} />
        </SafeAreaView>
      </LandscapeConstrainer>
    </Container>
  );
};

const styles = StyleSheet.create({
  calendarTitle: {
    ...fonts.bold,
    fontSize: fontSize[1],
    color: colors.white,
    marginBottom: rhythm[1],
  },
  safeArea: {
    flex: 1,
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  calendar: {
    backgroundColor: colors.accent,
    alignItems: 'center',
    borderRadius: rhythm[2],
    paddingVertical: rhythm[2],
    paddingHorizontal: rhythm[2],
    marginBottom: rhythm[3],
    alignSelf: 'center',
  },
});
