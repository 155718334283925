import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   **/
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   **/
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   **/
  Date: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   **/
  GenericScalar: any;
};

export type ChatType = MessageType | MessageThreadType | MessageAttachmentType;

export type CompleteMessageAttachment = {
  __typename?: 'CompleteMessageAttachment';
  ok: Scalars['Boolean'];
};

export type ConsumeLoginToken = {
  __typename?: 'ConsumeLoginToken';
  token?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
};

export type CreateMessage = {
  __typename?: 'CreateMessage';
  message: MessageType;
};

export type CreateMessageAttachment = {
  __typename?: 'CreateMessageAttachment';
  messageAttachment: MessageAttachmentType;
};

export type CreateMessageThread = {
  __typename?: 'CreateMessageThread';
  messageThread: MessageThreadType;
};

export type CreateNextSegment = {
  __typename?: 'CreateNextSegment';
  segment: SegmentType;
};

export type CurriculumModuleType = {
  __typename?: 'CurriculumModuleType';
  id: Scalars['ID'];
  curriculum: CurriculumType;
  title: Scalars['String'];
  description: Scalars['String'];
  lessonGroups?: Maybe<Array<Maybe<LessonGroupType>>>;
};

export type CurriculumType = {
  __typename?: 'CurriculumType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  completion: Scalars['JSONString'];
  curriculumModules?: Maybe<Array<Maybe<CurriculumModuleType>>>;
};

export type CursorChatType = {
  __typename?: 'CursorChatType';
  chats?: Maybe<Array<Maybe<ChatType>>>;
  since?: Maybe<Scalars['DateTime']>;
};

export type EnrollmentType = {
  __typename?: 'EnrollmentType';
  id: Scalars['ID'];
  student: StudentType;
  curriculum?: Maybe<CurriculumType>;
};

export type ExerciseProgressType = {
  __typename?: 'ExerciseProgressType';
  id: Scalars['ID'];
  exercise: ExerciseType;
  submissionComplete: Scalars['Boolean'];
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  rating?: Maybe<Scalars['Int']>;
  uploadData?: Maybe<UploadDataType>;
  s3Url?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  previousExerciseProgress?: Maybe<ExerciseProgressType>;
  messageThreads?: Maybe<Array<Maybe<MessageThreadType>>>;
};

export type ExerciseType = {
  __typename?: 'ExerciseType';
  id: Scalars['ID'];
  level: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  soundsliceEmbedUrl: Scalars['String'];
  optional: Scalars['Boolean'];
  lesson?: Maybe<LessonType>;
  lastProgress?: Maybe<ExerciseProgressType>;
};

export type GenerateLoginEmail = {
  __typename?: 'GenerateLoginEmail';
  ok: Scalars['Boolean'];
};

export type GeneratePasswordEmail = {
  __typename?: 'GeneratePasswordEmail';
  ok: Scalars['Boolean'];
};

export type LessonGroupType = {
  __typename?: 'LessonGroupType';
  id: Scalars['ID'];
  curriculumModule: CurriculumModuleType;
  title: Scalars['String'];
  lessonGroupVideos?: Maybe<Array<Maybe<LessonGroupVideoType>>>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
};

export type LessonGroupVideoType = {
  __typename?: 'LessonGroupVideoType';
  id: Scalars['ID'];
  lessonGroup: LessonGroupType;
  description?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
};

export type LessonType = {
  __typename?: 'LessonType';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  supplementary: Scalars['JSONString'];
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  videoUrl?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  lessonGroup?: Maybe<LessonGroupType>;
  supplementaryJSON: Scalars['JSONString'];
};

export type MentorType = {
  __typename?: 'MentorType';
  id: Scalars['ID'];
  user: UserType;
  schedule: Scalars['String'];
};

export type MessageAttachmentType = {
  __typename?: 'MessageAttachmentType';
  id: Scalars['ID'];
  message?: Maybe<MessageType>;
  name: Scalars['String'];
  complete: Scalars['Boolean'];
  uploadData?: Maybe<UploadDataType>;
  s3Url?: Maybe<Scalars['String']>;
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
  messageId: Scalars['Int'];
};

export type MessageThreadType = {
  __typename?: 'MessageThreadType';
  id: Scalars['ID'];
  student: StudentType;
  mentor: MentorType;
  subject?: Maybe<Scalars['String']>;
  exerciseProgress?: Maybe<ExerciseProgressType>;
  lastMessageAt: Scalars['DateTime'];
  messages?: Maybe<Array<Maybe<MessageType>>>;
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  relationship?: Maybe<StudentMentorRelationshipType>;
  lastActivity?: Maybe<Scalars['DateTime']>;
  lastMessage?: Maybe<MessageType>;
  unreadCount: Scalars['Int'];
  s3ThumbnailUrl?: Maybe<Scalars['String']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  contents: Scalars['String'];
  complete: Scalars['Boolean'];
  read: Scalars['Boolean'];
  receiver?: Maybe<UserType>;
  sender?: Maybe<UserType>;
  exercise?: Maybe<ExerciseType>;
  attachments?: Maybe<Array<Maybe<MessageAttachmentType>>>;
  messageThread?: Maybe<MessageThreadType>;
  messageThreadId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createNextSegment?: Maybe<CreateNextSegment>;
  reportProgress?: Maybe<ReportProgress>;
  reportCompleteSubmission?: Maybe<ReportCompleteSubmission>;
  createMessageThread?: Maybe<CreateMessageThread>;
  createMessage?: Maybe<CreateMessage>;
  createMessageAttachment?: Maybe<CreateMessageAttachment>;
  completeMessageAttachment?: Maybe<CompleteMessageAttachment>;
  startMessage?: Maybe<StartMessage>;
  publishMessage?: Maybe<PublishMessage>;
  readMessage?: Maybe<ReadMessage>;
  readMessages?: Maybe<ReadMessages>;
  consumeLoginToken?: Maybe<ConsumeLoginToken>;
  generateLoginEmail?: Maybe<GenerateLoginEmail>;
  generatePasswordEmail?: Maybe<GeneratePasswordEmail>;
  setPushToken?: Maybe<SetPushToken>;
  setPreferences?: Maybe<SetPreferences>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  updatePracticeReminderSettings?: Maybe<PracticeReminderSettings>;
};

export type MutationCreateNextSegmentArgs = {
  duration: Scalars['Int'];
};

export type MutationReportProgressArgs = {
  endedAt?: Maybe<Scalars['Date']>;
  exerciseId: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  segmentId: Scalars['Int'];
  startedAt?: Maybe<Scalars['Date']>;
};

export type MutationReportCompleteSubmissionArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessageThreadArgs = {
  contents: Scalars['String'];
  exerciseProgressId?: Maybe<Scalars['Int']>;
  relationshipId: Scalars['Int'];
  subject: Scalars['String'];
};

export type MutationCreateMessageArgs = {
  contents: Scalars['String'];
  exerciseId?: Maybe<Scalars['Int']>;
  messageThreadId: Scalars['Int'];
};

export type MutationCreateMessageAttachmentArgs = {
  id: Scalars['Int'];
};

export type MutationCompleteMessageAttachmentArgs = {
  id: Scalars['Int'];
};

export type MutationStartMessageArgs = {
  contents: Scalars['String'];
  exerciseId?: Maybe<Scalars['Int']>;
  messageThreadId: Scalars['Int'];
};

export type MutationPublishMessageArgs = {
  exerciseId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type MutationReadMessageArgs = {
  id: Scalars['Int'];
};

export type MutationReadMessagesArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
};

export type MutationConsumeLoginTokenArgs = {
  password?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type MutationGenerateLoginEmailArgs = {
  email: Scalars['String'];
};

export type MutationGeneratePasswordEmailArgs = {
  email: Scalars['String'];
};

export type MutationSetPushTokenArgs = {
  token: Scalars['String'];
};

export type MutationSetPreferencesArgs = {
  pushNotificationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationVerifyTokenArgs = {
  token: Scalars['String'];
};

export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};

export type MutationUpdatePracticeReminderSettingsArgs = {
  enabled?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  token?: Maybe<Scalars['String']>;
};

export type PagedExerciseProgressType = {
  __typename?: 'PagedExerciseProgressType';
  totalCount?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfoType>;
  exerciseProgresses?: Maybe<Array<Maybe<ExerciseProgressType>>>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PracticeReminderSettings = {
  enabled?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  notificationId?: Maybe<Scalars['ID']>;
};

export type PublishMessage = {
  __typename?: 'PublishMessage';
  ok: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<UserType>;
  lastSegment?: Maybe<SegmentType>;
  curriculum?: Maybe<CurriculumType>;
  curriculumModule?: Maybe<CurriculumModuleType>;
  lesson?: Maybe<LessonType>;
  lessonGroup?: Maybe<LessonGroupType>;
  exercise?: Maybe<ExerciseType>;
  exercises?: Maybe<Array<Maybe<ExerciseType>>>;
  exerciseProgresses?: Maybe<PagedExerciseProgressType>;
  messageThreads?: Maybe<Array<Maybe<MessageThreadType>>>;
  messageThread?: Maybe<MessageThreadType>;
  message?: Maybe<MessageType>;
  messageAttachments?: Maybe<Array<Maybe<MessageAttachmentType>>>;
  enrollments?: Maybe<Array<Maybe<EnrollmentType>>>;
  studentMentorRelationships?: Maybe<
    Array<Maybe<StudentMentorRelationshipType>>
  >;
  studentMentorRelationship?: Maybe<StudentMentorRelationshipType>;
  chats?: Maybe<CursorChatType>;
  userSettings?: Maybe<UserSettings>;
};

export type QueryCurriculumArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryCurriculumModuleArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryLessonArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryLessonGroupArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryExerciseArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryExercisesArgs = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type QueryExerciseProgressesArgs = {
  cursor?: Maybe<Scalars['String']>;
};

export type QueryMessageThreadsArgs = {
  relationshipId?: Maybe<Scalars['Int']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
};

export type QueryMessageThreadArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryMessageArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryStudentMentorRelationshipArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryChatsArgs = {
  since?: Maybe<Scalars['DateTime']>;
  relationshipId?: Maybe<Scalars['Int']>;
};

export type ReadMessage = {
  __typename?: 'ReadMessage';
  ok: Scalars['Boolean'];
};

export type ReadMessages = {
  __typename?: 'ReadMessages';
  ok: Scalars['Boolean'];
};

export type Refresh = {
  __typename?: 'Refresh';
  token?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['GenericScalar']>;
};

export type ReportCompleteSubmission = {
  __typename?: 'ReportCompleteSubmission';
  ok: Scalars['Boolean'];
};

export type ReportProgress = {
  __typename?: 'ReportProgress';
  progress: ExerciseProgressType;
};

export type SegmentType = {
  __typename?: 'SegmentType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reviewExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  newExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  completedExerciseIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SetPreferences = {
  __typename?: 'SetPreferences';
  ok: Scalars['Boolean'];
};

export type SetPushToken = {
  __typename?: 'SetPushToken';
  ok: Scalars['Boolean'];
};

export type StartMessage = {
  __typename?: 'StartMessage';
  message: MessageType;
};

export type StudentMentorRelationshipType = {
  __typename?: 'StudentMentorRelationshipType';
  id: Scalars['ID'];
  student: StudentType;
  mentor: MentorType;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  messageThread?: Maybe<MessageThreadType>;
  unreadCount?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum StudentStudentType {
  /** low_ticket */
  Lt = 'LT',
  /** high_ticket */
  Ht = 'HT',
}

export type StudentType = {
  __typename?: 'StudentType';
  id: Scalars['ID'];
  user: UserType;
  level: Scalars['Int'];
  studentType: StudentStudentType;
  segments?: Maybe<Array<Maybe<SegmentType>>>;
};

export type UploadDataType = {
  __typename?: 'UploadDataType';
  url?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSONString']>;
};

export type UserSettings = {
  practiceReminder?: Maybe<PracticeReminderSettings>;
  chatNotifications?: Maybe<Scalars['Boolean']>;
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  pushNotificationsEnabled: Scalars['Boolean'];
  userType?: Maybe<Scalars['String']>;
  student?: Maybe<StudentType>;
  fullName: Scalars['String'];
};

export type Verify = {
  __typename?: 'Verify';
  payload?: Maybe<Scalars['GenericScalar']>;
};

export type StudentTabUnreadPollQueryVariables = {};

export type StudentTabUnreadPollQuery = { __typename?: 'Query' } & {
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        { __typename?: 'StudentMentorRelationshipType' } & Pick<
          StudentMentorRelationshipType,
          'id' | 'unreadCount'
        >
      >
    >
  >;
};

export type StudentTabQueryVariables = {};

export type StudentTabQuery = { __typename?: 'Query' } & {
  user: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
        student: Maybe<
          { __typename?: 'StudentType' } & Pick<
            StudentType,
            'id' | 'studentType'
          >
        >;
      }
  >;
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'StudentMentorRelationshipType';
        } & StudentTabRelationshipFragment
      >
    >
  >;
};

export type ConsumeLoginTokenMutationVariables = {
  token: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type ConsumeLoginTokenMutation = { __typename?: 'Mutation' } & {
  consumeLoginToken: Maybe<
    { __typename?: 'ConsumeLoginToken' } & Pick<
      ConsumeLoginToken,
      'token' | 'expired'
    >
  >;
};

export type MentorChatStudentInfoQueryVariables = {
  mainThreadId: Scalars['Int'];
};

export type MentorChatStudentInfoQuery = { __typename?: 'Query' } & {
  messageThread: Maybe<
    { __typename?: 'MessageThreadType' } & Pick<MessageThreadType, 'id'> & {
        mentor: { __typename?: 'MentorType' } & Pick<MentorType, 'id'> & {
            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> &
              UserFragment;
          };
        student: { __typename?: 'StudentType' } & Pick<StudentType, 'id'> & {
            user: { __typename?: 'UserType' } & Pick<UserType, 'id'> &
              UserFragment;
            segments: Maybe<
              Array<
                Maybe<
                  { __typename?: 'SegmentType' } & Pick<
                    SegmentType,
                    'id' | 'createdAt'
                  >
                >
              >
            >;
          };
      }
  >;
};

export type ExerciseSimpleQueryVariables = {
  exerciseId: Scalars['Int'];
};

export type ExerciseSimpleQuery = { __typename?: 'Query' } & {
  exercise: Maybe<
    { __typename?: 'ExerciseType' } & Pick<
      ExerciseType,
      'id' | 'level' | 'soundsliceEmbedUrl'
    >
  >;
};

export type MentorIndexQueryVariables = {};

export type MentorIndexQuery = { __typename?: 'Query' } & {
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'StudentMentorRelationshipType';
        } & MentorIndexStudentMentorRelationshipFragment
      >
    >
  >;
};

export type SignInUserMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignInUserMutation = { __typename?: 'Mutation' } & {
  tokenAuth: Maybe<
    { __typename?: 'ObtainJSONWebToken' } & Pick<ObtainJsonWebToken, 'token'>
  >;
};

export type GenerateLoginEmailMutationVariables = {
  email: Scalars['String'];
};

export type GenerateLoginEmailMutation = { __typename?: 'Mutation' } & {
  generateLoginEmail: Maybe<
    { __typename?: 'GenerateLoginEmail' } & Pick<GenerateLoginEmail, 'ok'>
  >;
};

export type GeneratePasswordEmailMutationVariables = {
  email: Scalars['String'];
};

export type GeneratePasswordEmailMutation = { __typename?: 'Mutation' } & {
  generatePasswordEmail: Maybe<
    { __typename?: 'GeneratePasswordEmail' } & Pick<GeneratePasswordEmail, 'ok'>
  >;
};

export type StudentChatBasicInfoQueryVariables = {};

export type StudentChatBasicInfoQuery = { __typename?: 'Query' } & {
  user: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
        student: Maybe<
          { __typename?: 'StudentType' } & Pick<
            StudentType,
            'id' | 'studentType'
          >
        >;
      }
  >;
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        { __typename?: 'StudentMentorRelationshipType' } & Pick<
          StudentMentorRelationshipType,
          'id' | 'endedAt'
        > & {
            mentor: { __typename?: 'MentorType' } & Pick<MentorType, 'id'> & {
                user: { __typename?: 'UserType' } & Pick<
                  UserType,
                  'id' | 'firstName' | 'lastName' | 'fullName'
                >;
              };
            student: { __typename?: 'StudentType' } & Pick<
              StudentType,
              'id'
            > & {
                user: { __typename?: 'UserType' } & Pick<
                  UserType,
                  'id' | 'firstName' | 'lastName'
                >;
              };
            messageThread: Maybe<
              { __typename?: 'MessageThreadType' } & Pick<
                MessageThreadType,
                'id'
              >
            >;
          }
      >
    >
  >;
};

export type ReportProgressMutationVariables = {
  exerciseId: Scalars['Int'];
  segmentId: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
};

export type ReportProgressMutation = { __typename?: 'Mutation' } & {
  reportProgress: Maybe<
    { __typename?: 'ReportProgress' } & {
      progress: { __typename?: 'ExerciseProgressType' } & Pick<
        ExerciseProgressType,
        'id' | 'rating' | 'submissionComplete' | 'startedAt' | 'endedAt'
      > & {
          exercise: { __typename?: 'ExerciseType' } & Pick<ExerciseType, 'id'>;
          uploadData: Maybe<
            { __typename?: 'UploadDataType' } & Pick<
              UploadDataType,
              'url' | 'fields'
            >
          >;
        };
    }
  >;
};

export type ExerciseRatingScreenQueryVariables = {
  exerciseId: Scalars['Int'];
};

export type ExerciseRatingScreenQuery = { __typename?: 'Query' } & {
  lastSegment: Maybe<{ __typename?: 'SegmentType' } & Pick<SegmentType, 'id'>>;
  exercise: Maybe<
    { __typename?: 'ExerciseType' } & Pick<
      ExerciseType,
      'id' | 'level' | 'soundsliceEmbedUrl'
    > & {
        lastProgress: Maybe<
          { __typename?: 'ExerciseProgressType' } & Pick<
            ExerciseProgressType,
            'id'
          > &
            ExerciseProgressFragment
        >;
      } & ExerciseTitleFragment
  >;
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        { __typename?: 'StudentMentorRelationshipType' } & Pick<
          StudentMentorRelationshipType,
          'id' | 'endedAt'
        >
      >
    >
  >;
  user: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
        student: Maybe<
          { __typename?: 'StudentType' } & Pick<
            StudentType,
            'id' | 'studentType'
          >
        >;
      }
  >;
};

export type ExerciseQueryVariables = {
  exerciseId: Scalars['Int'];
};

export type ExerciseQuery = { __typename?: 'Query' } & {
  lastSegment: Maybe<{ __typename?: 'SegmentType' } & Pick<SegmentType, 'id'>>;
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        { __typename?: 'StudentMentorRelationshipType' } & Pick<
          StudentMentorRelationshipType,
          'id' | 'endedAt'
        >
      >
    >
  >;
  exercise: Maybe<
    { __typename?: 'ExerciseType' } & Pick<
      ExerciseType,
      'id' | 'level' | 'soundsliceEmbedUrl'
    > & {
        lastProgress: Maybe<
          { __typename?: 'ExerciseProgressType' } & Pick<
            ExerciseProgressType,
            'id'
          > &
            ExerciseProgressFragment
        >;
      } & ExerciseTitleFragment
  >;
};

export type LessonGroupScreenQueryVariables = {
  lessonGroupId: Scalars['Int'];
};

export type LessonGroupScreenQuery = { __typename?: 'Query' } & {
  lessonGroup: Maybe<
    { __typename?: 'LessonGroupType' } & CurriculumLessonGroupFragment
  >;
};

export type LessonQueryVariables = {
  lessonId: Scalars['Int'];
};

export type LessonQuery = { __typename?: 'Query' } & {
  lesson: Maybe<
    { __typename?: 'LessonType' } & Pick<LessonType, 'id'> & LessonInfoFragment
  >;
  lastSegment: Maybe<
    { __typename?: 'SegmentType' } & Pick<
      SegmentType,
      'id' | 'newExerciseIds' | 'completedExerciseIds' | 'reviewExerciseIds'
    >
  >;
};

export type LessonsScreenQueryVariables = {};

export type LessonsScreenQuery = { __typename?: 'Query' } & {
  enrollments: Maybe<
    Array<
      Maybe<
        { __typename?: 'EnrollmentType' } & Pick<EnrollmentType, 'id'> & {
            curriculum: Maybe<
              { __typename?: 'CurriculumType' } & Pick<CurriculumType, 'id'> &
                CurriculumFragment
            >;
          }
      >
    >
  >;
};

export type LastSegmentQueryVariables = {};

export type LastSegmentQuery = { __typename?: 'Query' } & {
  lastSegment: Maybe<
    { __typename?: 'SegmentType' } & LastSegmentExercisesFragment
  >;
};

export type PracticeScreenExerciseFragment = {
  __typename?: 'ExerciseType';
} & Pick<ExerciseType, 'id'> & {
    lesson: Maybe<
      { __typename?: 'LessonType' } & Pick<
        LessonType,
        'id' | 's3ThumbnailUrl' | 'title'
      > & {
          exercises: Maybe<
            Array<
              Maybe<
                { __typename?: 'ExerciseType' } & Pick<
                  ExerciseType,
                  'id' | 'optional'
                > & {
                    lastProgress: Maybe<
                      { __typename?: 'ExerciseProgressType' } & Pick<
                        ExerciseProgressType,
                        'id' | 'rating'
                      >
                    >;
                  }
              >
            >
          >;
          lessonGroup: Maybe<
            { __typename?: 'LessonGroupType' } & Pick<
              LessonGroupType,
              'id' | 'title'
            > & {
                curriculumModule: {
                  __typename?: 'CurriculumModuleType';
                } & Pick<CurriculumModuleType, 'id' | 'title'>;
                lessons: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'LessonType' } & Pick<LessonType, 'id'>
                    >
                  >
                >;
                lessonGroupVideos: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'LessonGroupVideoType' } & Pick<
                        LessonGroupVideoType,
                        'id' | 'description' | 'videoUrl' | 's3ThumbnailUrl'
                      >
                    >
                  >
                >;
              }
          >;
        }
    >;
  };

export type PracticeScreenExercisesQueryVariables = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PracticeScreenExercisesQuery = { __typename?: 'Query' } & {
  exercises: Maybe<
    Array<
      Maybe<{ __typename?: 'ExerciseType' } & PracticeScreenExerciseFragment>
    >
  >;
};

export type StudentMentorRelationshipsQueryVariables = {};

export type StudentMentorRelationshipsQuery = { __typename?: 'Query' } & {
  studentMentorRelationships: Maybe<
    Array<
      Maybe<
        { __typename?: 'StudentMentorRelationshipType' } & Pick<
          StudentMentorRelationshipType,
          'id'
        >
      >
    >
  >;
};

export type CreateNextSegmentMutationVariables = {
  duration: Scalars['Int'];
};

export type CreateNextSegmentMutation = { __typename?: 'Mutation' } & {
  createNextSegment: Maybe<
    { __typename?: 'CreateNextSegment' } & {
      segment: { __typename?: 'SegmentType' } & Pick<
        SegmentType,
        'id' | 'newExerciseIds' | 'completedExerciseIds' | 'reviewExerciseIds'
      >;
    }
  >;
};

export type StudentHomeScreenQueryVariables = {};

export type StudentHomeScreenQuery = { __typename?: 'Query' } & {
  lastSegment: Maybe<
    { __typename?: 'SegmentType' } & Pick<
      SegmentType,
      | 'id'
      | 'createdAt'
      | 'newExerciseIds'
      | 'completedExerciseIds'
      | 'reviewExerciseIds'
    >
  >;
  user: Maybe<
    { __typename?: 'UserType' } & {
      student: Maybe<
        { __typename?: 'StudentType' } & Pick<
          StudentType,
          'id' | 'studentType'
        > & {
            segments: Maybe<
              Array<
                Maybe<
                  { __typename?: 'SegmentType' } & Pick<
                    SegmentType,
                    'id' | 'createdAt'
                  >
                >
              >
            >;
          }
      >;
    } & UserFragment
  >;
};

export type SetPushTokenMutationVariables = {
  token: Scalars['String'];
};

export type SetPushTokenMutation = { __typename?: 'Mutation' } & {
  setPushToken: Maybe<
    { __typename?: 'SetPushToken' } & Pick<SetPushToken, 'ok'>
  >;
};

export type CreateMessageAttachmentThreadMutationVariables = {
  relationshipId: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  exerciseProgressId?: Maybe<Scalars['Int']>;
};

export type CreateMessageAttachmentThreadMutation = {
  __typename?: 'Mutation';
} & {
  createMessageThread: Maybe<
    { __typename?: 'CreateMessageThread' } & {
      messageThread: { __typename?: 'MessageThreadType' } & Pick<
        MessageThreadType,
        'id'
      >;
    }
  >;
};

export type StartMessageMutationVariables = {
  messageThreadId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
};

export type StartMessageMutation = { __typename?: 'Mutation' } & {
  startMessage: Maybe<
    { __typename?: 'StartMessage' } & {
      message: { __typename?: 'MessageType' } & Pick<MessageType, 'id'>;
    }
  >;
};

export type CreateMessageAttachmentMutationVariables = {
  messageId: Scalars['Int'];
};

export type CreateMessageAttachmentMutation = { __typename?: 'Mutation' } & {
  createMessageAttachment: Maybe<
    { __typename?: 'CreateMessageAttachment' } & {
      messageAttachment: { __typename?: 'MessageAttachmentType' } & Pick<
        MessageAttachmentType,
        'id'
      > & {
          uploadData: Maybe<
            { __typename?: 'UploadDataType' } & Pick<
              UploadDataType,
              'url' | 'fields'
            >
          >;
        };
    }
  >;
};

export type PublishMessageMutationVariables = {
  messageId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
};

export type PublishMessageMutation = { __typename?: 'Mutation' } & {
  publishMessage: Maybe<
    { __typename?: 'PublishMessage' } & Pick<PublishMessage, 'ok'>
  >;
};

export type CompleteMessageAttachmentMutationVariables = {
  attachmentId: Scalars['Int'];
};

export type CompleteMessageAttachmentMutation = { __typename?: 'Mutation' } & {
  completeMessageAttachment: Maybe<
    { __typename?: 'CompleteMessageAttachment' } & Pick<
      CompleteMessageAttachment,
      'ok'
    >
  >;
};

export type UserFragment = { __typename?: 'UserType' } & Pick<
  UserType,
  'id' | 'firstName' | 'lastName' | 'fullName' | 'userType' | 'email'
>;

export type ExerciseTitleFragment = { __typename?: 'ExerciseType' } & Pick<
  ExerciseType,
  'id'
> & {
    lesson: Maybe<
      { __typename?: 'LessonType' } & Pick<LessonType, 'id' | 'title'> & {
          lessonGroup: Maybe<
            { __typename?: 'LessonGroupType' } & Pick<
              LessonGroupType,
              'id' | 'title'
            >
          >;
        }
    >;
  };

export type LastSegmentExercisesFragment = {
  __typename?: 'SegmentType';
} & Pick<
  SegmentType,
  'id' | 'newExerciseIds' | 'completedExerciseIds' | 'reviewExerciseIds'
>;

export type MessageAttachmentDownloadInfoFragment = {
  __typename?: 'MessageAttachmentType';
} & Pick<MessageAttachmentType, 'id' | 'complete' | 's3Url' | 's3ThumbnailUrl'>;

export type MessageWithAttachmentsFragment = {
  __typename?: 'MessageType';
} & Pick<MessageType, 'id' | 'contents' | 'complete' | 'createdAt' | 'read'> & {
    sender: Maybe<
      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'userType'> &
        UserFragment
    >;
    attachments: Maybe<
      Array<
        Maybe<
          {
            __typename?: 'MessageAttachmentType';
          } & MessageAttachmentDownloadInfoFragment
        >
      >
    >;
  };

export type MessagesWithAttachmentsFragment = {
  __typename?: 'MessageThreadType';
} & Pick<MessageThreadType, 'id'> & {
    messages: Maybe<
      Array<
        Maybe<{ __typename?: 'MessageType' } & MessageWithAttachmentsFragment>
      >
    >;
  };

export type MessageFragment = { __typename?: 'MessageType' } & Pick<
  MessageType,
  'id' | 'contents' | 'complete' | 'createdAt' | 'read'
> & {
    sender: Maybe<
      { __typename?: 'UserType' } & Pick<UserType, 'id'> & UserFragment
    >;
  };

export type MentorIndexMessageThreadFragment = {
  __typename?: 'MessageThreadType';
} & Pick<MessageThreadType, 'id' | 'lastActivity'> & {
    lastMessage: Maybe<
      { __typename?: 'MessageType' } & Pick<MessageType, 'id' | 'contents'> & {
          attachments: Maybe<
            Array<
              Maybe<
                {
                  __typename?: 'MessageAttachmentType';
                } & MessageAttachmentDownloadInfoFragment
              >
            >
          >;
        }
    >;
    mentor: { __typename?: 'MentorType' } & Pick<MentorType, 'id'> & {
        user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & UserFragment;
      };
    student: { __typename?: 'StudentType' } & Pick<
      StudentType,
      'id' | 'studentType' | 'level'
    > & { user: { __typename?: 'UserType' } & UserFragment };
  };

export type MentorIndexStudentMentorRelationshipFragment = {
  __typename?: 'StudentMentorRelationshipType';
} & Pick<
  StudentMentorRelationshipType,
  'id' | 'startedAt' | 'endedAt' | 'unreadCount'
> & {
    messageThread: Maybe<
      { __typename?: 'MessageThreadType' } & MentorIndexMessageThreadFragment
    >;
  };

export type StudentTabRelationshipFragment = {
  __typename?: 'StudentMentorRelationshipType';
} & Pick<
  StudentMentorRelationshipType,
  'id' | 'unreadCount' | 'startedAt' | 'endedAt'
> & {
    messageThread: Maybe<
      { __typename?: 'MessageThreadType' } & Pick<MessageThreadType, 'id'> & {
          student: { __typename?: 'StudentType' } & Pick<StudentType, 'id'> & {
              user: { __typename?: 'UserType' } & Pick<
                UserType,
                'id' | 'firstName' | 'lastName' | 'fullName'
              >;
            };
          mentor: { __typename?: 'MentorType' } & Pick<MentorType, 'id'> & {
              user: { __typename?: 'UserType' } & Pick<
                UserType,
                'id' | 'firstName' | 'lastName' | 'fullName'
              >;
            };
        }
    >;
  };

export type ExerciseProgressFragment = {
  __typename?: 'ExerciseProgressType';
} & Pick<
  ExerciseProgressType,
  'rating' | 'submissionComplete' | 'startedAt' | 'endedAt' | 's3Url'
>;

export type ExerciseInfoFragment = { __typename?: 'ExerciseType' } & Pick<
  ExerciseType,
  'id' | 'level' | 'optional' | 'soundsliceEmbedUrl'
> & {
    lastProgress: Maybe<
      { __typename?: 'ExerciseProgressType' } & Pick<
        ExerciseProgressType,
        'id'
      > &
        ExerciseProgressFragment
    >;
  };

export type LessonInfoFragment = { __typename?: 'LessonType' } & Pick<
  LessonType,
  | 'title'
  | 'description'
  | 's3ThumbnailUrl'
  | 'videoUrl'
  | 'supplementary'
  | 'supplementaryJSON'
> & {
    exercises: Maybe<
      Array<
        Maybe<
          { __typename?: 'ExerciseType' } & Pick<ExerciseType, 'id'> &
            ExerciseInfoFragment
        >
      >
    >;
  };

export type LessonGroupVideoFragment = {
  __typename?: 'LessonGroupVideoType';
} & Pick<
  LessonGroupVideoType,
  'id' | 'description' | 'videoUrl' | 's3ThumbnailUrl'
>;

export type ModuleInfoFragment = { __typename?: 'CurriculumModuleType' } & Pick<
  CurriculumModuleType,
  'title' | 'description'
>;

export type CurriculumLessonGroupFragment = {
  __typename?: 'LessonGroupType';
} & Pick<LessonGroupType, 'id' | 'title'> & {
    lessons: Maybe<
      Array<
        Maybe<
          { __typename?: 'LessonType' } & Pick<LessonType, 'id'> &
            LessonInfoFragment
        >
      >
    >;
    lessonGroupVideos: Maybe<
      Array<
        Maybe<
          { __typename?: 'LessonGroupVideoType' } & Pick<
            LessonGroupVideoType,
            'id'
          > &
            LessonGroupVideoFragment
        >
      >
    >;
  };

export type CurriculumFragment = { __typename?: 'CurriculumType' } & Pick<
  CurriculumType,
  'id' | 'title' | 'description'
> & {
    curriculumModules: Maybe<
      Array<
        Maybe<
          { __typename?: 'CurriculumModuleType' } & Pick<
            CurriculumModuleType,
            'id'
          > & {
              lessonGroups: Maybe<
                Array<
                  Maybe<
                    {
                      __typename?: 'LessonGroupType';
                    } & CurriculumLessonGroupFragment
                  >
                >
              >;
            } & ModuleInfoFragment
        >
      >
    >;
  };

export type SegmentFragment = { __typename?: 'SegmentType' } & Pick<
  SegmentType,
  | 'id'
  | 'createdAt'
  | 'completedExerciseIds'
  | 'reviewExerciseIds'
  | 'newExerciseIds'
>;

export type SettingsFragment = {};

export type CreateMessageMutationVariables = {
  contents: Scalars['String'];
  messageThreadId: Scalars['Int'];
  exerciseId?: Maybe<Scalars['Int']>;
};

export type CreateMessageMutation = { __typename?: 'Mutation' } & {
  createMessage: Maybe<
    { __typename?: 'CreateMessage' } & {
      message: { __typename?: 'MessageType' } & Pick<MessageType, 'id'>;
    }
  >;
};

export type ReadMessagesMutationVariables = {
  ids: Array<Maybe<Scalars['Int']>>;
};

export type ReadMessagesMutation = { __typename?: 'Mutation' } & {
  readMessages: Maybe<
    { __typename?: 'ReadMessages' } & Pick<ReadMessages, 'ok'>
  >;
};

export type UpdatePracticeRemindersMutationVariables = {
  enabled?: Maybe<Scalars['Boolean']>;
  notificationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type UpdatePracticeRemindersMutation = { __typename?: 'Mutation' } & {
  updatePracticeReminderSettings: Maybe<>;
};

export type CurrentUserQueryVariables = {};

export type CurrentUserQuery = { __typename?: 'Query' } & {
  user: Maybe<{ __typename?: 'UserType' } & UserFragment>;
};

export type SettingsQueryVariables = {};

export type SettingsQuery = { __typename?: 'Query' } & {
  userSettings: Maybe<>;
};

export type ChatThreadQueryVariables = {
  threadId: Scalars['Int'];
};

export type ChatThreadQuery = { __typename?: 'Query' } & {
  messageThread: Maybe<
    { __typename?: 'MessageThreadType' } & CurrentThreadInfoFragment
  >;
};

export type CurrentThreadInfoFragment = {
  __typename?: 'MessageThreadType';
} & Pick<MessageThreadType, 'id' | 'subject'> & {
    student: { __typename?: 'StudentType' } & Pick<StudentType, 'id'> & {
        user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & UserFragment;
        segments: Maybe<
          Array<
            Maybe<
              { __typename?: 'SegmentType' } & Pick<
                SegmentType,
                'id' | 'createdAt'
              >
            >
          >
        >;
      };
    mentor: { __typename?: 'MentorType' } & Pick<MentorType, 'id'> & {
        user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & UserFragment;
      };
  } & MessagesWithAttachmentsFragment;

export type ChatAllThreadsQueryVariables = {
  relationshipId?: Maybe<Scalars['Int']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
};

export type ChatAllThreadsQuery = { __typename?: 'Query' } & {
  messageThreads: Maybe<
    Array<Maybe<{ __typename?: 'MessageThreadType' } & CarouselDataFragment>>
  >;
};

export type CarouselDataFragment = { __typename?: 'MessageThreadType' } & Pick<
  MessageThreadType,
  'id' | 'subject' | 'unreadCount' | 's3ThumbnailUrl' | 'lastMessageAt'
> & {
    exercises: Maybe<
      Array<Maybe<{ __typename?: 'ExerciseType' } & Pick<ExerciseType, 'id'>>>
    >;
  };

export type ChatActivityQueryVariables = {
  relationshipId?: Maybe<Scalars['Int']>;
  since?: Maybe<Scalars['DateTime']>;
};

export type ChatActivityQuery = { __typename?: 'Query' } & {
  chats: Maybe<
    { __typename?: 'CursorChatType' } & Pick<CursorChatType, 'since'> & {
        chats: Maybe<
          Array<
            Maybe<
              | ({ __typename: 'MessageType' } & MessageUpdateFragment)
              | ({
                  __typename: 'MessageThreadType';
                } & MessageThreadUpdateFragment)
              | ({
                  __typename: 'MessageAttachmentType';
                } & MessageAttachmentUpdateFragment)
            >
          >
        >;
      }
  >;
};

export type MessageThreadUpdateFragment = {
  __typename?: 'MessageThreadType';
} & Pick<
  MessageThreadType,
  'id' | 'subject' | 's3ThumbnailUrl' | 'unreadCount' | 'lastActivity'
> & {
    exercises: Maybe<
      Array<Maybe<{ __typename?: 'ExerciseType' } & Pick<ExerciseType, 'id'>>>
    >;
  };

export type MessageUpdateFragment = { __typename?: 'MessageType' } & Pick<
  MessageType,
  'id' | 'contents' | 'read' | 'createdAt' | 'complete' | 'messageThreadId'
> & {
    attachments: Maybe<
      Array<
        Maybe<
          { __typename?: 'MessageAttachmentType' } & Pick<
            MessageAttachmentType,
            'id' | 's3Url' | 's3ThumbnailUrl' | 'complete'
          >
        >
      >
    >;
    sender: Maybe<
      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'userType'> &
        UserFragment
    >;
  };

export type MessageAttachmentUpdateFragment = {
  __typename?: 'MessageAttachmentType';
} & Pick<
  MessageAttachmentType,
  'id' | 's3Url' | 's3ThumbnailUrl' | 'messageId' | 'complete'
>;

export const PracticeScreenExerciseFragmentDoc = gql`
  fragment PracticeScreenExercise on ExerciseType {
    id
    lesson {
      id
      s3ThumbnailUrl
      title
      exercises {
        id
        optional
        lastProgress {
          id
          rating
        }
      }
      lessonGroup {
        id
        title
        curriculumModule {
          id
          title
        }
        lessons {
          id
        }
        lessonGroupVideos {
          id
          description
          videoUrl
          s3ThumbnailUrl
        }
      }
    }
  }
`;
export const ExerciseTitleFragmentDoc = gql`
  fragment ExerciseTitle on ExerciseType {
    id
    lesson {
      id
      title
      lessonGroup {
        id
        title
      }
    }
  }
`;
export const LastSegmentExercisesFragmentDoc = gql`
  fragment LastSegmentExercises on SegmentType {
    id
    newExerciseIds
    completedExerciseIds
    reviewExerciseIds
  }
`;
export const UserFragmentDoc = gql`
  fragment User on UserType {
    id
    firstName
    lastName
    fullName @client
    userType
    email
  }
`;
export const MessageFragmentDoc = gql`
  fragment Message on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const MessageAttachmentDownloadInfoFragmentDoc = gql`
  fragment MessageAttachmentDownloadInfo on MessageAttachmentType {
    id
    complete
    s3Url
    s3ThumbnailUrl
  }
`;
export const MentorIndexMessageThreadFragmentDoc = gql`
  fragment MentorIndexMessageThread on MessageThreadType {
    id
    lastActivity
    lastMessage {
      id
      contents
      attachments {
        ...MessageAttachmentDownloadInfo
      }
    }
    mentor {
      id
      user {
        id
        ...User
      }
    }
    student {
      id
      studentType
      level
      user {
        ...User
      }
    }
  }
  ${MessageAttachmentDownloadInfoFragmentDoc}
  ${UserFragmentDoc}
`;
export const MentorIndexStudentMentorRelationshipFragmentDoc = gql`
  fragment MentorIndexStudentMentorRelationship on StudentMentorRelationshipType {
    id
    startedAt
    endedAt
    unreadCount
    messageThread {
      ...MentorIndexMessageThread
    }
  }
  ${MentorIndexMessageThreadFragmentDoc}
`;
export const StudentTabRelationshipFragmentDoc = gql`
  fragment StudentTabRelationship on StudentMentorRelationshipType {
    id
    unreadCount
    startedAt
    endedAt
    messageThread {
      id
      student {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
      mentor {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
    }
  }
`;
export const ModuleInfoFragmentDoc = gql`
  fragment ModuleInfo on CurriculumModuleType {
    title
    description
  }
`;
export const ExerciseProgressFragmentDoc = gql`
  fragment ExerciseProgress on ExerciseProgressType {
    rating
    submissionComplete
    startedAt
    endedAt
    s3Url
  }
`;
export const ExerciseInfoFragmentDoc = gql`
  fragment ExerciseInfo on ExerciseType {
    id
    level
    optional
    soundsliceEmbedUrl
    lastProgress {
      id
      ...ExerciseProgress
    }
  }
  ${ExerciseProgressFragmentDoc}
`;
export const LessonInfoFragmentDoc = gql`
  fragment LessonInfo on LessonType {
    title
    description
    exercises {
      id
      ...ExerciseInfo
    }
    s3ThumbnailUrl
    videoUrl
    supplementary
    supplementaryJSON @client
  }
  ${ExerciseInfoFragmentDoc}
`;
export const LessonGroupVideoFragmentDoc = gql`
  fragment LessonGroupVideo on LessonGroupVideoType {
    id
    description
    videoUrl
    s3ThumbnailUrl
  }
`;
export const CurriculumLessonGroupFragmentDoc = gql`
  fragment CurriculumLessonGroup on LessonGroupType {
    id
    title
    lessons {
      id
      ...LessonInfo
    }
    lessonGroupVideos {
      id
      ...LessonGroupVideo
    }
  }
  ${LessonInfoFragmentDoc}
  ${LessonGroupVideoFragmentDoc}
`;
export const CurriculumFragmentDoc = gql`
  fragment Curriculum on CurriculumType {
    id
    title
    description
    curriculumModules {
      id
      ...ModuleInfo
      lessonGroups {
        ...CurriculumLessonGroup
      }
    }
  }
  ${ModuleInfoFragmentDoc}
  ${CurriculumLessonGroupFragmentDoc}
`;
export const SegmentFragmentDoc = gql`
  fragment Segment on SegmentType {
    id
    createdAt
    completedExerciseIds
    reviewExerciseIds
    newExerciseIds
  }
`;
export const SettingsFragmentDoc = gql`
  fragment Settings on UserSettings {
    practiceReminder {
      enabled
      date
      notificationId
    }
    chatNotifications
  }
`;
export const MessageWithAttachmentsFragmentDoc = gql`
  fragment MessageWithAttachments on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      userType
      ...User
    }
    attachments {
      ...MessageAttachmentDownloadInfo
    }
  }
  ${UserFragmentDoc}
  ${MessageAttachmentDownloadInfoFragmentDoc}
`;
export const MessagesWithAttachmentsFragmentDoc = gql`
  fragment MessagesWithAttachments on MessageThreadType {
    id
    messages {
      ...MessageWithAttachments
    }
  }
  ${MessageWithAttachmentsFragmentDoc}
`;
export const CurrentThreadInfoFragmentDoc = gql`
  fragment CurrentThreadInfo on MessageThreadType {
    id
    subject
    ...MessagesWithAttachments
    student {
      id
      user {
        id
        ...User
      }
      segments {
        id
        createdAt
      }
    }
    mentor {
      id
      user {
        id
        ...User
      }
    }
  }
  ${MessagesWithAttachmentsFragmentDoc}
  ${UserFragmentDoc}
`;
export const CarouselDataFragmentDoc = gql`
  fragment CarouselData on MessageThreadType {
    id
    subject
    unreadCount
    s3ThumbnailUrl
    lastMessageAt
    exercises {
      id
    }
  }
`;
export const MessageThreadUpdateFragmentDoc = gql`
  fragment MessageThreadUpdate on MessageThreadType {
    id
    subject
    s3ThumbnailUrl
    unreadCount
    lastActivity
    exercises {
      id
    }
  }
`;
export const MessageUpdateFragmentDoc = gql`
  fragment MessageUpdate on MessageType {
    id
    contents
    read
    createdAt
    complete
    attachments {
      id
      s3Url
      s3ThumbnailUrl
      complete
    }
    sender {
      id
      userType
      ...User
    }
    messageThreadId
  }
  ${UserFragmentDoc}
`;
export const MessageAttachmentUpdateFragmentDoc = gql`
  fragment MessageAttachmentUpdate on MessageAttachmentType {
    id
    s3Url
    s3ThumbnailUrl
    messageId
    complete
  }
`;
export const StudentTabUnreadPollDocument = gql`
  query StudentTabUnreadPoll {
    studentMentorRelationships {
      id
      unreadCount
    }
  }
`;

/**
 * __useStudentTabUnreadPollQuery__
 *
 * To run a query within a React component, call `useStudentTabUnreadPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTabUnreadPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTabUnreadPollQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentTabUnreadPollQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentTabUnreadPollQuery,
    StudentTabUnreadPollQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    StudentTabUnreadPollQuery,
    StudentTabUnreadPollQueryVariables
  >(StudentTabUnreadPollDocument, baseOptions);
}
export function useStudentTabUnreadPollLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentTabUnreadPollQuery,
    StudentTabUnreadPollQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StudentTabUnreadPollQuery,
    StudentTabUnreadPollQueryVariables
  >(StudentTabUnreadPollDocument, baseOptions);
}
export type StudentTabUnreadPollQueryHookResult = ReturnType<
  typeof useStudentTabUnreadPollQuery
>;
export type StudentTabUnreadPollLazyQueryHookResult = ReturnType<
  typeof useStudentTabUnreadPollLazyQuery
>;
export type StudentTabUnreadPollQueryResult = ApolloReactCommon.QueryResult<
  StudentTabUnreadPollQuery,
  StudentTabUnreadPollQueryVariables
>;
export const StudentTabDocument = gql`
  query StudentTab {
    user {
      id
      student {
        id
        studentType
      }
    }
    studentMentorRelationships {
      ...StudentTabRelationship
    }
  }
  ${StudentTabRelationshipFragmentDoc}
`;

/**
 * __useStudentTabQuery__
 *
 * To run a query within a React component, call `useStudentTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentTabQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentTabQuery,
    StudentTabQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StudentTabQuery, StudentTabQueryVariables>(
    StudentTabDocument,
    baseOptions
  );
}
export function useStudentTabLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentTabQuery,
    StudentTabQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StudentTabQuery,
    StudentTabQueryVariables
  >(StudentTabDocument, baseOptions);
}
export type StudentTabQueryHookResult = ReturnType<typeof useStudentTabQuery>;
export type StudentTabLazyQueryHookResult = ReturnType<
  typeof useStudentTabLazyQuery
>;
export type StudentTabQueryResult = ApolloReactCommon.QueryResult<
  StudentTabQuery,
  StudentTabQueryVariables
>;
export const ConsumeLoginTokenDocument = gql`
  mutation ConsumeLoginToken($token: String!, $password: String) {
    consumeLoginToken(uuid: $token, password: $password) {
      token
      expired
    }
  }
`;
export type ConsumeLoginTokenMutationFn = ApolloReactCommon.MutationFunction<
  ConsumeLoginTokenMutation,
  ConsumeLoginTokenMutationVariables
>;

/**
 * __useConsumeLoginTokenMutation__
 *
 * To run a mutation, you first call `useConsumeLoginTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeLoginTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeLoginTokenMutation, { data, loading, error }] = useConsumeLoginTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConsumeLoginTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConsumeLoginTokenMutation,
    ConsumeLoginTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConsumeLoginTokenMutation,
    ConsumeLoginTokenMutationVariables
  >(ConsumeLoginTokenDocument, baseOptions);
}
export type ConsumeLoginTokenMutationHookResult = ReturnType<
  typeof useConsumeLoginTokenMutation
>;
export type ConsumeLoginTokenMutationResult = ApolloReactCommon.MutationResult<
  ConsumeLoginTokenMutation
>;
export type ConsumeLoginTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConsumeLoginTokenMutation,
  ConsumeLoginTokenMutationVariables
>;
export const MentorChatStudentInfoDocument = gql`
  query MentorChatStudentInfo($mainThreadId: Int!) {
    messageThread(id: $mainThreadId) {
      id
      mentor {
        id
        user {
          id
          ...User
        }
      }
      student {
        id
        user {
          id
          ...User
        }
        segments {
          id
          createdAt
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useMentorChatStudentInfoQuery__
 *
 * To run a query within a React component, call `useMentorChatStudentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorChatStudentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorChatStudentInfoQuery({
 *   variables: {
 *      mainThreadId: // value for 'mainThreadId'
 *   },
 * });
 */
export function useMentorChatStudentInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MentorChatStudentInfoQuery,
    MentorChatStudentInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MentorChatStudentInfoQuery,
    MentorChatStudentInfoQueryVariables
  >(MentorChatStudentInfoDocument, baseOptions);
}
export function useMentorChatStudentInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MentorChatStudentInfoQuery,
    MentorChatStudentInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MentorChatStudentInfoQuery,
    MentorChatStudentInfoQueryVariables
  >(MentorChatStudentInfoDocument, baseOptions);
}
export type MentorChatStudentInfoQueryHookResult = ReturnType<
  typeof useMentorChatStudentInfoQuery
>;
export type MentorChatStudentInfoLazyQueryHookResult = ReturnType<
  typeof useMentorChatStudentInfoLazyQuery
>;
export type MentorChatStudentInfoQueryResult = ApolloReactCommon.QueryResult<
  MentorChatStudentInfoQuery,
  MentorChatStudentInfoQueryVariables
>;
export const ExerciseSimpleDocument = gql`
  query ExerciseSimple($exerciseId: Int!) {
    exercise(id: $exerciseId) {
      id
      level
      soundsliceEmbedUrl
    }
  }
`;

/**
 * __useExerciseSimpleQuery__
 *
 * To run a query within a React component, call `useExerciseSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useExerciseSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExerciseSimpleQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useExerciseSimpleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExerciseSimpleQuery,
    ExerciseSimpleQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExerciseSimpleQuery,
    ExerciseSimpleQueryVariables
  >(ExerciseSimpleDocument, baseOptions);
}
export function useExerciseSimpleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExerciseSimpleQuery,
    ExerciseSimpleQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExerciseSimpleQuery,
    ExerciseSimpleQueryVariables
  >(ExerciseSimpleDocument, baseOptions);
}
export type ExerciseSimpleQueryHookResult = ReturnType<
  typeof useExerciseSimpleQuery
>;
export type ExerciseSimpleLazyQueryHookResult = ReturnType<
  typeof useExerciseSimpleLazyQuery
>;
export type ExerciseSimpleQueryResult = ApolloReactCommon.QueryResult<
  ExerciseSimpleQuery,
  ExerciseSimpleQueryVariables
>;
export const MentorIndexDocument = gql`
  query MentorIndex {
    studentMentorRelationships {
      ...MentorIndexStudentMentorRelationship
    }
  }
  ${MentorIndexStudentMentorRelationshipFragmentDoc}
`;

/**
 * __useMentorIndexQuery__
 *
 * To run a query within a React component, call `useMentorIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentorIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentorIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useMentorIndexQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MentorIndexQuery,
    MentorIndexQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MentorIndexQuery, MentorIndexQueryVariables>(
    MentorIndexDocument,
    baseOptions
  );
}
export function useMentorIndexLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MentorIndexQuery,
    MentorIndexQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MentorIndexQuery,
    MentorIndexQueryVariables
  >(MentorIndexDocument, baseOptions);
}
export type MentorIndexQueryHookResult = ReturnType<typeof useMentorIndexQuery>;
export type MentorIndexLazyQueryHookResult = ReturnType<
  typeof useMentorIndexLazyQuery
>;
export type MentorIndexQueryResult = ApolloReactCommon.QueryResult<
  MentorIndexQuery,
  MentorIndexQueryVariables
>;
export const SignInUserDocument = gql`
  mutation SignInUser($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;
export type SignInUserMutationFn = ApolloReactCommon.MutationFunction<
  SignInUserMutation,
  SignInUserMutationVariables
>;

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignInUserMutation,
    SignInUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SignInUserMutation,
    SignInUserMutationVariables
  >(SignInUserDocument, baseOptions);
}
export type SignInUserMutationHookResult = ReturnType<
  typeof useSignInUserMutation
>;
export type SignInUserMutationResult = ApolloReactCommon.MutationResult<
  SignInUserMutation
>;
export type SignInUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignInUserMutation,
  SignInUserMutationVariables
>;
export const GenerateLoginEmailDocument = gql`
  mutation GenerateLoginEmail($email: String!) {
    generateLoginEmail(email: $email) {
      ok
    }
  }
`;
export type GenerateLoginEmailMutationFn = ApolloReactCommon.MutationFunction<
  GenerateLoginEmailMutation,
  GenerateLoginEmailMutationVariables
>;

/**
 * __useGenerateLoginEmailMutation__
 *
 * To run a mutation, you first call `useGenerateLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLoginEmailMutation, { data, loading, error }] = useGenerateLoginEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateLoginEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateLoginEmailMutation,
    GenerateLoginEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateLoginEmailMutation,
    GenerateLoginEmailMutationVariables
  >(GenerateLoginEmailDocument, baseOptions);
}
export type GenerateLoginEmailMutationHookResult = ReturnType<
  typeof useGenerateLoginEmailMutation
>;
export type GenerateLoginEmailMutationResult = ApolloReactCommon.MutationResult<
  GenerateLoginEmailMutation
>;
export type GenerateLoginEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateLoginEmailMutation,
  GenerateLoginEmailMutationVariables
>;
export const GeneratePasswordEmailDocument = gql`
  mutation GeneratePasswordEmail($email: String!) {
    generatePasswordEmail(email: $email) {
      ok
    }
  }
`;
export type GeneratePasswordEmailMutationFn = ApolloReactCommon.MutationFunction<
  GeneratePasswordEmailMutation,
  GeneratePasswordEmailMutationVariables
>;

/**
 * __useGeneratePasswordEmailMutation__
 *
 * To run a mutation, you first call `useGeneratePasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePasswordEmailMutation, { data, loading, error }] = useGeneratePasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGeneratePasswordEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GeneratePasswordEmailMutation,
    GeneratePasswordEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GeneratePasswordEmailMutation,
    GeneratePasswordEmailMutationVariables
  >(GeneratePasswordEmailDocument, baseOptions);
}
export type GeneratePasswordEmailMutationHookResult = ReturnType<
  typeof useGeneratePasswordEmailMutation
>;
export type GeneratePasswordEmailMutationResult = ApolloReactCommon.MutationResult<
  GeneratePasswordEmailMutation
>;
export type GeneratePasswordEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GeneratePasswordEmailMutation,
  GeneratePasswordEmailMutationVariables
>;
export const StudentChatBasicInfoDocument = gql`
  query StudentChatBasicInfo {
    user {
      id
      student {
        id
        studentType
      }
    }
    studentMentorRelationships {
      id
      endedAt
      mentor {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
      student {
        id
        user {
          id
          firstName
          lastName
        }
      }
      messageThread {
        id
      }
    }
  }
`;

/**
 * __useStudentChatBasicInfoQuery__
 *
 * To run a query within a React component, call `useStudentChatBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentChatBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentChatBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentChatBasicInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentChatBasicInfoQuery,
    StudentChatBasicInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    StudentChatBasicInfoQuery,
    StudentChatBasicInfoQueryVariables
  >(StudentChatBasicInfoDocument, baseOptions);
}
export function useStudentChatBasicInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentChatBasicInfoQuery,
    StudentChatBasicInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StudentChatBasicInfoQuery,
    StudentChatBasicInfoQueryVariables
  >(StudentChatBasicInfoDocument, baseOptions);
}
export type StudentChatBasicInfoQueryHookResult = ReturnType<
  typeof useStudentChatBasicInfoQuery
>;
export type StudentChatBasicInfoLazyQueryHookResult = ReturnType<
  typeof useStudentChatBasicInfoLazyQuery
>;
export type StudentChatBasicInfoQueryResult = ApolloReactCommon.QueryResult<
  StudentChatBasicInfoQuery,
  StudentChatBasicInfoQueryVariables
>;
export const ReportProgressDocument = gql`
  mutation ReportProgress($exerciseId: Int!, $segmentId: Int!, $rating: Int) {
    reportProgress(
      exerciseId: $exerciseId
      segmentId: $segmentId
      rating: $rating
    ) {
      progress {
        id
        exercise {
          id
        }
        rating
        submissionComplete
        startedAt
        endedAt
        uploadData {
          url
          fields
        }
      }
    }
  }
`;
export type ReportProgressMutationFn = ApolloReactCommon.MutationFunction<
  ReportProgressMutation,
  ReportProgressMutationVariables
>;

/**
 * __useReportProgressMutation__
 *
 * To run a mutation, you first call `useReportProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportProgressMutation, { data, loading, error }] = useReportProgressMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      segmentId: // value for 'segmentId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useReportProgressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReportProgressMutation,
    ReportProgressMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReportProgressMutation,
    ReportProgressMutationVariables
  >(ReportProgressDocument, baseOptions);
}
export type ReportProgressMutationHookResult = ReturnType<
  typeof useReportProgressMutation
>;
export type ReportProgressMutationResult = ApolloReactCommon.MutationResult<
  ReportProgressMutation
>;
export type ReportProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReportProgressMutation,
  ReportProgressMutationVariables
>;
export const ExerciseRatingScreenDocument = gql`
  query ExerciseRatingScreen($exerciseId: Int!) {
    lastSegment {
      id
    }
    exercise(id: $exerciseId) {
      id
      level
      soundsliceEmbedUrl
      ...ExerciseTitle
      lastProgress {
        id
        ...ExerciseProgress
      }
    }
    studentMentorRelationships {
      id
      endedAt
    }
    user {
      id
      student {
        id
        studentType
      }
    }
  }
  ${ExerciseTitleFragmentDoc}
  ${ExerciseProgressFragmentDoc}
`;

/**
 * __useExerciseRatingScreenQuery__
 *
 * To run a query within a React component, call `useExerciseRatingScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useExerciseRatingScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExerciseRatingScreenQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useExerciseRatingScreenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExerciseRatingScreenQuery,
    ExerciseRatingScreenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ExerciseRatingScreenQuery,
    ExerciseRatingScreenQueryVariables
  >(ExerciseRatingScreenDocument, baseOptions);
}
export function useExerciseRatingScreenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExerciseRatingScreenQuery,
    ExerciseRatingScreenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ExerciseRatingScreenQuery,
    ExerciseRatingScreenQueryVariables
  >(ExerciseRatingScreenDocument, baseOptions);
}
export type ExerciseRatingScreenQueryHookResult = ReturnType<
  typeof useExerciseRatingScreenQuery
>;
export type ExerciseRatingScreenLazyQueryHookResult = ReturnType<
  typeof useExerciseRatingScreenLazyQuery
>;
export type ExerciseRatingScreenQueryResult = ApolloReactCommon.QueryResult<
  ExerciseRatingScreenQuery,
  ExerciseRatingScreenQueryVariables
>;
export const ExerciseDocument = gql`
  query Exercise($exerciseId: Int!) {
    lastSegment {
      id
    }
    studentMentorRelationships {
      id
      endedAt
    }
    exercise(id: $exerciseId) {
      id
      level
      ...ExerciseTitle
      soundsliceEmbedUrl
      lastProgress {
        id
        ...ExerciseProgress
      }
    }
  }
  ${ExerciseTitleFragmentDoc}
  ${ExerciseProgressFragmentDoc}
`;

/**
 * __useExerciseQuery__
 *
 * To run a query within a React component, call `useExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExerciseQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useExerciseQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExerciseQuery,
    ExerciseQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ExerciseQuery, ExerciseQueryVariables>(
    ExerciseDocument,
    baseOptions
  );
}
export function useExerciseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExerciseQuery,
    ExerciseQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ExerciseQuery, ExerciseQueryVariables>(
    ExerciseDocument,
    baseOptions
  );
}
export type ExerciseQueryHookResult = ReturnType<typeof useExerciseQuery>;
export type ExerciseLazyQueryHookResult = ReturnType<
  typeof useExerciseLazyQuery
>;
export type ExerciseQueryResult = ApolloReactCommon.QueryResult<
  ExerciseQuery,
  ExerciseQueryVariables
>;
export const LessonGroupScreenDocument = gql`
  query LessonGroupScreen($lessonGroupId: Int!) {
    lessonGroup(id: $lessonGroupId) {
      ...CurriculumLessonGroup
    }
  }
  ${CurriculumLessonGroupFragmentDoc}
`;

/**
 * __useLessonGroupScreenQuery__
 *
 * To run a query within a React component, call `useLessonGroupScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonGroupScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonGroupScreenQuery({
 *   variables: {
 *      lessonGroupId: // value for 'lessonGroupId'
 *   },
 * });
 */
export function useLessonGroupScreenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LessonGroupScreenQuery,
    LessonGroupScreenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LessonGroupScreenQuery,
    LessonGroupScreenQueryVariables
  >(LessonGroupScreenDocument, baseOptions);
}
export function useLessonGroupScreenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LessonGroupScreenQuery,
    LessonGroupScreenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LessonGroupScreenQuery,
    LessonGroupScreenQueryVariables
  >(LessonGroupScreenDocument, baseOptions);
}
export type LessonGroupScreenQueryHookResult = ReturnType<
  typeof useLessonGroupScreenQuery
>;
export type LessonGroupScreenLazyQueryHookResult = ReturnType<
  typeof useLessonGroupScreenLazyQuery
>;
export type LessonGroupScreenQueryResult = ApolloReactCommon.QueryResult<
  LessonGroupScreenQuery,
  LessonGroupScreenQueryVariables
>;
export const LessonDocument = gql`
  query Lesson($lessonId: Int!) {
    lesson(id: $lessonId) {
      id
      ...LessonInfo
    }
    lastSegment {
      id
      newExerciseIds
      completedExerciseIds
      reviewExerciseIds
    }
  }
  ${LessonInfoFragmentDoc}
`;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useLessonQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LessonQuery,
    LessonQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LessonQuery, LessonQueryVariables>(
    LessonDocument,
    baseOptions
  );
}
export function useLessonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LessonQuery,
    LessonQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LessonQuery, LessonQueryVariables>(
    LessonDocument,
    baseOptions
  );
}
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonQueryResult = ApolloReactCommon.QueryResult<
  LessonQuery,
  LessonQueryVariables
>;
export const LessonsScreenDocument = gql`
  query LessonsScreen {
    enrollments {
      id
      curriculum {
        id
        ...Curriculum
      }
    }
  }
  ${CurriculumFragmentDoc}
`;

/**
 * __useLessonsScreenQuery__
 *
 * To run a query within a React component, call `useLessonsScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonsScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonsScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useLessonsScreenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LessonsScreenQuery,
    LessonsScreenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LessonsScreenQuery,
    LessonsScreenQueryVariables
  >(LessonsScreenDocument, baseOptions);
}
export function useLessonsScreenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LessonsScreenQuery,
    LessonsScreenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LessonsScreenQuery,
    LessonsScreenQueryVariables
  >(LessonsScreenDocument, baseOptions);
}
export type LessonsScreenQueryHookResult = ReturnType<
  typeof useLessonsScreenQuery
>;
export type LessonsScreenLazyQueryHookResult = ReturnType<
  typeof useLessonsScreenLazyQuery
>;
export type LessonsScreenQueryResult = ApolloReactCommon.QueryResult<
  LessonsScreenQuery,
  LessonsScreenQueryVariables
>;
export const LastSegmentDocument = gql`
  query LastSegment {
    lastSegment {
      ...LastSegmentExercises
    }
  }
  ${LastSegmentExercisesFragmentDoc}
`;

/**
 * __useLastSegmentQuery__
 *
 * To run a query within a React component, call `useLastSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastSegmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastSegmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LastSegmentQuery,
    LastSegmentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LastSegmentQuery, LastSegmentQueryVariables>(
    LastSegmentDocument,
    baseOptions
  );
}
export function useLastSegmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LastSegmentQuery,
    LastSegmentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LastSegmentQuery,
    LastSegmentQueryVariables
  >(LastSegmentDocument, baseOptions);
}
export type LastSegmentQueryHookResult = ReturnType<typeof useLastSegmentQuery>;
export type LastSegmentLazyQueryHookResult = ReturnType<
  typeof useLastSegmentLazyQuery
>;
export type LastSegmentQueryResult = ApolloReactCommon.QueryResult<
  LastSegmentQuery,
  LastSegmentQueryVariables
>;
export const PracticeScreenExercisesDocument = gql`
  query PracticeScreenExercises($ids: [Int]) {
    exercises(ids: $ids) {
      ...PracticeScreenExercise
    }
  }
  ${PracticeScreenExerciseFragmentDoc}
`;

/**
 * __usePracticeScreenExercisesQuery__
 *
 * To run a query within a React component, call `usePracticeScreenExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeScreenExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeScreenExercisesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePracticeScreenExercisesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PracticeScreenExercisesQuery,
    PracticeScreenExercisesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PracticeScreenExercisesQuery,
    PracticeScreenExercisesQueryVariables
  >(PracticeScreenExercisesDocument, baseOptions);
}
export function usePracticeScreenExercisesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PracticeScreenExercisesQuery,
    PracticeScreenExercisesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PracticeScreenExercisesQuery,
    PracticeScreenExercisesQueryVariables
  >(PracticeScreenExercisesDocument, baseOptions);
}
export type PracticeScreenExercisesQueryHookResult = ReturnType<
  typeof usePracticeScreenExercisesQuery
>;
export type PracticeScreenExercisesLazyQueryHookResult = ReturnType<
  typeof usePracticeScreenExercisesLazyQuery
>;
export type PracticeScreenExercisesQueryResult = ApolloReactCommon.QueryResult<
  PracticeScreenExercisesQuery,
  PracticeScreenExercisesQueryVariables
>;
export const StudentMentorRelationshipsDocument = gql`
  query StudentMentorRelationships {
    studentMentorRelationships {
      id
    }
  }
`;

/**
 * __useStudentMentorRelationshipsQuery__
 *
 * To run a query within a React component, call `useStudentMentorRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentMentorRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentMentorRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentMentorRelationshipsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentMentorRelationshipsQuery,
    StudentMentorRelationshipsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    StudentMentorRelationshipsQuery,
    StudentMentorRelationshipsQueryVariables
  >(StudentMentorRelationshipsDocument, baseOptions);
}
export function useStudentMentorRelationshipsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentMentorRelationshipsQuery,
    StudentMentorRelationshipsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StudentMentorRelationshipsQuery,
    StudentMentorRelationshipsQueryVariables
  >(StudentMentorRelationshipsDocument, baseOptions);
}
export type StudentMentorRelationshipsQueryHookResult = ReturnType<
  typeof useStudentMentorRelationshipsQuery
>;
export type StudentMentorRelationshipsLazyQueryHookResult = ReturnType<
  typeof useStudentMentorRelationshipsLazyQuery
>;
export type StudentMentorRelationshipsQueryResult = ApolloReactCommon.QueryResult<
  StudentMentorRelationshipsQuery,
  StudentMentorRelationshipsQueryVariables
>;
export const CreateNextSegmentDocument = gql`
  mutation CreateNextSegment($duration: Int!) {
    createNextSegment(duration: $duration) {
      segment {
        id
        newExerciseIds
        completedExerciseIds
        reviewExerciseIds
      }
    }
  }
`;
export type CreateNextSegmentMutationFn = ApolloReactCommon.MutationFunction<
  CreateNextSegmentMutation,
  CreateNextSegmentMutationVariables
>;

/**
 * __useCreateNextSegmentMutation__
 *
 * To run a mutation, you first call `useCreateNextSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextSegmentMutation, { data, loading, error }] = useCreateNextSegmentMutation({
 *   variables: {
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useCreateNextSegmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNextSegmentMutation,
    CreateNextSegmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateNextSegmentMutation,
    CreateNextSegmentMutationVariables
  >(CreateNextSegmentDocument, baseOptions);
}
export type CreateNextSegmentMutationHookResult = ReturnType<
  typeof useCreateNextSegmentMutation
>;
export type CreateNextSegmentMutationResult = ApolloReactCommon.MutationResult<
  CreateNextSegmentMutation
>;
export type CreateNextSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNextSegmentMutation,
  CreateNextSegmentMutationVariables
>;
export const StudentHomeScreenDocument = gql`
  query StudentHomeScreen {
    lastSegment {
      id
      createdAt
      newExerciseIds
      completedExerciseIds
      reviewExerciseIds
    }
    user {
      ...User
      student {
        id
        studentType
        segments {
          id
          createdAt
        }
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useStudentHomeScreenQuery__
 *
 * To run a query within a React component, call `useStudentHomeScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentHomeScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentHomeScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentHomeScreenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StudentHomeScreenQuery,
    StudentHomeScreenQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    StudentHomeScreenQuery,
    StudentHomeScreenQueryVariables
  >(StudentHomeScreenDocument, baseOptions);
}
export function useStudentHomeScreenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentHomeScreenQuery,
    StudentHomeScreenQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StudentHomeScreenQuery,
    StudentHomeScreenQueryVariables
  >(StudentHomeScreenDocument, baseOptions);
}
export type StudentHomeScreenQueryHookResult = ReturnType<
  typeof useStudentHomeScreenQuery
>;
export type StudentHomeScreenLazyQueryHookResult = ReturnType<
  typeof useStudentHomeScreenLazyQuery
>;
export type StudentHomeScreenQueryResult = ApolloReactCommon.QueryResult<
  StudentHomeScreenQuery,
  StudentHomeScreenQueryVariables
>;
export const SetPushTokenDocument = gql`
  mutation SetPushToken($token: String!) {
    setPushToken(token: $token) {
      ok
    }
  }
`;
export type SetPushTokenMutationFn = ApolloReactCommon.MutationFunction<
  SetPushTokenMutation,
  SetPushTokenMutationVariables
>;

/**
 * __useSetPushTokenMutation__
 *
 * To run a mutation, you first call `useSetPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPushTokenMutation, { data, loading, error }] = useSetPushTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetPushTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPushTokenMutation,
    SetPushTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetPushTokenMutation,
    SetPushTokenMutationVariables
  >(SetPushTokenDocument, baseOptions);
}
export type SetPushTokenMutationHookResult = ReturnType<
  typeof useSetPushTokenMutation
>;
export type SetPushTokenMutationResult = ApolloReactCommon.MutationResult<
  SetPushTokenMutation
>;
export type SetPushTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPushTokenMutation,
  SetPushTokenMutationVariables
>;
export const CreateMessageAttachmentThreadDocument = gql`
  mutation CreateMessageAttachmentThread(
    $relationshipId: Int!
    $subject: String = "Attachment"
    $exerciseProgressId: Int
  ) {
    createMessageThread(
      contents: "VideoThread"
      relationshipId: $relationshipId
      subject: $subject
      exerciseProgressId: $exerciseProgressId
    ) {
      messageThread {
        id
      }
    }
  }
`;
export type CreateMessageAttachmentThreadMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessageAttachmentThreadMutation,
  CreateMessageAttachmentThreadMutationVariables
>;

/**
 * __useCreateMessageAttachmentThreadMutation__
 *
 * To run a mutation, you first call `useCreateMessageAttachmentThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageAttachmentThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageAttachmentThreadMutation, { data, loading, error }] = useCreateMessageAttachmentThreadMutation({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      subject: // value for 'subject'
 *      exerciseProgressId: // value for 'exerciseProgressId'
 *   },
 * });
 */
export function useCreateMessageAttachmentThreadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessageAttachmentThreadMutation,
    CreateMessageAttachmentThreadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMessageAttachmentThreadMutation,
    CreateMessageAttachmentThreadMutationVariables
  >(CreateMessageAttachmentThreadDocument, baseOptions);
}
export type CreateMessageAttachmentThreadMutationHookResult = ReturnType<
  typeof useCreateMessageAttachmentThreadMutation
>;
export type CreateMessageAttachmentThreadMutationResult = ApolloReactCommon.MutationResult<
  CreateMessageAttachmentThreadMutation
>;
export type CreateMessageAttachmentThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessageAttachmentThreadMutation,
  CreateMessageAttachmentThreadMutationVariables
>;
export const StartMessageDocument = gql`
  mutation StartMessage($messageThreadId: Int!, $exerciseId: Int) {
    startMessage(
      contents: ""
      messageThreadId: $messageThreadId
      exerciseId: $exerciseId
    ) {
      message {
        id
      }
    }
  }
`;
export type StartMessageMutationFn = ApolloReactCommon.MutationFunction<
  StartMessageMutation,
  StartMessageMutationVariables
>;

/**
 * __useStartMessageMutation__
 *
 * To run a mutation, you first call `useStartMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMessageMutation, { data, loading, error }] = useStartMessageMutation({
 *   variables: {
 *      messageThreadId: // value for 'messageThreadId'
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useStartMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartMessageMutation,
    StartMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    StartMessageMutation,
    StartMessageMutationVariables
  >(StartMessageDocument, baseOptions);
}
export type StartMessageMutationHookResult = ReturnType<
  typeof useStartMessageMutation
>;
export type StartMessageMutationResult = ApolloReactCommon.MutationResult<
  StartMessageMutation
>;
export type StartMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartMessageMutation,
  StartMessageMutationVariables
>;
export const CreateMessageAttachmentDocument = gql`
  mutation CreateMessageAttachment($messageId: Int!) {
    createMessageAttachment(id: $messageId) {
      messageAttachment {
        id
        uploadData {
          url
          fields
        }
      }
    }
  }
`;
export type CreateMessageAttachmentMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessageAttachmentMutation,
  CreateMessageAttachmentMutationVariables
>;

/**
 * __useCreateMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageAttachmentMutation, { data, loading, error }] = useCreateMessageAttachmentMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useCreateMessageAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessageAttachmentMutation,
    CreateMessageAttachmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMessageAttachmentMutation,
    CreateMessageAttachmentMutationVariables
  >(CreateMessageAttachmentDocument, baseOptions);
}
export type CreateMessageAttachmentMutationHookResult = ReturnType<
  typeof useCreateMessageAttachmentMutation
>;
export type CreateMessageAttachmentMutationResult = ApolloReactCommon.MutationResult<
  CreateMessageAttachmentMutation
>;
export type CreateMessageAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessageAttachmentMutation,
  CreateMessageAttachmentMutationVariables
>;
export const PublishMessageDocument = gql`
  mutation PublishMessage($messageId: Int!, $exerciseId: Int) {
    publishMessage(id: $messageId, exerciseId: $exerciseId) {
      ok
    }
  }
`;
export type PublishMessageMutationFn = ApolloReactCommon.MutationFunction<
  PublishMessageMutation,
  PublishMessageMutationVariables
>;

/**
 * __usePublishMessageMutation__
 *
 * To run a mutation, you first call `usePublishMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMessageMutation, { data, loading, error }] = usePublishMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function usePublishMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishMessageMutation,
    PublishMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PublishMessageMutation,
    PublishMessageMutationVariables
  >(PublishMessageDocument, baseOptions);
}
export type PublishMessageMutationHookResult = ReturnType<
  typeof usePublishMessageMutation
>;
export type PublishMessageMutationResult = ApolloReactCommon.MutationResult<
  PublishMessageMutation
>;
export type PublishMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PublishMessageMutation,
  PublishMessageMutationVariables
>;
export const CompleteMessageAttachmentDocument = gql`
  mutation CompleteMessageAttachment($attachmentId: Int!) {
    completeMessageAttachment(id: $attachmentId) {
      ok
    }
  }
`;
export type CompleteMessageAttachmentMutationFn = ApolloReactCommon.MutationFunction<
  CompleteMessageAttachmentMutation,
  CompleteMessageAttachmentMutationVariables
>;

/**
 * __useCompleteMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useCompleteMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMessageAttachmentMutation, { data, loading, error }] = useCompleteMessageAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useCompleteMessageAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompleteMessageAttachmentMutation,
    CompleteMessageAttachmentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CompleteMessageAttachmentMutation,
    CompleteMessageAttachmentMutationVariables
  >(CompleteMessageAttachmentDocument, baseOptions);
}
export type CompleteMessageAttachmentMutationHookResult = ReturnType<
  typeof useCompleteMessageAttachmentMutation
>;
export type CompleteMessageAttachmentMutationResult = ApolloReactCommon.MutationResult<
  CompleteMessageAttachmentMutation
>;
export type CompleteMessageAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompleteMessageAttachmentMutation,
  CompleteMessageAttachmentMutationVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage(
    $contents: String!
    $messageThreadId: Int!
    $exerciseId: Int
  ) {
    createMessage(
      contents: $contents
      messageThreadId: $messageThreadId
      exerciseId: $exerciseId
    ) {
      message {
        id
      }
    }
  }
`;
export type CreateMessageMutationFn = ApolloReactCommon.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      contents: // value for 'contents'
 *      messageThreadId: // value for 'messageThreadId'
 *      exerciseId: // value for 'exerciseId'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >(CreateMessageDocument, baseOptions);
}
export type CreateMessageMutationHookResult = ReturnType<
  typeof useCreateMessageMutation
>;
export type CreateMessageMutationResult = ApolloReactCommon.MutationResult<
  CreateMessageMutation
>;
export type CreateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const ReadMessagesDocument = gql`
  mutation ReadMessages($ids: [Int]!) {
    readMessages(ids: $ids) {
      ok
    }
  }
`;
export type ReadMessagesMutationFn = ApolloReactCommon.MutationFunction<
  ReadMessagesMutation,
  ReadMessagesMutationVariables
>;

/**
 * __useReadMessagesMutation__
 *
 * To run a mutation, you first call `useReadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesMutation, { data, loading, error }] = useReadMessagesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadMessagesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReadMessagesMutation,
    ReadMessagesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReadMessagesMutation,
    ReadMessagesMutationVariables
  >(ReadMessagesDocument, baseOptions);
}
export type ReadMessagesMutationHookResult = ReturnType<
  typeof useReadMessagesMutation
>;
export type ReadMessagesMutationResult = ApolloReactCommon.MutationResult<
  ReadMessagesMutation
>;
export type ReadMessagesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReadMessagesMutation,
  ReadMessagesMutationVariables
>;
export const UpdatePracticeRemindersDocument = gql`
  mutation UpdatePracticeReminders(
    $enabled: Boolean
    $notificationId: String
    $date: DateTime
  ) {
    updatePracticeReminderSettings(
      enabled: $enabled
      notificationId: $notificationId
      date: $date
    ) @client {
      enabled
    }
  }
`;
export type UpdatePracticeRemindersMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePracticeRemindersMutation,
  UpdatePracticeRemindersMutationVariables
>;

/**
 * __useUpdatePracticeRemindersMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeRemindersMutation, { data, loading, error }] = useUpdatePracticeRemindersMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      notificationId: // value for 'notificationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdatePracticeRemindersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePracticeRemindersMutation,
    UpdatePracticeRemindersMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePracticeRemindersMutation,
    UpdatePracticeRemindersMutationVariables
  >(UpdatePracticeRemindersDocument, baseOptions);
}
export type UpdatePracticeRemindersMutationHookResult = ReturnType<
  typeof useUpdatePracticeRemindersMutation
>;
export type UpdatePracticeRemindersMutationResult = ApolloReactCommon.MutationResult<
  UpdatePracticeRemindersMutation
>;
export type UpdatePracticeRemindersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePracticeRemindersMutation,
  UpdatePracticeRemindersMutationVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >(CurrentUserDocument, baseOptions);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const SettingsDocument = gql`
  query Settings {
    userSettings @client {
      ...Settings
    }
  }
  ${SettingsFragmentDoc}
`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SettingsQuery,
    SettingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SettingsQuery, SettingsQueryVariables>(
    SettingsDocument,
    baseOptions
  );
}
export function useSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SettingsQuery,
    SettingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SettingsQuery, SettingsQueryVariables>(
    SettingsDocument,
    baseOptions
  );
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<
  typeof useSettingsLazyQuery
>;
export type SettingsQueryResult = ApolloReactCommon.QueryResult<
  SettingsQuery,
  SettingsQueryVariables
>;
export const ChatThreadDocument = gql`
  query ChatThread($threadId: Int!) {
    messageThread(id: $threadId) {
      ...CurrentThreadInfo
    }
  }
  ${CurrentThreadInfoFragmentDoc}
`;

/**
 * __useChatThreadQuery__
 *
 * To run a query within a React component, call `useChatThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatThreadQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useChatThreadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ChatThreadQuery,
    ChatThreadQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ChatThreadQuery, ChatThreadQueryVariables>(
    ChatThreadDocument,
    baseOptions
  );
}
export function useChatThreadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ChatThreadQuery,
    ChatThreadQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ChatThreadQuery,
    ChatThreadQueryVariables
  >(ChatThreadDocument, baseOptions);
}
export type ChatThreadQueryHookResult = ReturnType<typeof useChatThreadQuery>;
export type ChatThreadLazyQueryHookResult = ReturnType<
  typeof useChatThreadLazyQuery
>;
export type ChatThreadQueryResult = ApolloReactCommon.QueryResult<
  ChatThreadQuery,
  ChatThreadQueryVariables
>;
export const ChatAllThreadsDocument = gql`
  query ChatAllThreads($relationshipId: Int, $hasAttachments: Boolean = false) {
    messageThreads(
      relationshipId: $relationshipId
      hasAttachments: $hasAttachments
    ) {
      ...CarouselData
    }
  }
  ${CarouselDataFragmentDoc}
`;

/**
 * __useChatAllThreadsQuery__
 *
 * To run a query within a React component, call `useChatAllThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatAllThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAllThreadsQuery({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      hasAttachments: // value for 'hasAttachments'
 *   },
 * });
 */
export function useChatAllThreadsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ChatAllThreadsQuery,
    ChatAllThreadsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ChatAllThreadsQuery,
    ChatAllThreadsQueryVariables
  >(ChatAllThreadsDocument, baseOptions);
}
export function useChatAllThreadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ChatAllThreadsQuery,
    ChatAllThreadsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ChatAllThreadsQuery,
    ChatAllThreadsQueryVariables
  >(ChatAllThreadsDocument, baseOptions);
}
export type ChatAllThreadsQueryHookResult = ReturnType<
  typeof useChatAllThreadsQuery
>;
export type ChatAllThreadsLazyQueryHookResult = ReturnType<
  typeof useChatAllThreadsLazyQuery
>;
export type ChatAllThreadsQueryResult = ApolloReactCommon.QueryResult<
  ChatAllThreadsQuery,
  ChatAllThreadsQueryVariables
>;
export const ChatActivityDocument = gql`
  query ChatActivity($relationshipId: Int, $since: DateTime) {
    chats(relationshipId: $relationshipId, since: $since) {
      since
      chats {
        __typename
        ... on MessageType {
          ...MessageUpdate
        }
        ... on MessageThreadType {
          ...MessageThreadUpdate
        }
        ... on MessageAttachmentType {
          ...MessageAttachmentUpdate
        }
      }
    }
  }
  ${MessageUpdateFragmentDoc}
  ${MessageThreadUpdateFragmentDoc}
  ${MessageAttachmentUpdateFragmentDoc}
`;

/**
 * __useChatActivityQuery__
 *
 * To run a query within a React component, call `useChatActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatActivityQuery({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useChatActivityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ChatActivityQuery,
    ChatActivityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ChatActivityQuery,
    ChatActivityQueryVariables
  >(ChatActivityDocument, baseOptions);
}
export function useChatActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ChatActivityQuery,
    ChatActivityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ChatActivityQuery,
    ChatActivityQueryVariables
  >(ChatActivityDocument, baseOptions);
}
export type ChatActivityQueryHookResult = ReturnType<
  typeof useChatActivityQuery
>;
export type ChatActivityLazyQueryHookResult = ReturnType<
  typeof useChatActivityLazyQuery
>;
export type ChatActivityQueryResult = ApolloReactCommon.QueryResult<
  ChatActivityQuery,
  ChatActivityQueryVariables
>;
