import React, { useEffect } from 'react';
import {
  TouchableOpacity,
  SafeAreaView,
  View,
  Dimensions,
  StyleSheet,
} from 'react-native';
import { ScrollView } from 'react-navigation';
import gql from 'graphql-tag';
import { useNavigation } from 'react-navigation-hooks';
import any from 'ramda/src/any';

import { rhythm, colors } from '../../designSystem';
import { lessonInfo } from '../../graphql/fragments';
import { useLessonQuery } from '../../graphql/types';
import { Badge } from '../../components/Badge';
import { Button } from '../../components/Button';
import { ListCard } from '../../components/ListCard';
import { Loading } from '../../components/Loading';
import { Section } from '../../components/Section';
import { SectionHeader } from '../../components/SectionHeader';
import { StarRating } from '../../components/StarRating';
import { VideoCard } from '../../components/VideoCard';
import { ErrorMessage, MissingDataError } from '../../components/ErrorMessage';
import { LessonScreenStrings as S } from '../../strings';
import { Fade } from '../../components/Fade';
import { NavigationScreenType } from '../../utils/navigationUtils';
import { useIsLessonBrowser } from '../../hooks/useIsLessonBrowser';
import { useIsMobileLayout } from '../../hooks/useMobileLayout';
import { exercisesForSegment } from '../../dataLoader';
import { usePortraitLockExceptIpad } from '../../hooks/useMobileOrientationLock';

interface LessonScreenParams {
  lessonId: string;
  moduleTitle: string;
  lessonGroupTitle: string;
  onComplete: (lessonId: string) => void;
}

export const LESSON_QUERY = gql`
  query Lesson($lessonId: Int!) {
    lesson(id: $lessonId) {
      id
      ...LessonInfo
    }

    lastSegment {
      id
      newExerciseIds
      completedExerciseIds
      reviewExerciseIds
    }
  }
  ${lessonInfo}
`;

interface ExerciseCardProps {
  exerciseIndex: number;
  isRequired: boolean;
  rating?: number;
}
const ExerciseCard: React.FC<ExerciseCardProps> = ({
  exerciseIndex,
  rating,
  isRequired,
}) => {
  return (
    <ListCard
      title={`Exercise ${exerciseIndex + 1}`}
      subtitle={isRequired ? S.exerciseList.required : S.exerciseList.optional}
    >
      {rating !== undefined ? (
        <StarRating rating={rating} size={16} color={colors.accentDarken} />
      ) : (
        <Badge type={isRequired ? 'required' : 'optional'} status="initial" />
      )}
    </ListCard>
  );
};

const VIEWRATIO = 57.0 / 100.0;

export const LessonScreen: NavigationScreenType = () => {
  const navigation = useNavigation<LessonScreenParams>();
  const isLessonBrowser = useIsLessonBrowser();
  const lessonId = navigation.getParam('lessonId');
  const moduleTitle = navigation.getParam(
    'moduleTitle'
  ) as LessonScreenParams['moduleTitle'];
  const lessonGroupTitle = navigation.getParam(
    'lessonGroupTitle'
  ) as LessonScreenParams['lessonGroupTitle'];
  const onComplete = navigation.getParam(
    'onComplete',
    null
  ) as LessonScreenParams['onComplete'];

  usePortraitLockExceptIpad({
    debugId: 'lesson-screen',
    onlyWhenFocused: true,
    isFocused: navigation.isFocused(),
  });

  const { data, loading, error } = useLessonQuery({ variables: { lessonId } });
  const [isMobileLayout, updateIsMobileLayout] = useIsMobileLayout();

  Dimensions.addEventListener('change', updateIsMobileLayout);
  useEffect(() => {
    return () => Dimensions.removeEventListener('change', updateIsMobileLayout);
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  if (!data || !data.lesson || !data.lesson.exercises)
    return <MissingDataError type="Lesson Screen" />;

  const { lesson } = data;
  if (!lesson.s3ThumbnailUrl || !lesson.videoUrl)
    throw new Error('lesson should have video URL and thumbnail');

  const exerciseRatings: number[] =
    (lesson &&
      lesson.exercises &&
      lesson.exercises.map(
        ex => (ex && ex.lastProgress && ex.lastProgress.rating) || 0
      )) ||
    [];

  const segmentExercises = exercisesForSegment(data);
  // When in practice mode, filter the available exercises based
  // on whether they are in the segment.
  // For lesson browser, do no such filtering.
  const availableExercises =
    lesson &&
    lesson.exercises &&
    (isLessonBrowser
      ? lesson.exercises
      : lesson.exercises.filter(
          e => e && segmentExercises.includes(parseInt(e.id))
        ));

  const hasIncompleteExercises = any(rating => !rating, exerciseRatings);
  const hasSupplementaryMaterials =
    lesson.supplementaryJSON && lesson.supplementaryJSON.link;

  const onOpenSupplementary = () => {
    if (!hasSupplementaryMaterials)
      throw new Error('No Supplementary Materials link provided.');
    navigation.navigate('WebViewFullScreen', {
      uri: lesson.supplementaryJSON.link,
      title: lesson.title,
    });
  };

  const onPressComplete = () =>
    typeof onComplete === 'function'
      ? onComplete(lesson.id)
      : navigation.goBack();

  return (
    <>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{
            backgroundColor: colors.lightGrey,
            marginBottom: isLessonBrowser ? 0 : rhythm[5],
          }}
        >
          <View style={{ height: '100%' }}>
            <View
              style={{
                flex: 1,
                flexDirection: isMobileLayout ? 'column' : 'row',
              }}
            >
              <View
                style={[
                  styles.container,
                  { flex: !isMobileLayout ? VIEWRATIO : undefined },
                ]}
              >
                <VideoCard
                  header={`${moduleTitle} / ${lessonGroupTitle}:`}
                  title={lesson.title}
                  description={lesson.description}
                  videoUrl={lesson.videoUrl}
                  posterSource={lesson.s3ThumbnailUrl}
                />
              </View>
              <View
                style={[
                  styles.container,
                  { flex: !isMobileLayout ? 1.0 - VIEWRATIO : undefined },
                ]}
              >
                <Section>
                  <SectionHeader>{S.exerciseList.title}</SectionHeader>
                  {availableExercises &&
                    availableExercises.map((exercise, i) => {
                      if (!exercise) return null;
                      return (
                        <TouchableOpacity
                          onPress={_ =>
                            navigation.navigate('ExerciseScreen', {
                              exerciseId: exercise.id,
                              isReviewExerciseFlow:
                                exercise.lastProgress &&
                                exercise.lastProgress.rating === 5,
                            })
                          }
                          key={`${i}-${exercise.soundsliceEmbedUrl}`}
                        >
                          <ExerciseCard
                            exerciseIndex={i}
                            isRequired={!exercise.optional}
                            rating={
                              (exercise.lastProgress &&
                                exercise.lastProgress.rating) ||
                              undefined
                            }
                          />
                        </TouchableOpacity>
                      );
                    })}
                </Section>
                {hasSupplementaryMaterials && (
                  <Section>
                    <SectionHeader>{S.supplementary.title}</SectionHeader>
                    <TouchableOpacity onPress={onOpenSupplementary}>
                      <ListCard title={S.supplementary.linkText} />
                    </TouchableOpacity>
                  </Section>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
        {!isLessonBrowser && (
          <Fade padded>
            <Button
              onPress={onPressComplete}
              title={S.button.nextLesson}
              disabled={hasIncompleteExercises}
            />
          </Fade>
        )}
      </SafeAreaView>
    </>
  );
};

LessonScreen.navigationOptions = ({ navigation }) => ({
  title: navigation.getParam('title'),
});

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: rhythm[2],
  },
});
