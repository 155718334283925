import React, { useRef, forwardRef } from 'react';
import { NativeSyntheticEvent } from 'react-native';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';
import { byPlatform } from '../utils/platform';

const WebView = byPlatform({
  web: WebViewWeb,
  mobile: WebViewMobile,
});

import {
  WebViewMessage,
  WebViewError,
  WebViewNavigation,
} from 'react-native-webview/lib/WebViewTypes';

interface SoundsliceProps {
  url: string;
  onMessage?: (e: NativeSyntheticEvent<WebViewMessage>) => void;
  onError?: (e: NativeSyntheticEvent<WebViewError>) => void;
  onLoad?: (e: NativeSyntheticEvent<WebViewNavigation>) => void;
}

export const Soundslice = forwardRef<JSX.Element, SoundsliceProps>(
  (props, ref) => {
    const { url, onMessage, onError, onLoad } = props;
    const fullUrl =
      url + '?api=1&branding=2&top_controls=1&enable_mixer=0&synth_display_name=Piano';

    return (
      <WebView
        ref={ref}
        style={{ flex: 1 }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
        source={{ uri: fullUrl }}
        onMessage={onMessage}
        onError={onError}
        onLoad={onLoad}
        allowsInlineMediaPlayback={true}
      />
    );
  }
);
