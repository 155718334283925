import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  ViewStyle,
  Text,
  StyleSheet,
} from 'react-native';
import { rhythm, colors } from '../designSystem';
import { byPlatform } from '../utils/platform';

const HEADER_HEIGHT = rhythm[6];
const FOOTER_HEIGHT = rhythm[5];

export const PrivacyPolicy = _ => (
  <View style={styles.container}>
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={styles.scrollView}>
        <Text style={styles.h1Style}>
          Privacy Policy of Guitar Mastery Intensive
        </Text>
        <Text style={styles.pStyle}>
          Guitar Mastery Intensive operates the guitarmasteryintensive.com
          website, which provides the SERVICE. This page is used to inform
          website visitors regarding our policies with the collection, use, and
          disclosure of Personal Information if anyone decided to use our
          Service, the Guitar Mastery Intensive website. If you choose to use
          our Service, then you agree to the collection and use of information
          in relation with this policy. The Personal Information that we collect
          are used for providing and improving the Service. We will not use or
          share your information with anyone except as described in this Privacy
          Policy. The terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, which is accessible at
          guitarmasteryintensive.com, unless otherwise defined in this Privacy
          Policy.{' '}
        </Text>

        <Text style={styles.h2Style}>Information Collection and Use</Text>
        <Text style={styles.pStyle}>
          For a better experience while using our Service, we may require you to
          provide us with certain personally identifiable information, including
          but not limited to your name, phone number, and postal address. The
          information that we collect will be used to contact or identify you.
        </Text>

        <Text style={styles.h2Style}>Log Data</Text>
        <Text style={styles.pStyle}>
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer’s Internet
          Protocol ("IP") address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics.
        </Text>

        <Text style={styles.h2Style}>Cookies</Text>
        <Text style={styles.pStyle}>
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          website that you visit and are stored on your computer’s hard drive.
          Our website uses these "cookies" to collection information and to
          improve our Service. You have the option to either accept or refuse
          these cookies, and know when a cookie is being sent to your computer.
          If you choose to refuse our cookies, you may not be able to use some
          portions of our Service.
        </Text>

        <Text style={styles.h2Style}>Service Providers</Text>
        <Text style={styles.pStyle}>
          We may employ third-party companies and individuals due to the
          following reasons:
        </Text>

        <Text style={styles.pStyle}>To facilitate our Service;</Text>

        <Text style={styles.pStyle}>To provide the Service on our behalf;</Text>

        <Text style={styles.pStyle}>
          To perform Service-related services; or
        </Text>

        <Text style={styles.pStyle}>
          To assist us in analyzing how our Service is used.
        </Text>

        <Text style={styles.pStyle}>
          We want to inform our Service users that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </Text>

        <Text style={styles.h2Style}>Security</Text>
        <Text style={styles.pStyle}>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </Text>

        <Text style={styles.h2Style}>Links to Other Sites</Text>
        <Text style={styles.pStyle}>
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>

        <Text style={styles.h2Style}>Children’s Privacy</Text>
        <Text style={styles.pStyle}>
          Our Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </Text>

        <Text style={styles.h2Style}>Changes to This Privacy Policy</Text>
        <Text style={styles.pStyle}>
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, after they are posted on this
          page.
        </Text>

        <Text style={styles.h2Style}>Contact Us</Text>
        <Text style={styles.pStyle}>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </Text>
        <Text style={styles.pStyle}></Text>
      </ScrollView>
    </SafeAreaView>
  </View>
);

const styles = StyleSheet.create({
  h1Style: {
    fontSize: rhythm[3],
    fontWeight: '600',
    padding: rhythm[1],
  },
  h2Style: {
    fontSize: rhythm[2],
    fontWeight: '600',
    padding: rhythm[1],
  },
  pStyle: {
    padding: rhythm[1],
  },
  scrollView: {
    backgroundColor: colors.lightGrey,
    padding: rhythm[2],
  },
  container: {
    height: byPlatform<ViewStyle['height']>({
      default: `100%`,
      web: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    }),
  },
});
