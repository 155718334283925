import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Section } from './Section';
import { SectionHeader } from './SectionHeader';
import { ListCard } from './ListCard';
import { useNavigation } from 'react-navigation-hooks';
import { Badge } from './Badge';

export interface RenderableReviewExercise {
  exerciseId: number;
  lessonGroupTitle: string;
  lessonTitle: string;
  complete: boolean;
}
interface ReviewExercisesListProps {
  reviewExercises: RenderableReviewExercise[];
}
export const ReviewExercisesList: React.FC<ReviewExercisesListProps> = ({
  reviewExercises,
}) => {
  const { navigate } = useNavigation();

  return reviewExercises.length ? (
    <Section>
      <SectionHeader>Review Items</SectionHeader>
      {reviewExercises.map(
        ({ exerciseId, lessonTitle, lessonGroupTitle, complete }) => {
          const props = {
            title: lessonGroupTitle + ': ' + lessonTitle,
          };

          return (
            <TouchableOpacity
              key={exerciseId}
              onPress={() =>
                navigate('ExerciseScreen', {
                  exerciseId: exerciseId,
                  isReviewExerciseFlow: true,
                })
              }
            >
              <ListCard {...props}>
                <Badge
                  key={exerciseId}
                  type="required"
                  status={complete ? 'completed' : 'initial'}
                />
              </ListCard>
            </TouchableOpacity>
          );
        }
      )}
    </Section>
  ) : null;
};
