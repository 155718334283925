import { useEffect } from 'react';
import { Linking } from 'expo';

interface ParsedLink {
  path: string;
  queryParams: {};
}

export const useDeepLink = (fn: (link: ParsedLink) => void) => {
  useEffect(() => {
    Linking.parseInitialURLAsync().then(url => {
      if (!!url.path) {
        fn(url);
      }
    });

    const onUrl = ({ url }) => {
      const link = Linking.parse(url);
      return fn(link);
    };

    Linking.addEventListener('url', onUrl);

    return () => {
      Linking.removeEventListener('url', onUrl);
    };
  }, []);
};
