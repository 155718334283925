import React, { useState, useEffect } from 'react';
import { RenderMessageVideoProps } from 'react-native-gifted-chat';
import {
  View,
  StyleSheet,
  Platform,
  Image,
  TouchableOpacity,
  LayoutChangeEvent,
} from 'react-native';

import { Video } from 'expo-av';
import VideoPlayer from 'expo-video-player';
import { MaterialIcons } from '@expo/vector-icons';

import { GiftedMessageWithPoster } from './types';
import { colors, rhythm } from '../../designSystem';
import { trackError } from '../../utils/analytics';

/**
 * Mobile "Play" Icon & video bubble building blocks
 */
const mobileBubbleWidth = 300;
const mobileBubbleHeight = (300 / 16) * 9;
const controlSize = 60;

/** Exact play icon used in expo-video-player */
const PlayIcon = () => (
  <MaterialIcons name="play-arrow" size={36} color={colors.white} />
);

/**
 * Duplicates the look and feel of play icon from `expo-video-player`
 * Uses absolute positioning to float above its DOM siblings
 */
const FloatingPlayIcon = () => (
  <View
    style={{
      zIndex: 1,
      position: 'absolute',
      left: (mobileBubbleWidth - controlSize) / 2,
      top: (mobileBubbleHeight - controlSize) / 2,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      borderRadius: controlSize,
      width: controlSize,
      height: controlSize,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <PlayIcon />
  </View>
);

const VideoBubbleMobile: React.FC<RenderMessageVideoProps<
  GiftedMessageWithPoster
>> = ({ currentMessage }) => {
  const [doLoadVideo, setDoLoadVideo] = useState(false);
  if (!currentMessage || !currentMessage.video || !currentMessage.posterImage)
    return null;

  return (
    <View style={[styles.baseBubbleContainer]}>
      {doLoadVideo ? (
        <VideoPlayer
          videoProps={{
            shouldPlay: true, // true if doLoadVideo stuff
            resizeMode: Video.RESIZE_MODE_CONTAIN,
            source: { uri: currentMessage.video },
            posterSource: { uri: currentMessage.posterImage },
            usePoster: true,
            onError: err => trackError(err),
          }}
          sliderColor={colors.white}
          inFullscreen={false}
          width={mobileBubbleWidth}
          height={mobileBubbleHeight}
          showControlsOnLoad={true}
        />
      ) : (
        <TouchableOpacity onPress={() => setDoLoadVideo(true)}>
          <FloatingPlayIcon />
          <Image
            source={{ uri: currentMessage.posterImage }}
            style={{ height: mobileBubbleHeight, width: mobileBubbleWidth }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

/**
 * Web version - see below for actual export
 */
const VideoBubbleWeb: React.FC<RenderMessageVideoProps<
  GiftedMessageWithPoster
>> = ({ currentMessage }) => {
  const [loaded, setLoaded] = React.useState(false);
  const vidRef = React.useRef<Video>(null);
  const [width, setWidth] = React.useState(0);
  const [aspectRatio, setAspectRatio] = React.useState<number>(0);
  const [dynamicStyle, setDynamicStyle] = useState({ video: {} });

  /**
   * derive aspect ratio of video by digging into its ref and pulling out its height
   */
  const updateAspectRatio = () => {
    if (
      vidRef.current &&
      vidRef.current._nativeRef.current &&
      //@ts-ignore a bit "hacky", but the object exists. and if not, we won't try to use it
      vidRef.current._nativeRef.current._video
    ) {
      const {
        videoHeight,
        videoWidth,
        //@ts-ignore (see above)
      } = vidRef.current._nativeRef.current._video;
      const aspectRatio = videoWidth / videoHeight;
      setAspectRatio(aspectRatio);
    }
  };

  /**
   * update style of video to make sure it keeps aspect ratio
   */
  useEffect(() => {
    if (aspectRatio !== 0) {
      const vidWidth = width - bubbleMargin * 2;
      setDynamicStyle({
        video: {
          width: vidWidth,
          height: vidWidth / aspectRatio,
        },
      });
    }
  }, [aspectRatio, width]);

  if (!currentMessage || !currentMessage.video || !currentMessage.posterImage)
    return null;

  return (
    <View
      style={[styles.baseBubbleContainer]}
      onLayout={e => setWidth(e.nativeEvent.layout.width)}
    >
      <Video
        source={{ uri: currentMessage.video }}
        isLooping={false}
        useNativeControls={true}
        onError={e => trackError(e)}
        onLoad={() => {
          updateAspectRatio();
          setLoaded(true);
        }}
        style={dynamicStyle.video}
        ref={vidRef}
        resizeMode="contain"
        posterSource={{ uri: currentMessage.posterImage }}
        usePoster={!loaded}
      />
    </View>
  );
};

/**
 * VideoBubbleWebImageOnly:
 * Save for later - if we want to try loading poster frame
 */

/*
const VideoBubbleWebImageOnly: React.FC<RenderMessageVideoProps<
  GiftedMessageWithPoster
>> = ({ currentMessage }) => {
  const [doLoadVideo, setDoLoadVideo] = React.useState(false);
  const [posterDimensions, setPosterDimensions] = React.useState({
    width: 640,
    height: 480,
  });
  return (
    currentMessage &&
    currentMessage.video &&
    currentMessage.posterImage && (
      <View style={[styles.baseBubbleContainer, styles.webBubbleContainer]}>
        {doLoadVideo ? (
          <Video
            source={{ uri: currentMessage.video }}
            shouldPlay={true}
            isLooping={false}
            useNativeControls={true}
            onError={e => console.warn('video error', e)}
            onLoad={e => console.log('loaded video', e)}
            style={{ ...styles.webVideo }}
            resizeMode="contain"
            posterSource={{ uri: currentMessage.posterImage }}
            usePoster={true}
          />
        ) : (
          <TouchableOpacity onPress={() => setDoLoadVideo(true)}>
            <Feather
              name="play"
              size={96}
              color={colors.white}
              style={{
                zIndex: 1,
                position: 'absolute',
                alignSelf: 'center',
              }}
            />
            <Image
              source={{ uri: currentMessage.posterImage }}
              onLoad={eventData =>
                setPosterDimensions({
                  width: eventData.nativeEvent.path[0].width,
                  height: eventData.nativeEvent.path[0].width,
                })
              }
              style={{
                resizeMode: 'cover',
                height: 480,
                width: posterDimensions.width * (480 / posterDimensions.height),
              }}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  );
};
*/

/**
 * Platform-specific chat bubble with video.
 */
export const VideoBubble =
  Platform.OS === 'web' ? VideoBubbleWeb : VideoBubbleMobile;

const bubbleMargin = rhythm[0];

const styles = StyleSheet.create({
  baseBubbleContainer: {
    margin: bubbleMargin,
    padding: rhythm[0],
  },
});
