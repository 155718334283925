import React, { useEffect, useState } from 'react';
import { AsyncStorage, View, Image, ImageBackground } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';

import { AUTH_TOKEN_KEY } from '../config';
import {
  useCurrentUserQuery,
  useUpdatePracticeRemindersMutation,
  useConsumeLoginTokenMutation,
} from '../graphql/types';
import { useRegisterForPushNotifications } from '../utils/pushNotifications';
import { useMachine } from '@xstate/react';
import { authMachine } from '../machines/authMachine';
import { useDeepLink } from '../hooks/useDeepLink';
import { assign } from 'xstate';
import gql from 'graphql-tag';
import * as Sentry from 'sentry-expo';
import { colors } from '../designSystem';
import { identifyUser, trackEvent } from '../utils/analytics';
import { signOut as completeSignOut } from '../hooks/useSignOut';

export const CONSUME_LOGIN_TOKEN = gql`
  mutation ConsumeLoginToken($token: String!, $password: String) {
    consumeLoginToken(uuid: $token, password: $password) {
      token
      expired
    }
  }
`;

export const AuthLoadingScreen: React.FC = () => {
  const { navigate, getParam } = useNavigation();
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const token = getParam('token');

  const [consumeLoginToken, { client }] = useConsumeLoginTokenMutation();

  const [current, send] = useMachine(authMachine, {
    guards: {
      blankToken: (context, event) => !!event.data,
    },
    actions: {
      logError: (context, event) => {
        Sentry.captureException(event);
      },
      cacheAuthToken: assign((context, event) => ({
        authToken: event.data,
      })),
      storeAuthToken: (context, event) => {
        return AsyncStorage.setItem(AUTH_TOKEN_KEY, event.data).then(_ =>
          client.resetStore()
        );
      },
      cacheLoginToken: assign((context, event) => ({
        loginToken: event.data,
      })),
    },
    services: {
      consumeLoginToken: async (context, event) => {
        return consumeLoginToken({
          variables: {
            token: context.loginToken,
          },
        })
          .then(d => {
            const {
              data: {
                consumeLoginToken: { token: authToken },
              },
            } = d;
            return authToken;
          })
          .catch(e => {
            console.error('ERROR', e);
          });
      },
      loadAuthToken: context => {
        return AsyncStorage.getItem(AUTH_TOKEN_KEY).then(
          // TODO THIS IS NOT HOW WE WANT TO DO IT BUT
          // FOR NOW IT WORKS.
          authToken => {
            if (token) {
              send({ type: 'RECEIVE_LOGIN_TOKEN', data: token });
            }
            return authToken;
          }
        );
      },
      signOut: () => {
        trackEvent('sign-out');
        return completeSignOut();
      },
    },
    immediate: true,
  });

  useDeepLink(link => {
    // const [path, data] = link.path.split('/');
    // address issue where '#' or '--' in URL path changes offset
    const components = link.path.split('/');
    const offset = ['--', '#'].includes(components[0]) ? 1 : 0;
    const path = components[offset];
    const data = components[offset + 1];

    if (path === 'login') {
      // i don't think we need this code anymore, but will track if it ever gets reached
      trackEvent('DEPRECATE-TRACK-useDeepLink');
      send({ type: 'RECEIVE_LOGIN_TOKEN', data });
    }
  });

  const shouldLoadUser = current.matches('loadUser');

  const { data, error, loading } = useCurrentUserQuery({
    skip: !shouldLoadUser,
  });

  const [updatePracticeReminderSettings] = useUpdatePracticeRemindersMutation();
  const registerForPushNotifications = useRegisterForPushNotifications();
  useEffect(() => {
    if (data && data.user) {
      Sentry.configureScope(scope => {
        scope.setUser({
          ...data.user,
        });
      });
      registerForPushNotifications();
      send({ type: 'LOADED_DATA', user: data.user });
      identifyUser(data.user);
      if (data.user.userType === 'student') {
        navigate('StudentApp');
      } else if (data.user.userType === 'mentor') {
        navigate('MentorApp');
      }
    }
  }, [data]);

  useEffect(() => {
    if (current.matches('signIn')) {
      navigate('SignedOutApp');
    }
  }, [current]);

  return <View style={{ flex: 1, backgroundColor: colors.lightGrey }} />;
};
