import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors, rhythm, fonts, fontSize } from '../../designSystem';

interface SeekBarProps {
  position: number;
  duration: number;
}

const formatTime = (millis: number) => (millis / 1000).toFixed(0) + 's';

const makeWidth = (pct: number) => `${Math.min(100, pct * 100)}%`;

export const SeekBar: React.FC<SeekBarProps> = ({ position, duration }) => {
  const progress = position / duration;

  return (
    <View style={styles.container}>
      <View style={styles.seekOuter}>
        <View style={[styles.seekBar, { width: makeWidth(progress) }]} />
      </View>
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{formatTime(position)}</Text>
        <Text style={styles.label}>{formatTime(duration)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  seekOuter: {
    flexDirection: 'row',
    backgroundColor: `rgba(255, 255, 255, 0.2)`,
    height: rhythm[1],
  },
  seekBar: {
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    height: rhythm[1],
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: rhythm[1],
  },
  label: {
    color: colors.white,
    fontSize: fontSize[0],
    ...fonts.regular,
  },
});
