import React from 'react';
import { StyleSheet, View } from 'react-native';
import { rhythm } from '../designSystem';

export const Section = props => (
  <View {...props} style={[styles.container, props.style]} />
);

const styles = StyleSheet.create({
  container: {
    marginBottom: rhythm[3],
  },
});
