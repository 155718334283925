import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Linking } from 'expo';

import { Button } from '../components/Button';
import { trackEvent } from '../utils/analytics';
import { rhythm, colors, fontSize } from '../designSystem';

const isWednesday = () => new Date().getDay() === 3;

const isLive = () => {
  const today = new Date();
  // 0 and 2 for daylight savings, 1 and 3 for standard
  return isWednesday() && today.getUTCHours() >= 0 && today.getUTCHours() < 2;
};

export const openLivestream = () => {
  if (isLive()) {
    trackEvent('open-livestream-live');
    Linking.openURL('https://zoom.us/j/98459449256');
  } else {
    trackEvent('open-livestream-replay');
    Linking.openURL('http://vimeo.com/user/105236272/folder/1899893');
  }
};

export const LiveStreamAnnouncement: React.FC = () => {
  const dateText = isLive()
    ? 'LIVE NOW!'
    : isWednesday()
    ? 'TONIGHT @ 5:30pm PST'
    : 'WEDS @ 5:30pm PST';
  const buttonText = isWednesday() ? 'WATCH' : 'WATCH REPLAY';

  return (
    <View style={styles.livestreamBlock}>
      <View style={{ flex: 1 }}>
        <Text style={styles.liveTitle}>Live Group Class</Text>
        <Text style={styles.dateText}>{dateText}</Text>
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <Button inverted title={buttonText} onPress={openLivestream} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  liveTitle: {
    fontSize: fontSize[2],
    color: colors.accentDarken,
    marginBottom: rhythm[1],
  },
  dateText: {
    fontSize: fontSize[1],
    color: colors.accent,
  },
  livestreamBlock: {
    alignItems: 'flex-start',
    padding: rhythm[2],
    backgroundColor: colors.accentMuted,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    borderRadius: rhythm[1],
  },
});
