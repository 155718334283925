import React from 'react';
import {
  View,
  Image,
  Text,
  ImageSourcePropType,
  StyleSheet,
} from 'react-native';
import { rhythm, colors, fontSize, fonts } from '../designSystem';
import { Loading } from './Loading';

interface StudentCardProps {
  title: string;
  subtitle?: string;
  startEndString?: string;
  imageUrl?: ImageSourcePropType;
  badgeCount?: number | boolean;
  accessoryText?: string;
  unread?: boolean;
  isLoading?: boolean;
  subtitleColor?: string;
}

export const StudentCard: React.FunctionComponent<StudentCardProps> = ({
  title,
  subtitle,
  startEndString,
  imageUrl,
  badgeCount,
  accessoryText,
  unread = false,
  isLoading = false,
  subtitleColor,
}) => (
  <View style={styles.container}>
    {imageUrl && (
      <View style={styles.mediaContainer}>
        <Image source={imageUrl} style={styles.image} />
      </View>
    )}
    <View style={styles.textContainer}>
      <Text style={[styles.title, unread && styles.textUnread]}>{title}</Text>
      {!!subtitle && (
        <Text
          style={[
            styles.subtitle,
            unread && styles.textUnread,
            !!subtitleColor && { color: subtitleColor },
          ]}
        >
          {subtitle}
        </Text>
      )}
      {!!startEndString && (
        <Text
          style={{
            ...styles.subtitle,
            fontSize: fontSize[0],
            marginTop: rhythm[1],
            color: colors.accent,
          }}
        >
          {startEndString}
        </Text>
      )}
    </View>
    <View style={styles.accessoryContainer}>
      <Text style={[styles.accessoryText, unread && styles.accessoryUnread]}>
        {accessoryText}
      </Text>
      <View>
        {badgeCount && <Text style={styles.badgeText}>{badgeCount}</Text>}
        {isLoading && <Loading />}
      </View>
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    paddingVertical: rhythm[2],
    paddingHorizontal: rhythm[2],
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    // alignItems: 'center'
  },
  mediaContainer: {
    marginRight: rhythm[2],
  },
  image: {
    backgroundColor: colors.lightGrey,
    width: 60,
    height: 60,
    borderRadius: 8,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: fontSize[1],
    ...fonts.regular,
  },
  subtitle: {
    fontSize: fontSize[0],
    marginTop: rhythm[0],
    ...fonts.regular,
  },
  textUnread: {
    ...fonts.bold,
  },
  accessoryContainer: {
    // justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  accessoryText: {
    fontSize: fontSize[0],
    ...fonts.regular,
    marginBottom: rhythm[1],
  },
  accessoryUnread: {
    color: colors.accent,
  },
  badgeText: {
    fontSize: fontSize[1],
    ...fonts.regular,
    textAlign: 'center',
    backgroundColor: colors.accent,
    color: colors.white,
    lineHeight: 24,
    width: 24,
    height: 24,
    borderRadius: 2,
  },
});
