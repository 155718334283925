import React, { useState, createContext } from 'react';
export interface ChatContextState {
  currentThreadId: number;
}

export interface ChatContextFunctions {
  setCurrentThreadId: (id: number) => void;
  lastFetchedTimeForThread: (threadId: number) => Date;
  updateFetchedTimeForThread: (threadId: number, time?: Date) => void;
}

export type ChatContextShape = ChatContextState & ChatContextFunctions;

const defaultStateValue: ChatContextState = {
  currentThreadId: -1,
};

const defaultFunctions = {} as ChatContextFunctions;

const defaultValue = { ...defaultStateValue, ...defaultFunctions };

export const ChatContext = createContext<ChatContextShape>(defaultValue);

export const ChatProvider: React.FC<{}> = ({ children }) => {
  const [currentThreadId, setCurrentThreadId] = useState(-1);
  const [fetchedDates, setFetchedDates] = useState<{
    [threadId: number]: Date;
  }>({});
  const lastFetchedTimeForThread = (threadId: number): Date => {
    const lastFetched = fetchedDates[threadId];

    if (!lastFetched) {
      const initial = new Date();
      updateFetchedTimeForThread(threadId, initial);
      return initial;
    } else return lastFetched;
  };

  const updateFetchedTimeForThread = (threadId: number, time?: Date) => {
    setFetchedDates(curr => ({ ...curr, [threadId]: time || new Date() }));
  };

  return (
    <ChatContext.Provider
      value={{
        currentThreadId,
        setCurrentThreadId,
        lastFetchedTimeForThread,
        updateFetchedTimeForThread,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
