import React from 'react';
import { ScrollView } from 'react-native';
import gql from 'graphql-tag';

import { Container } from '../../components/Container';
import { Loading } from '../../components/Loading';
import { curriculum } from '../../graphql/fragments';
import { useLessonsScreenQuery } from '../../graphql/types';
import { CurriculumBrowser as S } from '../../strings';
import { ErrorMessage, missingDataError } from '../../components/ErrorMessage';
import { EnrollmentView } from '../../components/lessonBrowser/EnrollmentView';
import { NavigationScreenType } from '../../utils/navigationUtils';

export const LESSONS_SCREEN_QUERY = gql`
  query LessonsScreen {
    enrollments {
      id
      curriculum {
        id
        ...Curriculum
      }
    }
  }
  ${curriculum}
`;

export const LessonsScreen: NavigationScreenType = () => {
  const { data, loading, error } = useLessonsScreenQuery();

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.enrollments)
    return <ErrorMessage error={missingDataError('Lessons Screen')} />;

  const currentEnrollment = data.enrollments[0]; // we only support one curriculum per student for now

  return (
    <ScrollView style={{ flex: 1 }}>
      <Container>
        <EnrollmentView enrollment={currentEnrollment} />
      </Container>
    </ScrollView>
  );
};

LessonsScreen.navigationOptions = {
  title: S.navTitle,
};
