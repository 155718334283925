export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'ChatType',
        possibleTypes: [
          {
            name: 'MessageType',
          },
          {
            name: 'MessageThreadType',
          },
          {
            name: 'MessageAttachmentType',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'UserSettings',
        possibleTypes: [],
      },
      {
        kind: 'INTERFACE',
        name: 'PracticeReminderSettings',
        possibleTypes: [],
      },
    ],
  },
};

export default result;
