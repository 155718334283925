import React from 'react';
import {
  Keyboard,
  Platform,
  View,
  TouchableWithoutFeedback,
} from 'react-native';

export const DismissKeyboardView: React.FC = ({ children }) =>
  Platform.OS === 'web' ? (
    <>{children}</>
  ) : (
    <TouchableWithoutFeedback
      onPress={Keyboard.dismiss}
      accessible={false}
      style={{ flex: 1 }}
    >
      <View style={{ flex: 1 }}>{children}</View>
    </TouchableWithoutFeedback>
  );
