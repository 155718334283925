import React, { useState, useEffect } from 'react';
import {
  View,
  Dimensions,
  Platform,
  ViewStyle,
  ScaledSize,
} from 'react-native';

const baseStyle: ViewStyle = {
  flex: 1,
};

/**
 * If it's a mobile platform and in landscape mode, e.g. iPad,
 * this will detect changes in orientation and constrain the
 * proportions of the landscape view so that it's basically
 * square. Uses onLayout/class component due to limitations in
 * Expo SDK
 */
export const LandscapeConstrainer: React.FC = ({ children }) => {
  const [style, setStyle] = useState(baseStyle);

  const getLandscapeStyle = (dimensions: ScaledSize): ViewStyle => {
    // Where the magic happens
    if (Platform.OS !== 'web' && dimensions.width > dimensions.height) {
      const maxWidth = dimensions.height - 200;
      return {
        ...baseStyle,
        maxWidth: maxWidth,
        alignSelf: 'center',
      };
    } else {
      return baseStyle;
    }
  };

  const onChange = ({ screen, window }) => {
    // trackEvent('dimensions-changed', { window, screen });
    setStyle(getLandscapeStyle(window));
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  return <View style={style}>{children}</View>;
};
