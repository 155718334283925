import React from 'react';
import {
  GestureResponderEvent,
  View,
  TouchableOpacity,
  StyleSheet,
  Switch,
  Text,
} from 'react-native';
import { rhythm, fontSize, colors, fonts } from '../designSystem';

interface SimpleRowProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onPress?: (ev: GestureResponderEvent) => void;
}

export const SimpleRow: React.FC<SimpleRowProps> = ({
  title,
  subtitle,
  children,
  disabled,
  onPress,
}) => {
  const rowView = (
    <View
      style={[SimpleRowStyle.container, disabled && SimpleRowStyle.disabled]}
    >
      <View style={SimpleRowStyle.content}>
        <Text style={[SimpleRowStyle.title]}>{title}</Text>
        {!!subtitle && (
          <Text style={[SimpleRowStyle.subtitle]}>{subtitle}</Text>
        )}
      </View>
      {!!children && children}
    </View>
  );

  return typeof onPress === 'undefined' ? (
    rowView
  ) : (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      {rowView}
    </TouchableOpacity>
  );
};

const SimpleRowStyle = StyleSheet.create({
  container: {
    paddingVertical: rhythm[1],
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontSize: fontSize[1],
    color: colors.darkGrey,
    ...fonts.regular,
  },
  subtitle: {
    fontSize: fontSize[0],
    color: colors.darkGrey,
    ...fonts.regular,
  },
});

interface ToggleRowProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  value: boolean;
  onValueChange?: (value: boolean) => void;
}

export const ToggleRow: React.FC<ToggleRowProps> = ({
  title,
  subtitle,
  onValueChange,
  value,
}) => (
  <View style={[SimpleRowStyle.container]}>
    <View style={SimpleRowStyle.content}>
      <Text style={[SimpleRowStyle.title]}>{title}</Text>
      {!!subtitle && <Text style={[SimpleRowStyle.subtitle]}>{subtitle}</Text>}
    </View>
    <Switch onValueChange={onValueChange} value={value} />
  </View>
);
