import { TextStyle } from 'react-native';
export const fontSize = [13, 15, 18, 24, 36];
export const rhythm = [4, 8, 16, 24, 36, 48, 60];

export const colors = {
  white: '#ffffff',
  lightGreyTransparent: 'rgba(246,243,240,0.0)',
  lightGrey: 'rgb(246,243,240)', // #f6f3f0
  darkGrey: '#222222',
  mediumGrey: '#777777',
  accent: '#13CA7A',
  accentMuted: '#D3F3CE',
  accentDarken: '#0CA461',
  red: '#FE5454',
  redMuted: '#FFDDDC',
  defaultBlue: '#0084ff', // from https://github.com/FaridSafi/react-native-gifted-chat/blob/master/src/Color.ts
  defaultBlueMuted: '#d8ecff',
};

export const fonts = {
  regular: {
    fontFamily: 'Inter-Medium',
    fontStyle: 'normal',
  } as TextStyle,
  bold: {
    fontFamily: 'Inter-Bold',
    fontStyle: 'normal',
  } as TextStyle,
  italic: {
    fontFamily: 'Inter-Medium',
    fontStyle: 'italic',
  } as TextStyle,
};

export const headerOptions = {
  headerStyle: {
    backgroundColor: colors.accent,
    borderBottomWidth: 0,
    height: rhythm[6],
  },
  headerTitleStyle: {
    ...fonts.regular,
    fontSize: fontSize[1],
    color: colors.white,
  },
  headerTintColor: colors.white,
};

/**
 * Addresses idiosyncrasy in web, required on stack navigators to use full window size
 */
export const fullScreenCard = {
  flex: 1,
  backgroundColor: colors.lightGrey,
};
