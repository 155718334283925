import { Machine, assign, sendParent } from 'xstate';
import { Video } from 'expo-av';
import { AVPlaybackStatus as PlaybackStatus } from 'expo-av/build/AV';

export interface ReviewContext {
  video: Video | null;
  videoUri: string;
  duration: number;
  elapsed: number;
  // uploadProgress: {
  //   value: number;
  //   max: number;
  // };
}

export interface ReviewStateSchema {
  states: {
    playback: {
      states: {
        loading: {};
        ready: {
          states: {
            playing: {};
            paused: {};
          };
        };
        failure: {};
      };
    };
    upload: {
      states: {
        idle: {};
        pending: {};
        progress: {};
        success: {};
        failure: {};
      };
    };
  };
}

export type ReviewEvent =
  | { type: 'DISMISS' }
  | { type: 'LOADED'; video: Video; status: PlaybackStatus }
  | { type: 'FAIL'; error: string }
  | { type: 'PAUSE' }
  | { type: 'TIMING'; status: PlaybackStatus }
  | { type: 'PLAY' }
  | { type: 'INIT_UPLOAD' }
  | { type: 'UPLOAD_COMPLETE' }
  | { type: 'UPLOAD_FAIL'; error: Error };
// | { type: 'UPLOAD_PROGRESS'; value: number; max: number };

export const createReviewMachine = (videoUri: string) =>
  Machine<ReviewContext, ReviewStateSchema, ReviewEvent>({
    id: 'review',
    type: 'parallel',
    context: {
      video: null,
      videoUri,
      duration: 0,
      elapsed: 0,
      // uploadProgress: {
      //   value: 0,
      //   max: 0,
      // },
    },
    states: {
      playback: {
        initial: 'loading',
        states: {
          loading: {
            on: {
              LOADED: {
                target: 'ready',
                actions: ['setVideo'],
              },
              FAIL: 'failure',
            },
          },
          ready: {
            initial: 'playing',
            states: {
              playing: {
                on: {
                  PAUSE: {
                    target: 'paused',
                    actions: ['pause'],
                  },
                  TIMING: {
                    target: 'playing',
                    actions: ['setElapsed'],
                  },
                },
              },
              paused: {
                on: {
                  PLAY: {
                    target: 'playing',
                    actions: ['play'],
                  },
                },
              },
            },
          },
          failure: {
            type: 'final',
          },
        },
      },
      upload: {
        initial: 'idle',
        onDone: {
          actions: 'uploadComplete',
        },
        states: {
          idle: {
            on: {
              INIT_UPLOAD: {
                target: 'pending',
              },
            },
          },
          pending: {
            invoke: {
              src: 'uploadVideo',
            },
            on: {
              UPLOAD_COMPLETE: 'success',
              UPLOAD_FAIL: 'failure',
              // UPLOAD_PROGRESS: {
              //   target: 'progress',
              //   actions: ['setUploadProgress'],
              // },
            },
          },
          progress: {
            on: {
              UPLOAD_COMPLETE: 'success',
              UPLOAD_FAIL: 'failure',
              // UPLOAD_PROGRESS: {
              //   target: 'progress',
              //   actions: ['setUploadProgress'],
              // },
            },
          },
          success: {
            type: 'final',
          },
          failure: {
            invoke: {
              src: 'uploadFailure',
            },
            on: {
              INIT_UPLOAD: {
                target: 'pending',
              },
            },
          },
        },
      },
    },
    on: {
      DISMISS: {
        actions: 'dismiss',
      },
    },
  });
