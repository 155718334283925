import React, { useRef, useState, useEffect } from 'react';
import { Platform, View, Dimensions, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';
import { Button } from '../Button';
import { colors, rhythm } from '../../designSystem';
import { onLoad } from '@sentry/browser';
import { Loading } from '../Loading';
const MetronomePng = require('../../../assets/metronome-white.png');
interface MetronomeButtonProps {
  onPress: () => void;
  disabled?: boolean;
}
export const MetronomeButton: React.FC<MetronomeButtonProps> = ({
  onPress,
  disabled = false,
}) => {
  const Icon = () =>
    Platform.OS === 'web' ? (
      <MaterialCommunityIcons name="metronome" color={colors.white} size={24} />
    ) : (
      <Image
        source={MetronomePng}
        resizeMode={'contain'}
        resizeMethod={'scale'}
        style={{ width: 30, height: 30 }}
      />
    );
  return (
    <>
      <Button
        onPress={onPress}
        style={{
          flex: 1,
          maxWidth: 50,
          marginRight: rhythm[2],
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disabled={disabled}
        title={''}
        analyticTitle={'metronome'}
      >
        <Icon />
      </Button>
    </>
  );
};

const WebView = Platform.OS === 'web' ? WebViewWeb : WebViewMobile;

interface MetronomeProps {
  doShow: boolean;
  bottomOffset?: number; //pixels to offset from bottom of view
  leftOffset?: number; //pixels to offset from left of view
}

/**
 * A component that embeds a webview metronome,
 * managing its sizes, and providing an option to close it
 */
export const Metronome: React.FC<MetronomeProps> = ({
  doShow,
  bottomOffset = 0,
  leftOffset = 0,
}) => {
  const [isLoading, setLoading] = useState(true);

  const height = doShow ? 300 : 0;
  const width = doShow
    ? Platform.OS === 'web' || Dimensions.get('screen').width > 600
      ? 380
      : 'auto'
    : 0;

  return (
    <View
      style={{
        display: doShow ? 'flex' : 'none',
        height,
        width,
        position: 'absolute',
        zIndex: 69, //bring to front
        ...(Platform.OS !== 'web' && { right: rhythm[2] }),
        bottom: bottomOffset,
        left: leftOffset,
      }}
    >
      <WebView
        source={{ uri: 'https://goat-metronome.herokuapp.com/' }}
        onLoad={() => setLoading(false)}
        style={[
          isLoading && { width: 0, height: 0 },
          { borderRadius: rhythm[2] },
        ]}
      />
      {isLoading && doShow && <Loading text="Loading Metronome" />}
    </View>
  );
};
