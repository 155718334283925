import React from 'react';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import { colors, fonts, fontSize } from '../designSystem';

interface LoadingProps {
  text?: string;
  customBackgroundColor?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  text = '',
  customBackgroundColor,
}) => {
  const backgroundColor = customBackgroundColor || colors.lightGrey;
  return (
    <View style={[styles.container, { backgroundColor }]}>
      <ActivityIndicator />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colors.lightGrey,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ...fonts.regular,
    fontSize: fontSize[0],
    color: colors.darkGrey,
  },
});
