import { Platform } from 'react-native';
import { OrientationLock, lockAsync } from 'expo-screen-orientation';
import { useScreenOrientationLock } from '@use-expo/screen-orientation';
import { trackEvent, trackError } from '../utils/analytics';
import { useEffect } from 'react';
import { useNavigation } from 'react-navigation-hooks';

const enableLandscape = Platform.OS === 'ios' && Platform.isPad;

// temp - only track these once per session to avoid annoyance
let trackedLandscapeEnable = false;
let trackedPortaitLocked = false;

let isLandscape;

interface PortraitLockArgs {
  debugId?: string;
  onlyOnMount?: boolean;
  onlyWhenFocused?: boolean;
  isFocused?: boolean;
}
/**
 * Locks the screen/view in portrait except for iPads :)
 * @param id an identifier for the screen calling (for debugging purposes only)
 * @param onlyOnMount run the effect only once (on component mount), instead of on each render
 */
export const usePortraitLockExceptIpad = ({
  debugId = '',
  onlyOnMount = false,
  onlyWhenFocused = true,
  isFocused = false,
}: PortraitLockArgs) => {
  if (Platform.OS === 'web') return;
  // okay to have this hook below return statement
  // since platform will never change for given instance
  if (!enableLandscape) {
    const dependencies = onlyOnMount
      ? []
      : onlyWhenFocused
      ? [isFocused]
      : undefined;
    return useEffect(() => {
      // console.log('locking portrait', debugId);
      if ((onlyWhenFocused && isFocused) || onlyOnMount)
        asyncLockPortraitExceptIpad();
    }, dependencies);
  }
};

export const asyncLockPortraitExceptIpad = () => {
  if (Platform.OS === 'web') return;
  if (!enableLandscape) {
    // console.log('locking portrait up');
    return lockAsync(OrientationLock.PORTRAIT_UP).catch(err =>
      trackError(`LOCK ISSUE ${err}`)
    );
  }
};

export const asyncLockLandscapeExceptIpad = () => {
  if (Platform.OS === 'web') return;

  if (!enableLandscape) lockAsync(OrientationLock.LANDSCAPE_RIGHT);
};
