import {
  useReportProgressMutation,
  MutationReportProgressArgs,
} from '../graphql/types';
import { trackEvent, trackError } from '../utils/analytics';
import { ApolloError } from 'apollo-client';

type ReportProgressMutationFunction = ({
  rating,
  exerciseId,
  segmentId,
  startedAt,
  endedAt,
}: MutationReportProgressArgs) => Promise<any>;

type ReportProgressMutationHook = () => {
  reportProgress: ReportProgressMutationFunction;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useReportProgress: ReportProgressMutationHook = () => {
  const [
    reportProgressMutation,
    { loading, error },
  ] = useReportProgressMutation();

  const reportProgress = (variables: MutationReportProgressArgs) => {
    return new Promise((resolve, reject) => {
      const prereportTime = new Date().getTime();
      reportProgressMutation({
        refetchQueries: [
          'LastSegment',
          'PracticeScreenExercises',
          'LessonsScreen',
        ],
        variables,
      })
        .then(() => {
          const executionTime = new Date().getTime() - prereportTime;
          trackEvent('submit-rating', { variables, executionTime });
          resolve();
        })
        .catch(err => {
          trackError(err);
          reject(err);
        });
    });
  };

  return {
    reportProgress,
    loading,
    error,
  };
};
