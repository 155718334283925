import uniq from 'ramda/src/uniq';
import { RenderableReviewExercise } from './components/ReviewExercisesList';
import { isNum, numsFromQueryArray } from './utils/globalTypes';
import {
  LastSegmentQuery,
  LessonGroupVideoFragment,
  Maybe,
  PracticeScreenExerciseFragment,
  LastSegmentExercisesFragment,
  ExerciseType,
} from './graphql/types';

export const exercisesForSegment = (data: LastSegmentQuery): number[] => {
  if (data && data.lastSegment) {
    const lastSegment = data.lastSegment;
    const allExercises = uniq([
      ...(lastSegment.reviewExerciseIds || []),
      ...(lastSegment.newExerciseIds || []),
    ]);

    return allExercises.filter(isNum);
  }
  return [];
};

const exerciseProps = (
  e: Maybe<Pick<ExerciseType, 'id' | 'lastProgress' | 'optional'>>
) => ({
  id: (e && parseInt(e.id)) || -1,
  lastProgress: {
    rating: (e && e.lastProgress && e.lastProgress.rating) || 0,
  },
  optional: e && e.optional,
});

// TODO IT WOULD BE AMAZING TO WRITE SOME TESTS FOR THIS
export const getLessonsFromSegment = (
  segment: LastSegmentExercisesFragment,
  exercises: PracticeScreenExerciseFragment[]
): [TechniqueListItem[], RenderableReviewExercise[]] => {
  const newExerciseIds = numsFromQueryArray(segment.newExerciseIds);
  const reviewExerciseIds = numsFromQueryArray(segment.reviewExerciseIds);
  const completedExerciseIds = numsFromQueryArray(segment.completedExerciseIds);

  // Create review exercise list
  const reviewExercises: RenderableReviewExercise[] = reviewExerciseIds.map(
    exerciseId => {
      // Find the exercise information from practice screen fragment
      const info = exercises.find(e => parseInt(e.id) === exerciseId);

      if (!info || !info.lesson || !info.lesson.lessonGroup)
        throw new Error('expected review exercise in segment exercise info');

      return {
        exerciseId,
        lessonTitle: info.lesson.title,
        lessonGroupTitle: info.lesson.lessonGroup.title,
        complete: completedExerciseIds.includes(exerciseId),
      };
    }
  );

  // Create new techniques list
  const segmentLessons: TechniqueListItem[] = newExerciseIds.reduce(
    (acc, exerciseId) => {
      // Find the exercise information from practice screen fragment
      const info = exercises.find(e => parseInt(e.id) === exerciseId);

      if (!info || !info.lesson)
        throw new Error('need lesson info for segmentLessons');
      if (!info.lesson.lessonGroup || !info.lesson.lessonGroup.lessons)
        throw new Error('need lesson group');

      const exerciseLessonId = info.lesson.id;
      const firstLessonInGroup = info.lesson.lessonGroup.lessons[0];

      // Find out if this lesson is the first in its lesson group
      // If so, add the lesson intro
      if (firstLessonInGroup && firstLessonInGroup.id === exerciseLessonId) {
        acc.push({
          type: 'lessonIntro',
          moduleTitle: info.lesson.lessonGroup.curriculumModule.title,
          lessonGroupTitle: info.lesson.lessonGroup.title,
          videos: info.lesson.lessonGroup.lessonGroupVideos, //TODO: fix types
        });
      }

      const lessonExercises = info.lesson.exercises || [];
      // Add the lesson itself
      acc.push({
        type: 'lesson',
        lesson: {
          id: exerciseLessonId,
          title: info.lesson.title,
          s3ThumbnailUrl: info.lesson.s3ThumbnailUrl || '',
          exercises: lessonExercises.map(exerciseProps),
        },
        moduleTitle: info.lesson.lessonGroup.curriculumModule.title,
        lessonGroupTitle: info.lesson.lessonGroup.title,
      });
      return acc;
    },
    [] as TechniqueListItem[]
  );

  return [segmentLessons, reviewExercises];
};

export interface RenderableLesson {
  type: 'lesson';
  lesson: {
    id: number | string;
    title: string;
    s3ThumbnailUrl: string;
    exercises: {
      id: number;
      optional: boolean;
      lastProgress: {
        rating: number;
      };
    }[];
  };
  moduleTitle: string;
  lessonGroupTitle: string;
}

interface RenderableLessonIntro {
  type: 'lessonIntro';
  moduleTitle: string;
  lessonGroupTitle: string;
  videos: LessonGroupVideoFragment[];
}

export type TechniqueListItem = RenderableLesson | RenderableLessonIntro;

export interface SegmentStat {
  remaining: number;
  completed: number;
  total: number;
}

export interface SegmentStats {
  completedPercent: number;
  reviewExercises: SegmentStat;
  techniques: SegmentStat;
}
