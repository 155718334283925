import React from 'react';
import { StyleSheet, Image, Text, View } from 'react-native';
import { colors, rhythm, fontSize, fonts } from '../designSystem';

export interface ListCardProps {
  title: string;
  subtitle?: string;
  header?: string;
  children?: React.ReactNode;
  inverse?: Boolean;
  disabled?: Boolean;
  image?: string;
}

export const ListCard: React.FC<ListCardProps> = ({
  title,
  subtitle,
  header,
  children,
  inverse,
  disabled,
  image,
}) => {
  const textColor = !!inverse ? ListCardStyle.textInverse : ListCardStyle.text;

  return (
    <View
      style={[
        ListCardStyle.container,
        !!inverse && ListCardStyle.containerInverse,
        !!disabled && ListCardStyle.containerDisabled,
      ]}
    >
      {!!image && (
        <View>
          <Image source={{ uri: image }} style={ListCardStyle.image} />
        </View>
      )}
      <View style={ListCardStyle.content}>
        {!!header && (
          <Text style={[ListCardStyle.header, textColor]}>{header}</Text>
        )}
        <Text style={[ListCardStyle.title, textColor]}>{title}</Text>
        {!!subtitle && (
          <Text style={[ListCardStyle.subtitle, textColor]}>{subtitle}</Text>
        )}
      </View>
      {!!children && <View style={ListCardStyle.children}>{children}</View>}
    </View>
  );
};

const cardImageHeight = rhythm[5] * 2;
const ListCardStyle = StyleSheet.create({
  container: {
    paddingHorizontal: rhythm[2],
    paddingVertical: rhythm[2],
    borderRadius: rhythm[1],
    marginBottom: rhythm[2],
    backgroundColor: colors.accentMuted,
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerInverse: {
    backgroundColor: colors.accent,
  },
  containerDisabled: {
    opacity: 0.5,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    borderRadius: rhythm[0],
    backgroundColor: colors.accent,
    width: cardImageHeight,
    height: (cardImageHeight * 9) / 16,
    marginRight: rhythm[2],
  },
  title: {
    fontSize: fontSize[1],
    ...fonts.bold,
  },
  subtitle: {
    fontSize: fontSize[1],
    marginTop: rhythm[1],
    ...fonts.regular,
  },
  header: {
    fontSize: fontSize[0],
    // textTransform: 'uppercase',
    ...fonts.bold,
    marginBottom: rhythm[1],
  },
  children: {
    marginLeft: rhythm[2],
    flexDirection: 'row',
  },
  text: {
    color: colors.accentDarken,
  },
  textInverse: {
    color: colors.white,
  },
});
