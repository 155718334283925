import React from 'react';
import { View } from 'react-native';
import splitEvery from 'ramda/src/splitEvery';
import flatten from 'ramda/src/flatten';

import { LessonType, Maybe } from '../../graphql/types';
import { Badge, BadgeProps } from '../../components/Badge';

const ratingToStatus = (rating: number): BadgeProps['status'] => {
  if (rating >= 1 && rating <= 4) {
    return 'inProgress';
  }

  if (rating === 5) {
    return 'completed';
  }

  return 'initial';
};

const exerciseToBadgeProps = (rating: number): BadgeProps => {
  const status = ratingToStatus(rating || 0);

  return {
    status,
    type: 'required',
  };
};

/**
 *
 * @param lessons: array of lessons, containing:
 * {
 *  exercises {
 *    id: number
 *    lastProgress: { rating: number | undefined } | undefined
 *    optional: boolean
 *  }
 * }
 */
export const ExerciseCompletionBadges: React.FC<{
  lessons: Maybe<Pick<LessonType, 'exercises'>>[];
}> = ({ lessons }) => {
  if (!lessons) return null;
  // all we need is the lesson exercises w/ id & last rating & optional status
  const allExercises = flatten(lessons.map(lesson => lesson?.exercises));
  const requiredExercises = allExercises.filter(e => e && !e.optional);
  const badges = requiredExercises.map(
    exercise =>
      exercise && (
        <Badge
          key={exercise.id}
          {...exerciseToBadgeProps(
            (exercise.lastProgress && exercise.lastProgress.rating) || 0
          )}
        />
      )
  );

  const badgeRows = splitEvery(5, badges).map((badgeRow, i) => (
    <View key={`badge-${i}`} style={{ flexDirection: 'row' }}>
      {badgeRow}
    </View>
  ));
  return <View>{badgeRows}</View>;
};
