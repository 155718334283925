import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import gql from 'graphql-tag';

import {
  DEFAULT_PRACTICE_SESSION_DURATION_INDEX,
  PRACTICE_SESSION_DURATIONS,
} from '../../config';
import { Button, TextButton } from '../../components/Button';
import { colors, rhythm, fontSize } from '../../designSystem';
import { Picker } from '../../components/Picker';
import { useCreateNextSegmentMutation } from '../../graphql/types';
import { SessionCreatorScreenStrings as S } from '../../strings';
import { trackEvent } from '../../utils/analytics';

export const CREATE_NEXT_SEGMENT = gql`
  mutation CreateNextSegment($duration: Int!) {
    createNextSegment(duration: $duration) {
      segment {
        id
        newExerciseIds
        completedExerciseIds
        reviewExerciseIds
      }
    }
  }
`;

export const SessionCreatorScreen: React.FC = _ => {
  const { navigate, goBack } = useNavigation();
  const [duration, setDuration] = useState(
    PRACTICE_SESSION_DURATIONS[DEFAULT_PRACTICE_SESSION_DURATION_INDEX]
  );

  const [createNextSegment, { loading }] = useCreateNextSegmentMutation({
    refetchQueries: ['LastSegment', 'StudentHomeScreen'],
    awaitRefetchQueries: true,
  });

  const handleSelectItem = (itemIndex: number) =>
    setDuration(PRACTICE_SESSION_DURATIONS[itemIndex]);

  const onPressStart = _ => {
    createNextSegment({
      variables: { duration },
    }).then(res => {
      trackEvent('started-new-session', {
        duration,
        data: res.data,
        error: res.errors,
      });
      navigate('PracticeSessionScreen');
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentContainer}>
        <View
          style={{
            marginBottom: rhythm[4],
            paddingHorizontal: rhythm[2],
          }}
        >
          <Text style={styles.heroText}>{S.hero}</Text>
          <Text style={styles.subtitleText}>{S.subtitle}</Text>
        </View>
        <View style={{ marginBottom: rhythm[4] }}>
          <Picker
            options={PRACTICE_SESSION_DURATIONS}
            initialItemIndex={DEFAULT_PRACTICE_SESSION_DURATION_INDEX}
            onSelectItem={handleSelectItem}
          />
        </View>
        <View style={{ paddingHorizontal: rhythm[2] }}>
          <Button
            title={S.startButton}
            style={styles.button}
            disabled={loading}
            textStyle={styles.buttonText}
            onPress={onPressStart}
          />
        </View>
      </View>
      <View style={styles.cancelContainer}>
        <TextButton
          color={colors.white}
          title={S.cancelButton}
          onPress={() => goBack(null)}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  heroText: {
    fontSize: fontSize[4],
    color: colors.white,
    marginBottom: rhythm[2],
  },
  subtitleText: {
    fontSize: fontSize[1],
    color: colors.white,
    marginBottom: rhythm[2],
  },
  container: {
    backgroundColor: colors.accent,
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: rhythm[4],
  },
  contentContainer: {
    flex: 1,
  },
  cancelContainer: {
    flex: 0,
    marginVertical: rhythm[2],
  },
  button: {
    height: rhythm[4] * 2,
    backgroundColor: colors.white,
  },
  buttonText: {
    color: colors.accent,
  },
  picker: {
    width: '100%',
  },
});
