import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import { Linking } from 'expo';

export const isWebProd = () =>
  window &&
  window.location &&
  window.location.host.includes('guitarmasteryintensive.com');

export const isMobileProd = () =>
  Constants.manifest &&
  Constants.manifest.releaseChannel &&
  Constants.manifest.releaseChannel.indexOf('gmi') !== -1;

export const isProduction = () => isMobileProd() || isWebProd();
export const isDevelopment = () => !isProduction();

export function graphQLUrl() {
  const apiUrl = isMobileProd()
    ? 'https://api.guitarmasteryintensive.com/graphql'
    : isWebProd()
    ? '/graphql'
    : 'https://dev.guitarmasteryintensive.com/graphql';

  return apiUrl;
}

export function amplitudeApiKey() {
  return isDevelopment()
    ? '99fd5a7f38098600a7c726b2f65b411d'
    : '2fa0caaf0be27dcb7927377b14df8f8e';
}

export function ziggeoApiKey() {
  return isDevelopment()
    ? '99fd5a7f38098600a7c726b2f65b411d'
    : '2fa0caaf0be27dcb7927377b14df8f8e';
}

export function initializeSentry() {
  const sentryDsn = isDevelopment()
    ? 'https://af7de85cfb34438892beb9e34143c539@sentry.io/3483373'
    : 'https://35a6a1e705d34062a4a0ebedaf5d4dff@sentry.io/1826066';
  if (Platform.OS === 'web') {
    Sentry.init({
      dsn: sentryDsn,
      release: Constants.manifest.revisionId,
    });
    console.log(`GMI v${Constants.manifest.version}`);
  } else {
    Sentry.init({
      dsn: sentryDsn,
      enableInExpoDevelopment: true,
    });
    Sentry.setRelease(Constants.manifest.revisionId || 'noRevisionId');
  }
}

export const isLoginPath: () => boolean = () =>
  Platform.OS === 'web' &&
  window &&
  window.location &&
  window.location.pathname.substring(0, 6) === '/login';

export const didLaunchMobileApp: () => boolean = () => {
  if (!window || !window.location || !window.location.pathname || !navigator) {
    return false;
  }

  Sentry.captureMessage('iPad Detection Code on Web');
  const token = window.location.pathname.split('/')[2];
  const browserPlatform = navigator.platform.toLowerCase();

  let canLaunchMobileApp =
    /ipad|iphone|ipod/.test(browserPlatform) ||
    /android/.test(browserPlatform) ||
    (browserPlatform === 'macintel' && navigator.maxTouchPoints > 1);

  if (canLaunchMobileApp) {
    const isProd = window.location.host.includes('guitarmasteryintensive.com');
    const isStaging =
      window.location.host === 'guitar-web-staging.herokuapp.com';

    const prodLink = 'guitar-mastery://login/';
    const stagingLink =
      'exp://exp.host/@guitarmasteryintensive/guitar-mastery-intensive/--/login/';
    const localLink = `exp://localhost:19000/--/login/`;

    const loginLink = isProd ? prodLink : isStaging ? stagingLink : localLink;

    const redirectWithToken = loginLink + token;
    Linking.openURL(redirectWithToken);
    return true;
  }
  return false;
};
