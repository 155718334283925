import {
  Maybe,
  MessageAttachmentType,
  MentorIndexStudentMentorRelationshipFragment,
  MessageWithAttachmentsFragment,
  CurrentThreadInfoFragment,
} from '../graphql/types';

import stringHash from 'string-hash';
import { ChatThreadType, ChatAttachmentType } from './chat';

const boolToNum = (bool: boolean) => (bool ? 1 : 0);

function hashAttachments(
  attachments: (
    | Maybe<Pick<MessageAttachmentType, 'complete'> | undefined>
    | ChatAttachmentType
  )[]
) {
  return (
    attachments &&
    attachments.reduce(
      (acc, curr, i) => acc + i + boolToNum(!!curr && curr.complete),
      ''
    )
  );
}
/**
 * Returns a single string representing state of a Messages array
 * Deep equal arrays will return same string.
 * Purpose is to detect changes in message count, contents, read status,
 * attachments, etc.
 */
export const hashMessages = (
  messages: Maybe<MessageWithAttachmentsFragment>[]
) =>
  messages &&
  messages.reduce(
    (acc, currMessage, index) =>
      acc +
      index +
      stringHash(currMessage!.contents) +
      boolToNum(currMessage!.read) +
      boolToNum(currMessage!.complete) +
      hashAttachments(currMessage!.attachments || []),
    ''
  );

/**
 * This returns a string reduction of message thread data,
 * for memoization. The hash will stay the same if and only if
 * the messages, message contents, and message read status
 * stay the same.
 * @param thread Maybe<MessageThreadType> - turn it into a string
 */
export const hashMessageThread = (
  thread: Maybe<Pick<CurrentThreadInfoFragment, 'messages'>>
) => thread && thread.messages && hashMessages(thread.messages);

export const hashChatThread = (thread: ChatThreadType) =>
  thread.id +
  thread.messages.reduce(
    (acc, curr, i) =>
      acc +
      i +
      stringHash(curr.contents) +
      boolToNum(curr.read) +
      boolToNum(curr.sent) +
      hashAttachments([curr.attachment]),
    ''
  );

export const hashStringArray = (arr: string[]) =>
  arr.reduce((acc, curr, index) => acc + index + stringHash(curr), '');

export const hashMentorRelationships = (
  data: Maybe<MentorIndexStudentMentorRelationshipFragment>[]
) => {
  if (!data) return '';
  data.reduce(
    (acc, curr, i) =>
      acc +
      (curr
        ? i +
          curr.id +
          curr.startedAt +
          curr.endedAt +
          curr.unreadCount +
          (curr.messageThread
            ? curr.messageThread.lastMessage + curr.messageThread.lastActivity
            : '')
        : ''),
    ''
  );
};
