import React, { useState } from 'react';
import {
  Text,
  TextInput,
  View,
  SafeAreaView,
  KeyboardAvoidingView,
  StatusBar,
  Platform,
  AsyncStorage,
} from 'react-native';
import gql from 'graphql-tag';

import { Button, TextButton } from '../../components/Button';
import { rhythm } from '../../designSystem';
import {
  useGenerateLoginEmailMutation,
  useGeneratePasswordEmailMutation,
  useSignInUserMutation,
} from '../../graphql/types';
import { isEmail } from '../../utils/validators';
import * as Sentry from 'sentry-expo';
import { styles } from './styles';
import { useInputValidation, Guard } from '../../hooks/useInputValidation';
import { useNavigation } from 'react-navigation-hooks';
import { trackError } from '../../utils/analytics';
import { AUTH_TOKEN_KEY } from '../../config';
import { SignInStrings as S } from '../../strings';
import { DismissKeyboardView } from '../../components/DismissKeyboardView';

export const SIGN_IN_USER = gql`
  mutation SignInUser($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

export const GENERATE_LOGIN_EMAIL = gql`
  mutation GenerateLoginEmail($email: String!) {
    generateLoginEmail(email: $email) {
      ok
    }
  }
`;

export const GENERATE_PASSWORD_EMAIL = gql`
  mutation GeneratePasswordEmail($email: String!) {
    generatePasswordEmail(email: $email) {
      ok
    }
  }
`;

interface TextInputMessageAreaProps {
  message: string | null;
  isError: boolean;
}
const TextInputMessageArea: React.FC<TextInputMessageAreaProps> = ({
  message,
  isError,
}) => {
  const messageStyle = !!message
    ? isError
      ? styles.errorText
      : styles.messageText
    : {};

  return <Text style={[styles.inputMessageArea, messageStyle]}>{message}</Text>;
};

export const SignInScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const [emailMessage, emailSetMessage] = useState<string | null>(null);

  const [
    generatePasswordEmail,
    { loading: generatePasswordEmailLoading },
  ] = useGeneratePasswordEmailMutation();

  const isNoEmail: Guard<string | null> = email =>
    !email ? 'Please enter your email address' : null;

  const isBadEmailFormat: Guard<string | null> = email =>
    email && email.length > 0 && !isEmail(email)
      ? 'Please enter a valid email address'
      : null;

  // only show password error if email is valid
  const isFairPassword: Guard<string | null> = password =>
    (password && password.length > 0) || emailError ? null : 'Enter a password';

  const {
    value: emailValue,
    error: emailError,
    setError: emailSetError,
    onChange: emailOnChange,
    onFocus: emailOnFocus,
    onBlur: emailOnBlur,
    disabled: emailDisabled,
  } = useInputValidation([isNoEmail, isBadEmailFormat]);

  const {
    value: passwordValue,
    error: passwordError,
    setError: passwordSetError,
    onChange: passwordOnChange,
    onFocus: passwordOnFocus,
    onBlur: passwordOnBlur,
    disabled: passwordDisabled,
  } = useInputValidation([isFairPassword]);

  const [
    generateLoginEmail,
    { loading: generateLoginEmailLoading, error: generateLoginEmailError },
  ] = useGenerateLoginEmailMutation();
  const [
    signInUser,
    { loading: signInLoading, client },
  ] = useSignInUserMutation();

  if (!client) throw new Error('need graphQL client online');

  const handlePasswordChange = (text: string) => {
    passwordSetError('');
    passwordOnChange(text);
  };

  const handleEmailChange = (text: string) => {
    emailSetError('');
    emailSetMessage('');
    emailOnChange(text.trim());
  };

  const resetPassword = () =>
    emailValue &&
    !emailError &&
    generatePasswordEmail({ variables: { email: emailValue } })
      .then(() => {
        emailSetMessage(`Password reset instructions sent to ${emailValue}`);
        emailSetError('');
        passwordSetError('');
      })
      .catch(err => trackError(err));

  const sendMagicLink = () => {
    if (!emailValue)
      throw new Error('should not be able to send without email');
    if (!!emailError) return;
    generateLoginEmail({
      variables: { email: emailValue },
    })
      .then(() => navigate('MagicLinkPending', { email: emailValue }))
      .catch(err => Sentry.captureException(err));
  };

  const handleSignIn = () => {
    if (!emailValue || !passwordValue)
      throw new Error(
        'should not be able to submit with falsey email or password'
      );

    signInUser({
      variables: {
        email: emailValue,
        password: passwordValue,
      },
    })
      .then(
        ({
          data: {
            tokenAuth: { token },
          },
        }) => {
          return AsyncStorage.setItem(AUTH_TOKEN_KEY, token)
            .then(_ => client.resetStore())
            .then(_ => navigate('AuthLoading'));
        }
      )
      .catch(x => {
        passwordSetError(S.loginIncorrectError);
        emailSetMessage('');
      });
  };

  const signInDisabled =
    emailDisabled ||
    passwordDisabled ||
    signInLoading ||
    generateLoginEmailLoading ||
    generatePasswordEmailLoading;

  const forgotPasswordDisabled = emailDisabled || generatePasswordEmailLoading;
  const magicLinkDisabled = emailDisabled || generateLoginEmailLoading;

  const buttonTitle = (): string => (signInLoading ? S.pleaseWait : S.signIn);

  return (
    <DismissKeyboardView>
      <SafeAreaView style={styles.container}>
        <View style={styles.innerContainer}>
          <StatusBar barStyle="dark-content" />
          <KeyboardAvoidingView
            behavior="height"
            style={{
              position: 'relative',
              flex: 1,
              padding: rhythm[3],
            }}
          >
            <Text style={styles.heroText}>{S.welcomeTo}</Text>
            <Text style={[styles.heroText, styles.heroTextLastLine]}>
              {S.GuitarMasteryIntensive}
            </Text>

            <Text style={styles.label}>{S.emailLabel}</Text>
            <TextInput
              style={[
                styles.input,
                !!emailError && styles.inputError,
                !!emailMessage && styles.inputMessage,
              ]}
              onChangeText={handleEmailChange}
              onFocus={emailOnFocus}
              onBlur={emailOnBlur}
              textContentType="emailAddress"
              {...(Platform.OS !== 'web' && { autoCompleteType: 'email' })}
              autoCapitalize="none"
              placeholder={S.placeHolderEmail}
              value={emailValue || ''}
            />
            <TextInputMessageArea
              isError={!!emailError}
              message={emailError || emailMessage}
            />

            <Text style={styles.label}>{S.passwordLabel}</Text>
            <TextInput
              style={[styles.input, !!passwordError && styles.inputError]}
              onChangeText={handlePasswordChange}
              onBlur={passwordOnBlur}
              onFocus={passwordOnFocus}
              textContentType="password"
              {...(Platform.OS === 'android' && {
                autoCompleteType: 'password',
              })}
              autoCapitalize="none"
              secureTextEntry={true}
              value={passwordValue || ''}
            />
            <TextInputMessageArea
              isError={!!passwordError}
              message={passwordError}
            />

            <Button
              title={buttonTitle()}
              onPress={handleSignIn}
              disabled={signInDisabled}
              style={{ marginBottom: rhythm[2] }}
            />
            <TextButton
              title={S.forgotPasswordButton}
              disabled={forgotPasswordDisabled}
              onPress={resetPassword}
            />
            <TextButton
              title={S.sendMagicLinkButton}
              disabled={magicLinkDisabled}
              onPress={sendMagicLink}
            />
          </KeyboardAvoidingView>
        </View>
      </SafeAreaView>
    </DismissKeyboardView>
  );
};
