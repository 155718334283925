import React from 'react';
import { ScrollView, View, Text } from 'react-native';
import { Chip, chipWidth, chipPadding, ChipProps } from './Chip';
import { rhythm } from '../../designSystem';
import { CarouselActions } from './VideoCarouselActions';
import { ChatScreenStrings as S, ChatScreenStrings } from '../../strings';

const NoSubmissionsMessage: React.FC<{ text: string }> = ({ text }) => (
  <View
    style={{
      flex: 1,
      borderRadius: rhythm[1],
      borderColor: 'black',
      borderWidth: 2,
      borderStyle: 'dashed',
      alignItems: 'center',
      justifyContent: 'center',
      padding: chipPadding,
      margin: chipPadding,
      opacity: 0.5,
    }}
  >
    <Text style={{ alignSelf: 'center' }}>{text}</Text>
  </View>
);

interface VideoCarouselProps {
  data: ChipProps[];
  isAddThreadEnabled: boolean;
  isLoading?: boolean;
  threadSubject?: string;
}
export const VideoCarousel: React.FC<VideoCarouselProps> = ({
  data,
  isLoading = false,
  threadSubject,
  isAddThreadEnabled,
}) => {
  const chips = data.map((item, i) => <Chip key={item.title + i} {...item} />);

  const showEmptyStateMessage = chips.length === 0;
  const emptyStateMessage = isLoading ? S.videoLoading : S.noExercises;

  return (
    <ScrollView
      style={{ flexGrow: 0 }}
      horizontal
      contentContainerStyle={{ flexGrow: 1, height: chipWidth + chipPadding }}
    >
      <CarouselActions
        isAddThreadEnabled={isAddThreadEnabled}
        threadSubject={ChatScreenStrings.studentSubmission}
      />
      {showEmptyStateMessage && (
        <NoSubmissionsMessage text={emptyStateMessage} />
      )}
      {chips}
    </ScrollView>
  );
};
