import React from 'react';
import { useNavigation } from 'react-navigation-hooks';
import { View, TouchableOpacity } from 'react-native';
import prop from 'ramda/src/prop';
import any from 'ramda/src/any';
import compose from 'ramda/src/compose';

import { ListCard } from '../../components/ListCard';
import {
  EnrollmentType,
  CurriculumModuleType,
  LessonGroupType,
} from '../../graphql/types';
import { isLessonClickable } from '../../components/TechniquesList';
import { ExerciseCompletionBadges } from './ExerciseCompletionBadges';
import { LockedBadge } from './LockedBadge';

const isLessonGroupClickable = compose(any(isLessonClickable), prop('lessons'));

/**
 * Given a student's curriculum (passed via enrollment object), lists all the
 * contents of the curriculum (modules/lessons etc.)
 */
export const EnrollmentView: React.FC<{
  enrollment: Pick<EnrollmentType, 'curriculum'> | null;
}> = ({ enrollment }) => {
  if (!enrollment || !enrollment.curriculum.curriculumModules) return null;

  return (
    <>
      {enrollment.curriculum.curriculumModules.map(module => (
        <ModuleView key={`mod-${module?.id}`} module={module} />
      ))}
    </>
  );
};

/**
 * Shows the module's lesson groups in a list
 */
const ModuleView: React.FC<{ module: CurriculumModuleType | null }> = ({
  module,
}) => {
  if (!module || !module.lessonGroups) return null;

  return (
    <View key={module.id}>
      {module.lessonGroups.map(lessonGroup => (
        <LessonGroupView
          key={`lg-${lessonGroup?.id}`}
          lessonGroup={lessonGroup}
          module={module}
        />
      ))}
    </View>
  );
};

/**
 * Single lesson group - clickable with badges indicating how many of this
 * lesson group's exercises have been completed, or a locked badge indicating
 * this part of curriculum is not yet available
 */
const LessonGroupView: React.FC<{
  lessonGroup: LessonGroupType | null;
  module: CurriculumModuleType;
}> = ({ lessonGroup, module }) => {
  const { navigate } = useNavigation();

  if (!lessonGroup || !lessonGroup.lessons) return null;

  const clickable = isLessonGroupClickable(lessonGroup);

  const badges = clickable ? (
    <ExerciseCompletionBadges lessons={lessonGroup.lessons} />
  ) : (
    <LockedBadge />
  );

  const lessonGroupId = lessonGroup.id;
  const moduleTitle = module.title;

  return (
    <TouchableOpacity
      key={lessonGroupId}
      disabled={!clickable}
      onPress={() => {
        navigate('LessonGroupScreen', {
          lessonGroupId,
          moduleTitle,
        });
      }}
    >
      <ListCard
        title={lessonGroup.title}
        disabled={!isLessonGroupClickable(lessonGroup)}
        header={moduleTitle}
      >
        {badges}
      </ListCard>
    </TouchableOpacity>
  );
};
