import React, { useState } from 'react';
import {
  Text,
  TextInput,
  View,
  SafeAreaView,
  KeyboardAvoidingView,
  StatusBar,
  Platform,
  AsyncStorage,
  TouchableOpacity,
  Alert,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import { Button } from '../../components/Button';
import { rhythm, colors } from '../../designSystem';
import { useConsumeLoginTokenMutation } from '../../graphql/types';
import { styles } from './styles';
import { useInputValidation, Guard } from '../../hooks/useInputValidation';
import { useNavigation } from 'react-navigation-hooks';
import { trackError } from '../../utils/analytics';
import { AUTH_TOKEN_KEY } from '../../config';
import { SignInStrings as S } from '../../strings';

const ShowHideEye: React.FC<{ isHiding: boolean; size?: number }> = ({
  isHiding,
  size = 24,
}) => {
  const iconDoHide = 'md-eye-off';
  const iconDoShow = 'md-eye';

  return (
    <Ionicons
      name={isHiding ? iconDoShow : iconDoHide}
      size={size}
      color={colors.defaultBlue}
    />
  );
};

interface SetPasswordTextInputProps {
  doShowPassword: boolean;
  password: string;
  setDoShowPassword: (_: boolean) => void;
  setPassword: (_: string) => void;
}

const SetPasswordTextInput: React.FC<SetPasswordTextInputProps> = props => {
  const hidePassword = !props.doShowPassword;

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TextInput
        textContentType="password"
        style={[styles.input, { paddingRight: 30, width: '100%' }]}
        autoCapitalize="none"
        secureTextEntry={hidePassword}
        onChangeText={props.setPassword}
        value={props.password}
      />

      <TouchableOpacity
        onPress={() => props.setDoShowPassword(!props.doShowPassword)}
        style={{ position: 'relative', marginLeft: -30 }}
      >
        <ShowHideEye isHiding={hidePassword} />
      </TouchableOpacity>
    </View>
  );
};

export const SetPasswordScreen: React.FC = () => {
  const navigation = useNavigation();
  const [password, setPassword] = useState('');

  // by default, hide password on web, show on mobile (for fat fingers), see https://cxl.com/blog/password-ux/
  const [doShowPassword, setDoShowPassword] = useState(
    Platform.OS === 'web' ? false : true
  );

  const [
    consumeLoginToken,
    { loading: consumeLoginLoading, error: consumeLoginError, client },
  ] = useConsumeLoginTokenMutation();

  const email = navigation.getParam('email');
  const token = navigation.getParam('token');
  if (!email) throw new Error('Set password screen requires email param');
  if (!token) throw new Error('Set password screen requires token param');
  if (!client) throw new Error('apollo graphql client does not exist');

  const setPasswordAndLogin = () => {
    consumeLoginToken({
      variables: {
        token,
        password,
      },
    })
      .then(result => {
        if (
          result.data &&
          result.data.consumeLoginToken &&
          result.data.consumeLoginToken.token
        ) {
          const token = result.data.consumeLoginToken.token;
          return AsyncStorage.setItem(AUTH_TOKEN_KEY, token)
            .then(_ => client.resetStore())
            .then(_ => navigation.navigate('AuthLoading'));
        } else {
          throw new Error('no token from consumelogin token mutation');
        }
      })
      .catch(err => {
        Alert.alert(
          'Error',
          'Sorry, something went wrong. Please contact GMI tech support - contact@guitarmasteryintensive.com',
          [{ style: 'default', text: 'OK' }]
        );
        trackError(`Password reset fail ${JSON.stringify(err)}`);
      });
  };

  const buttonDisabled = consumeLoginLoading;
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.innerContainer}>
        <StatusBar barStyle="dark-content" />
        <KeyboardAvoidingView
          behavior="height"
          style={{
            position: 'relative',
            flex: 1,
            padding: rhythm[3],
          }}
        >
          <Text style={styles.heroText}>{S.setPasswordFor}</Text>
          <Text
            style={[
              styles.heroText,
              styles.heroTextLastLine,
              { fontStyle: 'italic' },
            ]}
          >
            {email}
          </Text>

          <View style={{ marginVertical: rhythm[2] }}>
            <Text style={styles.label}>{S.passwordLabel}</Text>
            <SetPasswordTextInput
              doShowPassword={doShowPassword}
              setDoShowPassword={setDoShowPassword}
              password={password}
              setPassword={setPassword}
            />
          </View>
          <Button
            title={S.setPassword}
            onPress={setPasswordAndLogin}
            disabled={buttonDisabled}
            style={{ marginBottom: rhythm[2], marginTop: rhythm[2] }}
          />
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  );
};
