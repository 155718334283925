import { useState } from 'react';
import { useNavigation } from 'react-navigation-hooks';

// checks focus due to 2020-02-28 reported issue where multiple versions of this screen
// are rendered on navigator at same time, we only want to check the top focused version
// https://www.notion.so/guitarmasteryintensive/tech-debt-multiple-versions-of-screen-are-rendered-entire-app-is-all-rendered-all-the-same-time-o-7e056dd14b3e4dc881123509274eb543

export const useIsLessonBrowser = () => {
  const navigation = useNavigation();
  const [isLessonBrowser] = useState(
    navigation.getParam('isLessonBrowser', false) && navigation.isFocused()
  );
  return isLessonBrowser;
};
