import { Platform } from '@unimodules/core';
import * as Sentry from 'sentry-expo';

type PlatformValues<T> = {
  [key in typeof Platform.OS | 'mobile' | 'default']?: T;
};
/**
 * Use to select value by platform.
 * For React components or computationally expensive values,
 * remember to wrap in a function, e.g.
 *   byPlatform({
 *     web: () => <BigComponent />,
 *     ios: () => <IOSBiggie />,
 *     default: () => <NoBiggie />
 *   })()
 * @param platformValues object with keys from Platform.OS, 'mobile', or 'default'
 *
 * Will log an error if no value exists for current platform
 */
export function byPlatform<T>(platformValues: PlatformValues<T>): T {
  const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

  if (platformValues[Platform.OS] !== undefined) {
    return platformValues[Platform.OS];
  }
  if (isMobile && platformValues['mobile'] !== undefined) {
    return platformValues['mobile'];
  }
  if (platformValues['default'] !== undefined) {
    return platformValues['default'];
  }

  const err = `byPlatform: no value for ${JSON.stringify(platformValues)} on ${
    Platform.OS
  }`;
  Sentry.captureException(err);
  console.error(err);
  return undefined;
}
