import { useEffect } from 'react';
import { useNavigation } from 'react-navigation-hooks';
import { Platform } from 'react-native';

const listenerFn = Platform.OS === 'web' ? 'didFocus' : 'willFocus';

export const useOnFocus = (fn: () => void) => {
  const { addListener } = useNavigation();
  useEffect(() => {
    // Note: `didFocus` seems to work cross-platform, not `willFocus`

    const listener = addListener(listenerFn, () => {
      // console.log('focus');
      return fn();
    });
    return () => {
      return listener.remove();
    };
  }, [fn]);
};
