import React from 'react';
import { SafeAreaView, StatusBar } from 'react-native';
import gql from 'graphql-tag';

import { colors } from '../../designSystem';
import { curriculum, lastSegmentExercises } from '../../graphql/fragments';
import { useLastSegmentQuery } from '../../graphql/types';
import { ErrorMessage, MissingDataError } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { NavigationScreenType } from '../../utils/navigationUtils';
import { PracticeSessionView } from '../../components/PracticeSessionView';

export const LAST_SEGMENT_QUERY = gql`
  query LastSegment {
    lastSegment {
      ...LastSegmentExercises
    }
  }
  ${lastSegmentExercises}
`;

export const PRACTICE_SCREEN_EXERCISES = gql`
  fragment PracticeScreenExercise on ExerciseType {
    id
    lesson {
      id
      s3ThumbnailUrl
      title

      exercises {
        id
        optional
        lastProgress {
          id
          rating
        }
      }
      lessonGroup {
        id
        title
        curriculumModule {
          id
          title
        }
        lessons {
          id
        }
        lessonGroupVideos {
          id
          description
          videoUrl
          s3ThumbnailUrl
        }
      }
    }
  }

  query PracticeScreenExercises($ids: [Int]) {
    exercises(ids: $ids) {
      ...PracticeScreenExercise
    }
  }
`;
/**
 * Shows the current practice session plan which must be
 * created before navigating here.
 */
export const PracticeSessionScreen: NavigationScreenType = () => {
  const lastSegmentQ = useLastSegmentQuery();

  const loading = lastSegmentQ.loading;

  if (loading) return <Loading />;
  if (lastSegmentQ.error) {
    return <ErrorMessage error={lastSegmentQ.error} />;
  }
  // if (curriculumQ.error) {
  //   return <ErrorMessage error={curriculumQ.error} />;
  // }
  if (!lastSegmentQ.data)
    // || !curriculumQ.data)
    return <MissingDataError type="Practice Session Screen" />;

  if (!lastSegmentQ.data || !lastSegmentQ.data.lastSegment)
    return <MissingDataError type="Practice Session Curriculum" />;

  return (
    <SafeAreaView
      style={{
        position: 'relative',
        backgroundColor: colors.lightGrey,
        flex: 1,
      }}
    >
      <StatusBar hidden />
      <PracticeSessionView lastSegment={lastSegmentQ.data.lastSegment} />
    </SafeAreaView>
  );
};

PracticeSessionScreen.navigationOptions = ({ navigation }) => ({
  headerLeft: null,
});
