import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';

import { ListCard } from './ListCard';
import { RenderableLesson } from '../dataLoader';
import { ErrorMessage, missingDataError } from './ErrorMessage';
import { LockedBadge } from './lessonBrowser/LockedBadge';
import { ExerciseCompletionBadges } from './lessonBrowser/ExerciseCompletionBadges';

interface TechiqueCardProps {
  item: RenderableLesson;
  onPress: (lesson: Lesson) => void;
  disabled?: boolean;
}

export const TechniqueCard: React.FC<TechiqueCardProps> = ({
  item,
  onPress,
  disabled = false,
}) => {
  // TODO Typescript cleanup here on item.
  const { moduleTitle, lessonGroupTitle, lesson } = item;
  const { navigate } = useNavigation();

  const props = {
    title: lessonGroupTitle,
    subtitle: lesson.title,
    image: lesson.s3ThumbnailUrl,
    disabled,
  };

  if (!lesson || !lesson.exercises || !lessonGroupTitle || !moduleTitle)
    return <ErrorMessage error={missingDataError('Technique Card')} />;

  const badges = disabled ? (
    <LockedBadge />
  ) : (
    <ExerciseCompletionBadges lessons={[lesson]} />
  );

  return (
    <TouchableOpacity
      key={`touchableOpacity:${lesson.id}`}
      disabled={disabled}
      onPress={() =>
        navigate('LessonScreen', {
          lessonId: lesson.id,
          lessonGroupTitle,
          moduleTitle,
          onComplete: onPress,
        })
      }
    >
      <ListCard key={`listCard:${lesson.id}`} {...props}>
        {badges}
      </ListCard>
    </TouchableOpacity>
  );
};
