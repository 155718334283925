import React, { useState, useEffect } from 'react';
import {
  Text,
  StyleSheet,
  View,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import times from 'ramda/src/times';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from 'react-navigation-hooks';
import gql from 'graphql-tag';

import { Button } from '../../components/Button';
import { colors, rhythm, fontSize, fonts } from '../../designSystem';
import { useExerciseRatingScreenQuery } from '../../graphql/types';
import { exerciseProgress, exerciseTitle } from '../../graphql/fragments';
import { Loading } from '../../components/Loading';
import { ErrorMessage, MissingDataError } from '../../components/ErrorMessage';
import { isRelationshipActive } from '../../utils/dateUtils';
import { HighTicketExpiredUpsell } from '../../components/HighTicketExpiredUpsell';
import { resolveExerciseTitle } from '../../graphql/resolvers';

import { useReportProgress } from '../../hooks/useReportProgress';

interface StarRatingProps {
  rating: number;
  onPress?: (n: number) => void;
}

const StarRating: React.FunctionComponent<StarRatingProps> = ({
  rating,
  onPress,
}) => (
  <View style={styles.starRatingContainer}>
    {times(n => {
      const i = Math.min(5, Math.max(1, n + 1));

      return (
        <TouchableOpacity key={i} onPress={e => onPress && onPress(i)}>
          <Text
            style={[
              styles.star,
              (!rating || i > rating) && styles.starInactive,
            ]}
          >
            <FontAwesome name="star" size={36} color={colors.white} />
          </Text>
        </TouchableOpacity>
      );
    }, 5)}
  </View>
);

export const reportProgress = gql`
  mutation ReportProgress($exerciseId: Int!, $segmentId: Int!, $rating: Int) {
    reportProgress(
      exerciseId: $exerciseId
      segmentId: $segmentId
      rating: $rating
    ) {
      progress {
        id
        exercise {
          id
        }
        rating
        submissionComplete
        startedAt
        endedAt
        uploadData {
          url
          fields
        }
      }
    }
  }
`;

export const EXERCISE_RATING_SCREEN = gql`
  query ExerciseRatingScreen($exerciseId: Int!) {
    lastSegment {
      id
    }
    exercise(id: $exerciseId) {
      id
      level
      soundsliceEmbedUrl
      ...ExerciseTitle
      lastProgress {
        id
        ...ExerciseProgress
      }
    }
    studentMentorRelationships {
      id
      endedAt
    }
    user {
      id
      student {
        id
        studentType
      }
    }
  }
  ${exerciseTitle}
  ${exerciseProgress}
`;

enum NextAction {
  SkipVideo,
  RecordVideo,
}

export const ExerciseRatingScreen: React.FC = () => {
  const { navigate, getParam } = useNavigation();
  const exerciseId = getParam('exerciseId');

  const {
    reportProgress,
    loading: reportLoading,
    error: reportError,
  } = useReportProgress();
  const [rating, setRating] = useState<number>(0);

  const { data, loading, error } = useExerciseRatingScreenQuery({
    variables: {
      exerciseId,
    },
  });

  useEffect(() => {
    if (
      data &&
      data.exercise &&
      data.exercise.lastProgress &&
      data.exercise.lastProgress.rating
    ) {
      setRating(data.exercise.lastProgress.rating);
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (
    !data ||
    !data.user ||
    !data.user.student ||
    !data.studentMentorRelationships ||
    !data.user.student.studentType ||
    !data.lastSegment
  )
    return <MissingDataError type="Exercise Rating Screen" />;

  const {
    user: {
      student: { studentType },
    },
    studentMentorRelationships,
    lastSegment,
  } = data;

  const currRelationship = studentMentorRelationships[0];
  const relationshipExists = !!currRelationship;
  const isExpiredHighTicket =
    relationshipExists && !isRelationshipActive(currRelationship);

  const canSendVideo = () =>
    studentType === 'HT' &&
    rating === 5 &&
    isRelationshipActive(currRelationship);

  const completeRating = (withAction: NextAction) => {
    const segmentId = parseInt(lastSegment.id);
    if (canSendVideo() && withAction === NextAction.RecordVideo) {
      navigate('RecordVideoScreen', {
        exerciseProgressId: exerciseProgress.id,
        exitScreen: 'PracticeSessionScreen',
        exerciseId,
        threadSubject: resolveExerciseTitle(data.exercise),
        // report progress AFTER
        rating,
        segmentId,
      });
    } else {
      reportProgress({
        rating,
        segmentId,
        exerciseId,
      })
        .then(() => {
          navigate('PracticeSessionScreen');
        })
        .catch(err => {
          alert(err);
        });
    }
  };

  const buttonText = canSendVideo() ? 'Send a video' : 'Submit Rating';

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.innerContainer}>
        <Text style={styles.heroText}>Finished! Select a rating</Text>
        <StarRating rating={rating} onPress={setRating} />
        {!!rating && (
          <>
            <Button
              disabled={loading || reportLoading}
              inverted
              onPress={() => completeRating(NextAction.RecordVideo)}
              title={buttonText}
            />
            {canSendVideo() && (
              <Button
                style={{ marginTop: 10 }}
                disabled={loading || reportLoading}
                onPress={() => completeRating(NextAction.SkipVideo)}
                title="Skip Sending Video"
              />
            )}
            {isExpiredHighTicket && <HighTicketExpiredUpsell />}
          </>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  heroText: {
    fontSize: fontSize[3],
    color: colors.white,
    marginBottom: rhythm[4],
    ...fonts.regular,
  },
  subtitleText: {
    fontSize: fontSize[1],
    color: colors.white,
    marginBottom: rhythm[2],
    textAlign: 'center',
    ...fonts.regular,
  },
  container: {
    backgroundColor: colors.accent,
    flex: 1,
  },
  innerContainer: {
    paddingHorizontal: rhythm[3],
    paddingVertical: rhythm[4],
    justifyContent: 'center',
  },
  button: {
    height: rhythm[4] * 2,
    backgroundColor: colors.white,
  },
  buttonText: {
    color: colors.accent,
  },
  picker: {
    width: '100%',
  },
  starRatingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: rhythm[3],
  },
  star: {},
  starInactive: {
    opacity: 0.3,
  },
});
