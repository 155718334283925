import amplitude from 'amplitude-js';
import Constants from 'expo-constants';

const version = Constants.manifest.version;
const initialize = apiKey => {
  return new Promise((res, rej) => {
    amplitude.getInstance().init(apiKey);
    amplitude.getInstance().setVersionName(version);
    res();
  });
};

const setUserId = id => amplitude.getInstance().setUserId(id);
const setUserProperties = props =>
  amplitude.getInstance().setUserProperties(props);
const logEvent = (...args) => amplitude.getInstance().logEvent(...args);
const logEventWithProperties = (event, props) =>
  amplitude.getInstance().logEvent(event, props);
const clearUserProperties = () => amplitude.getInstance().clearUserProperties();

module.exports = {
  initialize,
  setUserId,
  setUserProperties,
  logEvent,
  logEventWithProperties,
  clearUserProperties,
};
