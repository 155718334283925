import React from 'react';
import { useNavigation } from 'react-navigation-hooks';

import { Interstitial } from '../../components/Interstitial';
import { SessionCompleteScreen as S } from '../../strings';

export const SessionCompleteScreen = () => {
  const { dismiss } = useNavigation();
  return (
    <Interstitial
      title={S.title}
      subtitle={S.subtitle}
      buttonText={S.buttonText}
      onPress={() => dismiss()}
    />
  );
};
