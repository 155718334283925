import React from 'react';
import { TouchableOpacity } from 'react-native';
import { dateMDY } from '../utils/dateUtils';
import { StudentCard } from './StudentCard';
import { formatDate, ChatRelationshipType } from '../utils/chat';
import { useNavigation } from 'react-navigation-hooks';

interface RelationshipRowProps {
  relationship: ChatRelationshipType;
}
export const RelationshipRow: React.FC<RelationshipRowProps> = ({
  relationship,
}) => {
  const { navigate } = useNavigation();

  const startEndString = `${dateMDY(
    relationship.relationshipStartDate
  )} to ${dateMDY(relationship.relationshipEndDate)}`;

  const badgeCount = relationship.unreadCount;
  const hasUnread = badgeCount > 0;
  const mainThreadId = relationship.mainThreadId;

  const latestMessage = relationship.lastMainThreadMessage;

  const StudentInfoCard = () =>
    !latestMessage ? (
      <StudentCard
        title={relationship.student.fullName}
        subtitle={'New Student'}
        unread={true}
        startEndString={startEndString}
      />
    ) : (
      <StudentCard
        title={relationship.student.fullName}
        subtitle={latestMessage}
        startEndString={startEndString}
        badgeCount={hasUnread && badgeCount}
        accessoryText={formatDate(relationship.lastActivity)}
        unread={hasUnread}
      />
    );

  return (
    <TouchableOpacity
      key={relationship.id}
      onPress={() =>
        navigate('MentorChat', {
          relationshipId: relationship.id,
          mainThreadId,
          currentThreadId: mainThreadId,
          baseTitle: relationship.student.fullName,
        })
      }
    >
      <StudentInfoCard />
    </TouchableOpacity>
  );
};
