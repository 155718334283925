import gql from 'graphql-tag';

export const userInfo = gql`
  fragment User on UserType {
    id
    firstName
    lastName
    fullName @client
    userType
    email
  }
`;

export const exerciseTitle = gql`
  fragment ExerciseTitle on ExerciseType {
    id
    lesson {
      id
      title
      lessonGroup {
        id
        title
      }
    }
  }
`;

export const lastSegmentExercises = gql`
  fragment LastSegmentExercises on SegmentType {
    id
    newExerciseIds
    completedExerciseIds
    reviewExerciseIds
  }
`;

/**
 * this represents a completed uploaded attachment
 * may need to differentiate between this and a
 * recently created attachment which has other fields to use
 */
export const attachmentInfo = gql`
  fragment MessageAttachmentDownloadInfo on MessageAttachmentType {
    id
    complete
    s3Url
    s3ThumbnailUrl
  }
`;

export const messageWithAttachments = gql`
  fragment MessageWithAttachments on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      userType
      ...User
    }

    attachments {
      ...MessageAttachmentDownloadInfo
    }
  }
  ${attachmentInfo}
  ${exerciseTitle}
  ${userInfo}
`;

// export const messageThreadChatMessages

/**
 * Gets all attachments (2020-07-09 only new-style message attachments)
 * for a message thread
 */
export const messagesWithAttachments = gql`
  fragment MessagesWithAttachments on MessageThreadType {
    id
    messages {
      ...MessageWithAttachments
    }
  }
  ${messageWithAttachments}
`;

export const messageFragment = gql`
  fragment Message on MessageType {
    id
    contents
    complete
    createdAt
    read
    sender {
      id
      ...User
    }
  }
  ${userInfo}
`;

export const messageThreadMentorIndex = gql`
  fragment MentorIndexMessageThread on MessageThreadType {
    id
    lastActivity
    lastMessage {
      id
      contents
      attachments {
        ...MessageAttachmentDownloadInfo
      }
    }
    mentor {
      id
      user {
        id
        ...User
      }
    }
    student {
      id
      studentType
      level
      user {
        ...User
      }
    }
  }

  ${userInfo}
  ${attachmentInfo}
`;

export const mentorIndexStudentMentorRelationship = gql`
  fragment MentorIndexStudentMentorRelationship on StudentMentorRelationshipType {
    id
    startedAt
    endedAt
    unreadCount
    messageThread {
      ...MentorIndexMessageThread
    }
  }
  ${messageThreadMentorIndex}
`;

export const studentTabRelationship = gql`
  fragment StudentTabRelationship on StudentMentorRelationshipType {
    id
    unreadCount
    startedAt
    endedAt
    messageThread {
      id
      student {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
      mentor {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
    }
  }
`;

export const exerciseProgress = gql`
  fragment ExerciseProgress on ExerciseProgressType {
    rating
    submissionComplete
    startedAt
    endedAt
    s3Url
  }
`;

export const exerciseInfo = gql`
  fragment ExerciseInfo on ExerciseType {
    id
    level
    optional
    soundsliceEmbedUrl
    lastProgress {
      id
      ...ExerciseProgress
    }
  }
`;

export const lessonInfo = gql`
  fragment LessonInfo on LessonType {
    title
    description
    exercises {
      id
      ...ExerciseInfo
    }
    s3ThumbnailUrl
    videoUrl
    supplementary
    supplementaryJSON @client
  }
  ${exerciseInfo}
`;

export const lessonGroupVideo = gql`
  fragment LessonGroupVideo on LessonGroupVideoType {
    id
    description
    videoUrl
    s3ThumbnailUrl
  }
`;

export const moduleInfo = gql`
  fragment ModuleInfo on CurriculumModuleType {
    title
    description
  }
`;

export const curriculumLessonGroup = gql`
  fragment CurriculumLessonGroup on LessonGroupType {
    id
    title
    lessons {
      id
      ...LessonInfo
    }
    lessonGroupVideos {
      id
      ...LessonGroupVideo
    }
  }
`;

export const curriculum = gql`
  fragment Curriculum on CurriculumType {
    id
    title
    description
    curriculumModules {
      id
      ...ModuleInfo
      lessonGroups {
        ...CurriculumLessonGroup
      }
    }
  }
  ${lessonInfo}
  ${lessonGroupVideo}
  ${moduleInfo}
  ${curriculumLessonGroup}
`;

export const segment = gql`
  fragment Segment on SegmentType {
    id
    createdAt
    completedExerciseIds
    reviewExerciseIds
    newExerciseIds
  }
`;

export const settings = gql`
  fragment Settings on UserSettings {
    practiceReminder {
      enabled
      date
      notificationId
    }
    chatNotifications
  }
`;
