import { useState } from 'react';

export type Guard<T> = (value: T) => string;

interface UseInputValidationReturn<T> {
  value: T;
  error: string;
  setError: (val: string) => void;
  onChange: (val: T) => void;
  onFocus: () => void;
  onBlur: () => void;
  disabled: boolean;
}

export const useInputValidation = <T extends unknown>(
  guards: Guard<T>[]
): UseInputValidationReturn<T> => {
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const disabled = !!error || !value;

  const onChange = setValue;

  const onFocus = () => {
    setError(null);
  };

  const onBlur = () => {
    const errorMessage = guards.reduce<string>((acc, guard) => {
      const message = guard(value);
      if (message) {
        return message;
      }
      return acc;
    }, null);

    setError(errorMessage);
  };

  return { value, error, setError, onChange, onFocus, onBlur, disabled };
};
