/**
 * Analytics & event reporting
 */

import * as Sentry from 'sentry-expo';
import * as Amplitude from 'expo-analytics-amplitude';
import Constants from 'expo-constants';

import { amplitudeApiKey } from './environment';
import { UserType } from '../graphql/types';
import kebabCase from 'lodash/kebabCase';

/**
 * Tracks an error
 */
export function trackError(error: any) {
  if (__DEV__) console.error(error);
  Sentry.captureException(error);
  trackEvent('track-error', { error });
}

/**
 * Main zones for analytics
 */
export enum Zone {
  ExerciseScreen = 'exercise-screen',
  RatingScreen = 'rating-screen',
  HomeScreen = 'home-screen',
  Buttons = 'btn',
  Navigate = 'navigate',
}

/**
 * Creates an event name from prefix & name and tracks it
 * @param prefix used for category of event, gets prefixed to ${name}
 * @param name name of specific event, will get converted to lowercase-kebab-case
 * @param data any event data you want included, can be an object.
 */
export const trackFormattedEvent = (zone: Zone, name: string, data?: any) => {
  trackEvent(`${zone}-${kebabCase(name)}`, data);
};

/**
 * Tracks this event in analytics (& Sentry breadcrumbs)
 * @param eventName - name of event to track
 * @param data - any data you want included, can be an object
 */
export const trackEvent = (eventName: string, data?: any) => {
  Sentry.addBreadcrumb({
    category: eventName,
    message: data,
    level: Sentry.Severity.Info,
  });
  if (data) {
    Amplitude.logEventWithProperties(eventName, data);
  } else {
    Amplitude.logEvent(eventName);
  }

  if (__DEV__) {
    console.log(`⚡️ ${eventName}`, data || '');
  }
};

let isAmplitudeInitialized = false;
export const initializeAmplitude = () => {
  const apiKey = amplitudeApiKey();
  if (isAmplitudeInitialized || !apiKey) {
    return;
  }

  Amplitude.initialize(apiKey).then(() => {
    Amplitude.setUserProperties({
      manifest_version: Constants.manifest.version,
    });
  });
  isAmplitudeInitialized = true;
};

export const identifyUser = (
  user: Pick<UserType, 'id' | 'fullName' | 'email'>
) => {
  Amplitude.setUserId(user.id);
  Amplitude.setUserProperties({
    name: user.fullName,
    email: user.email,
  });
  Sentry.setUser({
    id: user.id,
    username: user.fullName,
    email: user.email,
  });
};

export const clearUser = () => {
  Amplitude.clearUserProperties();
  Sentry.setUser(null);
};
