import React from 'react';
import { NavigationActions, NavigationContainer } from 'react-navigation';
import { trackFormattedEvent, Zone, trackEvent } from './analytics';

/**
 * Creates custom getActionForPathAndParams router function. Put any
 * specialized paths such as /login here. Also takes care of redirecting
 * non-existing paths to Home
 * @param navRouter any react-navigation router
 */
export function addLoginRouteForWeb(navRouter: NavigationContainer) {
  const defaultGetActionForPathAndParams =
    navRouter.router.getActionForPathAndParams;
  const navigate = NavigationActions.navigate;

  const getActionForPathAndParams = (path: string, params) => {
    const loginTokenStart = 'login/';
    const login = path.indexOf(loginTokenStart);
    const passwordTokenStart = 'setPassword/';
    const password = path.indexOf(passwordTokenStart);

    if (login >= 0) {
      const token = path.substr(login + loginTokenStart.length);
      return navigate({
        routeName: 'AuthLoading',
        params: {
          token,
        },
      });
    }

    if (password >= 0) {
      const token = path.substr(password + passwordTokenStart.length);
      const email = params['email'];
      trackEvent('navigate-reset-password', email);
      return navigate({
        routeName: 'SetPassword',
        params: {
          token,
          email,
        },
      });
    }

    return defaultGetActionForPathAndParams(path, params);
  };

  Object.assign(navRouter.router, {
    getActionForPathAndParams: getActionForPathAndParams,
  });

  return navRouter;
}

/**
 * returns the current route (name & params) from navigation state
 */
export function getActiveRoute(navigationState) {
  if (!navigationState) {
    return null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.routes) {
    return getActiveRoute(route);
  }
  return { name: route.routeName, params: route.params };
}

export const trackNavigationChange = (prevState, currentState, action) => {
  const { name: currentRouteName, params } = getActiveRoute(currentState);
  const { name: previousRouteName } = getActiveRoute(prevState);
  // console.dir(currentState, { depth: null }); //<-- 🐇HOW DEEP DOES THE RABBIT HOLE GO. CHECK THIS

  if (previousRouteName !== currentRouteName) {
    trackFormattedEvent(Zone.Navigate, currentRouteName, params);
  }
};

// Todo: add more type safety for navigation options
export type NavigationScreenType<T = {}> = React.FC<T> & {
  navigationOptions?: any;
};

export const booleanParam = (navParam: any) => navParam && navParam !== 'false';
