import React from 'react';
import {
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  Dimensions,
} from 'react-native';
import { colors, rhythm } from '../designSystem';
import { byPlatform } from '../utils/platform';
interface ContainerProps {
  style?: StyleProp<ViewStyle>;
  pull?: Boolean;
}

export const Container: React.SFC<ContainerProps> = props => {
  return (
    <View {...props} style={[styles.container, props.style]}>
      <View
        style={[
          styles.innerContainer,
          !!props.pull && styles.innerContainerPull,
        ]}
      >
        {props.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGrey,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    height: byPlatform<ViewStyle['height']>({
      web: '80vh', // TODO: FIXME, 100% height doesn't work.
      default: '100%',
    }),
  },
  innerContainer: {
    flex: 1,
    padding: rhythm[2],
  },
  innerContainerPull: {
    paddingVertical: 0,
    marginTop: -rhythm[4],
    marginBottom: rhythm[4],
  },
});
