import React, { useState, ReactElement } from 'react';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import Carousel, { CarouselStatic } from 'react-native-snap-carousel';
import { colors, rhythm, fontSize } from '../designSystem';
import { byPlatform } from '../utils/platform';

const chipWidth = 140;
const itemWidth = chipWidth + rhythm[1] * 2;
interface ChipProps {
  children: string | number | ReactElement;
  selected?: boolean;
}
const Chip: React.FC<ChipProps> = props => (
  <View style={chipStyles.chipWrapper}>
    <View style={[chipStyles.chip, props.selected && chipStyles.selectedChip]}>
      <Text style={chipStyles.chipText}>{props.children}</Text>
      <Text style={chipStyles.chipSubtitle}>minutes</Text>
    </View>
  </View>
);

const chipStyles = StyleSheet.create({
  chipWrapper: {
    width: itemWidth,
    height: chipWidth,
    paddingHorizontal: rhythm[1],
  },
  chip: {
    flex: 1,
    backgroundColor: byPlatform({
      web: colors.accentDarken,
      default: colors.white,
    }),
    width: chipWidth,
    borderRadius: rhythm[1],
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedChip: {
    // For web only
    backgroundColor: byPlatform({
      default: colors.white,
    }),
  },
  chipText: {
    color: colors.accent,
    fontSize: 36,
  },
  chipSubtitle: {
    color: colors.accent,
    fontSize: fontSize[1],
  },
});

interface PickerProps<T> {
  options: T[];
  initialItemIndex: number;
  onSelectItem?: (itemIndex: number) => void;
}

export const WebPicker: React.FC<PickerProps<number>> = props => {
  const [selectedIndex, setSelectedIndex] = useState(props.initialItemIndex);
  const setSelected = (index: number) => {
    setSelectedIndex(index);
    // TODO: linter is not handling the following line right.
    // It should catch that props.onSelectItem can be undefined
    // that's okay, I'll still make sure it's defined.
    props.onSelectItem && props.onSelectItem(index);
  };
  return (
    <View style={webPickerStyles.container}>
      {props.options.map((option, index) => (
        <View style={webPickerStyles.wrappableChip} key={option}>
          <TouchableOpacity onPress={() => setSelected(index)}>
            <Chip selected={selectedIndex === index}>{option}</Chip>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const webPickerStyles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  wrappableChip: {
    paddingTop: rhythm[2],
  },
});

export class NativePicker extends React.Component<PickerProps<number>> {
  _carousel: CarouselStatic<any>;

  _renderItem = ({ item, index }) => {
    return (
      <TouchableWithoutFeedback
        onPress={_ => {
          this._carousel.snapToItem(index);
        }}
      >
        <Chip>{item}</Chip>
      </TouchableWithoutFeedback>
    );
  };

  render() {
    const sliderWidth = Dimensions.get('window').width;
    return (
      <Carousel
        data={this.props.options}
        ref={c => (this._carousel = c)}
        renderItem={this._renderItem}
        sliderWidth={sliderWidth}
        itemWidth={itemWidth}
        firstItem={this.props.initialItemIndex}
        enableMomentum={true}
        decelerationRate={0.9}
        inactiveSlideScale={1}
        activeSlideAlignment="center"
        useScrollView={true}
        onSnapToItem={this.props.onSelectItem}
      />
    );
  }
}

export const Picker: React.FC<PickerProps<number>> = props => {
  return byPlatform({
    web: () => <WebPicker {...props} />,
    default: () => <NativePicker {...props} />,
  })();
};
