import React from 'react';
import { View, Text } from 'react-native';
import { rhythm, colors, fonts, fontSize } from '../designSystem';
import { CalendarHeatmap } from './CalendarHeatmap';
import { StudentType, SegmentType, Maybe } from '../graphql/types';
import last from 'ramda/src/last';
import sortBy from 'ramda/src/sortBy';
import prop from 'ramda/src/prop';
import { distanceInWordsToNow } from 'date-fns';

/**
 * Pop up view of Student's Calendar, for report card
 */
const StudentProfile = ({ student, visible }) => {
  return (
    <View
      style={{
        display: visible ? 'flex' : 'none',
        padding: rhythm[2],
        backgroundColor: colors.accent,
        alignItems: 'center',
      }}
    >
      <CalendarHeatmap records={student.segments} />
    </View>
  );
};

interface StudentReportCardProps {
  student: Pick<StudentType, 'segments'>;
  showCalendar: boolean;
  showLastPracticed?: boolean;
}

const isSegment = (seg: Maybe<SegmentType>): seg is SegmentType =>
  !!seg && !!seg.createdAt;

/**
 * View of student's last practiced time, and hide-able profile
 * for mentor chat view
 */
export const StudentReportCard: React.FC<StudentReportCardProps> = ({
  student,
  showCalendar,
  showLastPracticed = true,
}) => {
  const segments = (student.segments || []).filter(isSegment);
  const lastSession = last(sortBy(prop('createdAt'), segments));

  let lastPracticedText = '';

  if (lastSession) {
    // const lastSessionDiffInDays = differenceInDays(Date.now(), lastSession.createdAt);
    lastPracticedText = `Last practiced ${distanceInWordsToNow(
      lastSession.createdAt,
      { addSuffix: true }
    )}`;
  } else {
    lastPracticedText = 'Hasn’t started yet';
  }

  return (
    <>
      <StudentProfile student={student} visible={showCalendar} />
      {showLastPracticed && (
        <View
          style={{
            backgroundColor: colors.red,
            alignItems: 'center',
            padding: rhythm[2],
          }}
        >
          <Text
            style={{
              ...fonts.regular,
              fontSize: fontSize[0],
              textAlign: 'center',
              color: colors.white,
            }}
          >
            {lastPracticedText}
          </Text>
        </View>
      )}
    </>
  );
};
