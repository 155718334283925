import React, { useEffect } from 'react';

import {
  MessageAttachmentCreationResult,
  useFinalizeSubmissionMutation,
  useCreateMessageAttachmentOnExistingThread,
} from '../../../graphql/complexHooks';

interface SharedLogicArgs {
  messageThreadId: number;
  exerciseId?: number;
  onExit: () => void;
  isZiggeo: boolean;
}

export const useSharedVideoRecorderLogic = ({
  messageThreadId,
  exerciseId,
  onExit,
  isZiggeo,
}: SharedLogicArgs) => {
  /**
   * Result of mutations creating new message & attachment
   */
  const [
    creationResult,
    setCreationResult,
  ] = React.useState<MessageAttachmentCreationResult | null>(null);

  /**
   * To finalize video submission, we must publish the message and
   * (for non-ziggeo videos) mark the attachment complete.
   */
  const finalizeSubmissionMutation = useFinalizeSubmissionMutation({
    options: { isZiggeoSubmission: isZiggeo },
  });

  /**
   * Starts a new message on the thread, creates an attachment on the
   * new message - pass an onCompleted handler to `startNewMessageAttachment`
   */
  const startNewMessageAttachment = useCreateMessageAttachmentOnExistingThread({
    messageThreadId,
    exerciseId,
  });

  /**
   * On component mount, run startNewMessageAttachment mutation
   */
  useEffect(() => {
    // Begin new message, onComplete will set creationResult
    // console.log('creating new message attachment');
    startNewMessageAttachment({
      onCompleted: result => setCreationResult(result),
    });
  }, []);

  /** Runs the finalization mutation, then navigates away */
  const finalizeSubmissionThenNavigate = () => {
    if (
      !creationResult ||
      !creationResult.attachment ||
      !creationResult.attachment.id ||
      !creationResult.messageId
    )
      throw new Error(
        'attempt to publish message attachment without creationResult'
      );

    finalizeSubmissionMutation({
      messageId: creationResult.messageId,
      attachmentId: parseInt(creationResult.attachment.id),
    }).then(onExit);
  };
  return { creationResult, finalizeSubmissionThenNavigate };
};
