import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { rhythm, colors, fontSize, fonts } from '../designSystem';
import { Video } from './Video';

interface VideoCardProps {
  header: string;
  title: string;
  videoUrl: string;
  posterSource: string;
  description?: string;
}

export const VideoCard: React.FC<VideoCardProps> = ({
  header,
  title,
  videoUrl,
  description,
  posterSource,
}) => {
  return (
    <View style={styles.container}>
      <Video videoUri={videoUrl} posterUri={posterSource} />
      <Text style={styles.header}>{header}</Text>
      <Text style={styles.title}>{title}</Text>
      {!!description && <Text style={styles.description}>{description}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: rhythm[2],
    padding: rhythm[2],
  },
  header: {
    color: colors.darkGrey,
    fontSize: fontSize[1],
    ...fonts.bold,
    marginTop: rhythm[2],
    marginBottom: rhythm[1],
  },
  title: {
    color: colors.darkGrey,
    fontSize: fontSize[2],
    ...fonts.bold,
    marginBottom: rhythm[1],
  },
  description: {
    fontSize: fontSize[1],
    lineHeight: fontSize[1] * 1.43,
    ...fonts.regular,
    color: colors.darkGrey,
  },
});
