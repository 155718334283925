import React from 'react';
import { Platform } from 'react-native';

import { Loading } from '../../../components/Loading';
import { WebVideoRecorder } from '../../../components/camera/WebVideoRecorder';
import { useSharedVideoRecorderLogic } from './sharedLogic';
import { VideoRecorderProps } from './index';

export const VideoRecorder: React.FC<VideoRecorderProps> = ({
  onExit,
  exerciseId,
  messageThreadId,
}) => {
  if (Platform.OS !== 'web')
    throw new Error('Web version of VideoRecorder rendered NOT IN WEB');

  /**
  /** Runs the finalization mutation, then navigates away */
  const {
    creationResult,
    finalizeSubmissionThenNavigate,
  } = useSharedVideoRecorderLogic({
    onExit,
    exerciseId,
    messageThreadId,
    isZiggeo: true,
  });

  if (!creationResult) return <Loading />;

  return (
    <WebVideoRecorder
      finalizeUpload={finalizeSubmissionThenNavigate}
      onCancel={onExit}
      messageAttachmentId={+creationResult.attachment.id}
      submitButtonText={'Submit'}
    />
  );
};
