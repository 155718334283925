import React, { ReactNode } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  ButtonProps as RNButtonProps,
  View,
} from 'react-native';
import { fonts, colors, rhythm, fontSize } from '../designSystem';
import { Progress } from './SkinnyProgress';
import { trackFormattedEvent, Zone } from '../utils/analytics';
import { useNavigation } from 'react-navigation-hooks';

interface ButtonProps extends RNButtonProps {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  children?: ReactNode;
  inverted?: boolean;
  analyticTitle?: string;
}

export const Button: React.FC<ButtonProps> = props => {
  const { state } = useNavigation();

  const {
    title = 'Enter',
    style = {},
    textStyle = {},
    onPress,
    disabled,
    inverted = false,
    children,
    analyticTitle,
  } = props;

  const onPressWithAnalytics = eventInfo => {
    trackFormattedEvent(Zone.Buttons, title || analyticTitle || '', {
      route: state.routeName,
    });
    onPress(eventInfo);
  };

  return (
    <TouchableOpacity
      onPress={onPressWithAnalytics}
      disabled={disabled}
      style={[
        styles.button,
        inverted ? styles.buttonInverted : styles.buttonRegular,
        disabled && styles.disabled,
        style,
      ]}
    >
      <Text style={[styles.text, textStyle, inverted && styles.textInverted]}>
        {title}
      </Text>
      {children}
    </TouchableOpacity>
  );
};

interface ProgressButtonProps extends ButtonProps {
  progress: number;
  showProgress?: boolean;
}

export const ProgressButton: React.FC<ProgressButtonProps> = ({
  progress,
  showProgress = true,
  ...props
}) => (
  <Button {...props}>
    {showProgress && (
      <View style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
        <Progress progress={progress} min={0} />
      </View>
    )}
  </Button>
);

interface TextButtonProps extends RNButtonProps {}

export const TextButton: React.SFC<ButtonProps> = props => {
  // const { style = {}, textStyle = {}, ...rest } = props;

  const newStyle = {
    // ...style,
    backgroundColor: 'transparent',
    ...(props.disabled && styles.disabled),
  };

  const textStyle = {
    color: props.color,
  };

  return <Button style={newStyle} {...props} textStyle={textStyle} />;
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    height: rhythm[5],
    paddingLeft: rhythm[3],
    paddingRight: rhythm[3],
    borderRadius: rhythm[1],
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  buttonInverted: {
    backgroundColor: colors.white,
  },
  buttonRegular: {
    backgroundColor: colors.accent,
  },
  text: {
    fontSize: fontSize[1],
    color: colors.white,
    ...fonts.regular,
  },
  textInverted: {
    color: colors.accent,
  },
  disabled: {
    opacity: 0.5,
  },
});
