import React from 'react';
import { Interstitial } from './Interstitial';
import { Linking } from 'expo';
import { LowTicket as S } from '../strings';
import { trackEvent } from '../utils/analytics';
export const LowTicketUpsellInterstitial = () => (
  <Interstitial
    subtitle={S.deniedSubtitle}
    buttonText={S.buttonText}
    onPress={() => {
      trackEvent('click-mentorship-inquiry');
      Linking.openURL(
        'mailto:contact@guitarmasteryintensive.com?subject=I am interested in receiving mentorship'
      );
    }}
  />
);
