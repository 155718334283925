import React from 'react';
import { useNavigation } from 'react-navigation-hooks';
import { VideoRecorder } from '../../components/camera/VideoRecorder';

export const RecordThreadVideoScreen: React.FC = () => {
  const { navigate, getParam } = useNavigation();
  const messageThreadId: number | null = getParam('messageThreadId');

  if (!messageThreadId) {
    throw new Error('Record Video: Message Thread Id required');
  }

  const onExit = () =>
    navigate('MentorChat', { currentThreadId: messageThreadId });

  return <VideoRecorder onExit={onExit} messageThreadId={messageThreadId} />;
};
