import { Machine, assign } from 'xstate';
import { Video } from 'expo-av';
import { AVPlaybackStatus as PlaybackStatus } from 'expo-av/build/AV';

export interface VideoContext {
  video: Video;
}

export interface VideoStateSchema {
  states: {
    loading: {};
    ready: {
      states: {
        idle: {};
        playing: {};
      };
    };
    failure: {};
  };
}

export type VideoEvent =
  | { type: 'LOADED'; video: Video; status: PlaybackStatus }
  | { type: 'FAIL'; error: string }
  | { type: 'PLAY' }
  | { type: 'PAUSE' };

export const videoMachine = Machine<VideoContext, VideoStateSchema, VideoEvent>(
  {
    id: 'video',
    initial: 'loading',

    context: {
      video: null,
    },

    states: {
      loading: {
        on: {
          LOADED: {
            target: 'ready',
            actions: ['setVideo'],
          },
          FAIL: 'failure',
        },
      },
      ready: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              PLAY: {
                target: 'playing',
                actions: ['playVideo'],
              },
            },
          },
          playing: {
            on: {},
          },
        },
      },
      failure: {
        type: 'final',
      },
    },

    on: {
      // handle pause no matter what state
      PAUSE: {
        target: 'ready.idle',
        actions: ['pauseVideo'],
      },
    },
  }
);
