import React, { useContext, useEffect, useMemo } from 'react';

import gql from 'graphql-tag';
import { Notifications } from 'expo';
import { Notification } from 'expo/build/Notifications/Notifications.types';
import { useNavigation } from 'react-navigation-hooks';
import { RelationshipRow } from '../../components/RelationshipRow';

import { RefetchScrollView } from '../../components/RefetchScrollView';
import { Loading } from '../../components/Loading';

import { mentorIndexStudentMentorRelationship } from '../../graphql/fragments';
import { useMentorIndexQuery } from '../../graphql/types';
import {
  ErrorMessage,
  missingDataError,
  MissingDataError,
} from '../../components/ErrorMessage';
import {
  ChatRelationshipType,
  mentorIndexRelationshipToChatRelationship,
} from '../../utils/chat';
import { showMessage } from 'react-native-flash-message';
import { colors } from '../../designSystem';
import { trackError } from '../../utils/analytics';
import { ChatContext } from '../../services/ChatContext';

import { hashMentorRelationships } from '../../utils/icanhashcheezeburger';
import { useOnFocus } from '../../hooks/useOnFocus';
import { useOnBlur } from '../../hooks/useOnBlur';

export const MENTOR_INDEX_QUERY = gql`
  query MentorIndex {
    studentMentorRelationships {
      ...MentorIndexStudentMentorRelationship
    }
  }

  ${mentorIndexStudentMentorRelationship}
`;

const MENTOR_HOME_POLL_INTERVAL = 10 * 1000; // 10 seconds

export const MentorHomeScreen: React.FC = () => {
  // console.log('* re-render * <MentorHome>');
  const { currentThreadId, setCurrentThreadId } = useContext(ChatContext);
  const { navigate } = useNavigation();

  const {
    data,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
  } = useMentorIndexQuery({
    pollInterval: MENTOR_HOME_POLL_INTERVAL,
    errorPolicy: 'all',
  });
  useOnFocus(() => {
    setCurrentThreadId(-1);
    refetch();
    startPolling(MENTOR_HOME_POLL_INTERVAL);
  });
  useOnBlur(() => stopPolling);

  const relationshipWithId = (id: number | string) =>
    data &&
    data.studentMentorRelationships &&
    data.studentMentorRelationships.find(r => r && +r.id === +id);

  const handleNotification = (notification: Notification) => {
    const isMessage =
      notification.data.type === 'message' ||
      notification.data.type === 'thread';
    if (isMessage) {
      const notificationThreadId = +notification.data.messageThreadId;
      const relationship = relationshipWithId(notification.data.relationshipId);

      const openMessage = () => {
        relationship &&
          relationship.messageThread &&
          navigate('MentorChat', {
            relationshipId: +relationship.id,
            mainThreadId: +relationship.messageThread.id,
            currentThreadId: notificationThreadId,
            baseTitle: relationship.messageThread.student.user.fullName,
          });
      };

      switch (notification.origin) {
        case 'received': {
          // received whilst app is foregrounded
          refetch();
          const senderName = notification.data.senderFullName || 'student';
          if (notificationThreadId === currentThreadId) return;
          // Wait to show the message due to ChatView polling latency
          // We want the user to see the message.

          setTimeout(
            () =>
              showMessage({
                message: `New message from ${senderName}`,
                type: 'default',
                onPress: openMessage,
                onLongPress: openMessage,
              }),
            3 * 1000
          );
          return;
        }
        case 'selected': {
          openMessage();
          return;
        }
      }
    }
  };

  useEffect(() => {
    const subscription = Notifications.addListener(handleNotification);

    return () => {
      subscription.remove();
    };
  }, [data]); // handleNotification won't function unless addListener is called when data changes

  const isRelationshipActive = (relationship: ChatRelationshipType) => {
    let now = new Date();
    return (
      relationship.relationshipStartDate <= now &&
      relationship.relationshipEndDate >= now
    );
  };

  /**
   * High Ticket, active relationships for this mentor
   */
  const relationships: ChatRelationshipType[] = useMemo(() => {
    if (!data || !data.studentMentorRelationships) return [];
    return data.studentMentorRelationships.reduce(
      (acc, curr) =>
        curr &&
        curr.messageThread &&
        curr.messageThread.student.studentType === 'HT'
          ? acc.concat(mentorIndexRelationshipToChatRelationship(curr))
          : acc,
      [] as ChatRelationshipType[]
    );
  }, [data]);

  if (loading || !data) return <Loading />;
  if (error) {
    if (!data) return <ErrorMessage error={error} />;
    else trackError(error);
  }
  if (!data || !data.studentMentorRelationships)
    return <ErrorMessage error={missingDataError('Mentor Home')} />;

  if (!relationships) return <MissingDataError type="ChatRelationships" />;

  return (
    <RefetchScrollView
      style={{ flex: 1, backgroundColor: colors.lightGrey }}
      refetch={refetch}
    >
      {/* TODO <Text>Current Students</Text> */}
      {Object.values(relationships)
        .filter(isRelationshipActive)
        .sort((a, b) =>
          (a.lastActivity || new Date()) < (b.lastActivity || new Date())
            ? 1
            : -1
        )
        .map(relationship => (
          <RelationshipRow
            relationship={relationship}
            key={`r-${relationship.id}`}
          />
        ))}
    </RefetchScrollView>
  );
};
