import React from 'react';
import Svg, { Circle, Rect, Defs, ClipPath, Path } from 'react-native-svg';
import { View } from 'react-native';

import { colors } from '../designSystem';
import times from 'ramda/src/times';
import { byPlatform } from '../utils/platform';

type BadgeType = 'required' | 'optional';
type BadgeStatus = 'initial' | 'inProgress' | 'completed';

const viewBox = (size: number) => [0, 0, size, size].join(' ');

interface StarProps {
  innerRadiusRatio?: number;
  size?: number;
  status: BadgeStatus;
  color?: string;
}

export const Star: React.FunctionComponent<StarProps> = ({
  status,
  innerRadiusRatio = 0.5,
  size = 16,
  color = '#000',
}) => {
  const outerRadius = (size - 2) / 2;
  const innerRadius = (innerRadiusRatio * size) / 2;
  const c = size / 2;

  const num = (n: number) => (n < 0.0000001 ? 0 : n);

  const rad = (theta: number) => (Math.PI * theta) / 180;

  const rx = (r: number, theta: number) => c + r * Math.cos(rad(theta));

  const ry = (r: number, theta: number) => c + r * Math.sin(rad(theta));

  const drawStar = (n: number) => {
    const totalN = n * 2;
    const angle = 360 / totalN;
    const offset = 90;

    return [
      ...times(i => {
        const a = angle * i - offset;

        const radius = i % 2 === 0 ? outerRadius : innerRadius;

        return `${i === 0 ? 'M' : 'L'}${num(rx(radius, a))},${num(
          ry(radius, a)
        )}`;
      }, totalN),
      'Z',
    ].join(' ');
  };

  const pathData = [drawStar(5)].join(' ');

  return (
    <Svg width={size} height={size} viewBox={viewBox(size)}>
      <Defs>
        <ClipPath id="clip">
          <Rect x={0} y={0} width={c} height={size} />
        </ClipPath>
      </Defs>
      <Path d={pathData} strokeWidth="2" fill="none" stroke={color} />

      {status === 'inProgress' && (
        <Path clipPath="url(#clip)" d={pathData} strokeWidth="2" fill={color} />
      )}
      {status === 'completed' && (
        <Path d={pathData} strokeWidth="2" fill={color} />
      )}
    </Svg>
  );
};

interface DotProps {
  size: number;
  status: BadgeStatus;
  color?: string;
}

/**
 * Unique index for each clipPath. There is no semantic assignment
 * available, and they need to be unique to work in web. See <Dot>
 */
let clipIndex = 0;

export const Dot: React.FunctionComponent<DotProps> = ({
  size,
  status,
  color,
}) => {
  const r = size / 2;
  const adjustedR = r - 2.5;
  const center = byPlatform({
    web: '50%',
    mobile: '0',
  });
  const clipXY = byPlatform({
    web: { x: 0, y: 0 },
    mobile: { x: -r, y: -r },
  });
  clipIndex++;
  return (
    <Svg width={size} height={size} viewBox={viewBox(size)}>
      <Defs>
        <ClipPath id={`cl-${clipIndex}`}>
          <Rect {...clipXY} width={r} height={size} />
        </ClipPath>
      </Defs>
      <Circle
        x={r}
        y={r}
        cx={center}
        cy={center}
        r={adjustedR}
        stroke={color}
        strokeWidth={2}
        fill="none"
      />
      {status === 'inProgress' && (
        <Circle
          clipPath={`url(#cl-${clipIndex}`}
          x={r}
          y={r}
          cx={center}
          cy={center}
          r={adjustedR}
          fill={color}
        />
      )}
      {status === 'completed' && (
        <Circle
          x={r}
          y={r}
          cx={center}
          cy={center}
          r={adjustedR}
          fill={color}
        />
      )}
    </Svg>
  );
};

export interface BadgeProps {
  size?: number;
  inverse?: Boolean;
  status: BadgeStatus;
  type: BadgeType;
}

export const Badge: React.FunctionComponent<BadgeProps> = ({
  type,
  status,
  size = 16,
  inverse,
}) => {
  const color = !!inverse ? colors.white : colors.accentDarken;

  return (
    <View
      style={{
        width: size,
        height: size,
        marginHorizontal: 2,
      }}
    >
      {type === 'required' ? (
        <Dot size={size} status={status} color={color} />
      ) : (
        <Star
          innerRadiusRatio={0.5}
          size={size}
          status={status}
          color={color}
        />
      )}
    </View>
  );
};
