import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import { Platform } from 'react-native';

export const launchCameraRollVideoPicker = () => {
  const options: ImagePicker.ImagePickerOptions = {
    mediaTypes: ImagePicker.MediaTypeOptions.Videos,
    quality: 1,
    allowsEditing: true,
    videoExportPreset: ImagePicker.VideoExportPreset.MediumQuality,
  };

  const _pickImage = async () => {
    if (Platform.OS === 'ios') {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }

    return ImagePicker.launchImageLibraryAsync(options);
  };

  return _pickImage();
};
