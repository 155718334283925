import { Machine } from 'xstate';

interface AuthContext {
  loginToken: string;
  authToken: string;
}

interface AuthStateSchema {
  states: {
    authToken: {
      states: {
        loading: {};
        loaded: {};
      };
    };
    loadUser: {
      states: {
        loading: {};
        loaded: {};
      };
    };
    authenticated: {};
    signIn: {};
    receiveLoginToken: {
      states: {
        idle: {};
        pending: {};
        // success: {},
      };
    };
    idle: {};
  };
}

type AuthEvent =
  | { type: 'RECEIVE_LOGIN_TOKEN'; data: string }
  | { type: 'SIGN_OUT' }
  | { type: 'LOADED_DATA'; user: any };

export const authMachine = Machine<AuthContext, AuthStateSchema, AuthEvent>({
  id: 'auth',
  initial: 'authToken',
  context: {
    loginToken: null,
    authToken: null,
  },
  states: {
    authToken: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'loadAuthToken',
            onDone: [
              {
                cond: 'blankToken',
                actions: 'cacheAuthToken',
                target: '#auth.loadUser',
              },
              {
                target: '#auth.signIn',
              },
            ],
            onError: {
              target: '#auth.signIn',
              actions: 'logError',
            },
          },
        },
        loaded: {},
      },
    },
    loadUser: {
      initial: 'loading',
      states: {
        loading: {
          on: {
            LOADED_DATA: {
              // TODO
              // target: 'loaded',
            },
          },
        },
        loaded: {},
      },
      entry: context => {
        // console.log(context);
      },
    },
    authenticated: {
      on: {
        SIGN_OUT: {
          target: 'authToken',
          // invoke: {
          //   src: 'signOut',
          // },
        },
      },
    },
    signIn: {
      entry: () => {
        console.log('signIn');
      },
      // entry: ['onSignIn'],
    },
    receiveLoginToken: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            '': {
              target: 'pending',
            },
          },
        },
        pending: {
          invoke: {
            src: 'consumeLoginToken',
            onDone: [
              {
                cond: 'blankToken',
                actions: ['cacheAuthToken', 'storeAuthToken'],
                target: '#auth.loadUser',
              },
              {
                target: '#auth.signIn',
              },
            ],
            onError: {
              actions: 'logError',
              target: '#auth.signIn',
            },
          },
        },
      },
    },
    idle: {},
  },
  on: {
    RECEIVE_LOGIN_TOKEN: {
      target: 'receiveLoginToken',
      actions: ['cacheLoginToken'],
      cond: 'blankToken',
    },
  },
});
