import React, { useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
import { RecordButton } from './RecordButton';
import { useKeepAwake } from 'expo-keep-awake';
import { colors, rhythm } from '../../designSystem';
import { launchCameraRollVideoPicker } from '../../services/cameraRoll';
import { CloseButtonX } from '../CloseButtonX';
import { UploadButton } from '../UploadButton';
import { useNavigation } from 'react-navigation-hooks';

interface VideoCaptureProps {
  send: any;
  current: any;
}

export const VideoCapture: React.FC<VideoCaptureProps> = ({
  send,
  current,
}) => {
  useKeepAwake(); // prevent device from sleeping while capturing video
  const camera = useRef<Camera>(null);
  const recording = current.matches({ capture: { ready: 'active' } });
  const { goBack } = useNavigation();

  const handleDismiss = () => {
    send('CANCEL_RECORD');
    goBack();
  };

  const handleCameraRollUpload = () => {
    launchCameraRollVideoPicker()
      .then(result => {
        if (!result.cancelled && result.uri) {
          send({ type: 'UPLOAD_ROLL_VIDEO', uri: result.uri });
        }
      })
      .catch(err => {
        throw new Error(err);
      });
  };
  const ControlButtons: React.FC = () => (
    <>
      <View style={styles.closeButtonContainer}>
        <CloseButtonX onPress={handleDismiss} />
      </View>
      {!recording && (
        <View style={styles.uploadButtonContainer}>
          <UploadButton onPress={handleCameraRollUpload} />
        </View>
      )}
      <View style={styles.recordButtonContainer}>
        <RecordButton
          disabled={!current.matches({ capture: 'ready' })}
          isActive={recording}
          onPress={() =>
            recording ? send('FINISH_RECORD') : send('PRESS_RECORD')
          }
        />
      </View>
    </>
  );

  return (
    <React.Fragment>
      <ControlButtons />
      <View style={styles.cameraContainer}>
        <Camera
          ref={camera}
          onCameraReady={() => send('CAMERA_READY', { camera: camera.current })}
          onMountError={error => send('FAIL', { error })}
          style={styles.camera}
          type={Camera.Constants.Type.front}
        />
      </View>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  cameraContainer: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.darkGrey,
  },
  camera: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    aspectRatio: 4 / 3,
  },
  // All these buttons z-index 1
  recordButtonContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 30,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  closeButtonContainer: {
    position: 'absolute',
    zIndex: 1,
    padding: rhythm[1],
  },
  uploadButtonContainer: {
    position: 'absolute',
    zIndex: 1,
    padding: rhythm[1],
    right: 30,
  },
});
