import React, { useState } from 'react';
import { useNavigation } from 'react-navigation-hooks';
import { HeaderBackButton } from 'react-navigation-stack';
import gql from 'graphql-tag';

import { Loading } from '../../components/Loading';
import { ChatView } from '../../components/chat/ChatView';
import { useStudentChatBasicInfoQuery } from '../../graphql/types';
import { ErrorMessage, MissingDataError } from '../../components/ErrorMessage';
import { LowTicketUpsellInterstitial } from '../../components/LowTicketUpsell';
import { NavigationScreenType } from '../../utils/navigationUtils';
import { useOnFocus } from '../../hooks/useOnFocus';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useOnBlur } from '../../hooks/useOnBlur';
import { isRelationshipActive } from '../../utils/dateUtils';
import { trackEvent } from '../../utils/analytics';
import { dbUserToChatUser } from '../../utils/chat/mappings';
import { ChatUserType } from '../../utils/chat/types';
import { ExerciseViewButton } from '../../components/ExerciseViewButton';

export const CHAT_STUDENT_BASIC_QUERY = gql`
  query StudentChatBasicInfo {
    user {
      id
      student {
        id
        studentType
      }
    }

    studentMentorRelationships {
      id
      endedAt
      mentor {
        id
        user {
          id
          firstName
          lastName
          fullName @client
        }
      }
      student {
        id
        user {
          id
          firstName
          lastName
        }
      }
      messageThread {
        id
      }
    }
  }
`;

export const ChatScreen: NavigationScreenType = () => {
  const { getParam, isFocused: navIsFocused } = useNavigation();
  const [isFocused, setIsFocused] = useState(true);
  const forceRerender = useForceRerender();
  const { data, loading, error } = useStudentChatBasicInfoQuery();

  useOnFocus(() => {
    forceRerender();
    setIsFocused(true);
  });
  useOnBlur(() => setIsFocused(false));

  if (!isFocused || !navIsFocused()) {
    return null;
  }

  /**
   * Check data is valid & ready for processing
   */

  if (loading) {
    // console.log('chat screen basic loading', data);
    return <Loading />;
  }
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.user || !data.user.student)
    return <MissingDataError type="Base Chat" />;
  // Low ticket students can't access chat screen
  if (data.user.student.studentType === 'LT')
    return <LowTicketUpsellInterstitial />;
  if (!data.studentMentorRelationships || !data.studentMentorRelationships[0])
    return <MissingDataError type="Student/Mentor Relationship" />;

  const currRelationship = data.studentMentorRelationships[0];
  if (!currRelationship.messageThread)
    throw new Error('no relationship message');

  const mainThreadId = parseInt(currRelationship.messageThread.id);
  const currentThreadId = parseInt(getParam('currentThreadId', mainThreadId));
  const chatUser: ChatUserType = dbUserToChatUser(
    currRelationship.student.user
  );
  return (
    <ChatView
      relationshipId={+currRelationship.id}
      userRole="student"
      chatUser={chatUser}
      mainThreadId={mainThreadId}
      currentThreadId={currentThreadId}
      isRelationshipExpired={!isRelationshipActive(currRelationship)}
    />
  );
};

ChatScreen.navigationOptions = ({
  navigation: { navigate, getParam, setParams },
  screenProps,
}) => {
  const { mentorName, mainThreadId } = screenProps;
  const subject = getParam('subject', '');
  const exerciseId = getParam('exerciseId', -1);
  const currentThreadId = parseInt(getParam('currentThreadId', mainThreadId));
  const title = subject.length > 0 ? subject : mentorName;
  const isMainThread = currentThreadId === mainThreadId;

  const goBack = isMainThread
    ? undefined
    : () =>
        setParams({
          currentThreadId: mainThreadId,
          subject: '',
        });
  const hasExercise = !isMainThread && parseInt(exerciseId) > 0;

  return {
    title,
    headerLeft: goBack && (
      <HeaderBackButton onPress={goBack} tintColor={'white'} />
    ),
    headerRight: hasExercise && (
      <ExerciseViewButton
        onPress={() => {
          trackEvent('student-preview-exercise', { exerciseId });
          navigate('ExercisePreviewScreen', { exerciseId, subject });
        }}
      />
    ),
  };
};
