interface Strings {
  [key: string]: Strings | string;
}
export const GenericStrings = {
  noSubject: 'No Subject',
};

export const SignInStrings = {
  welcomeTo: 'Welcome to',
  GuitarMasteryIntensive: 'Guitar Mastery Intensive',
  emailLabel: 'Email:',
  placeHolderEmail: 'jimi@hendrix.com',
  passwordLabel: 'Password:',
  forgotPasswordButton: 'Forgot/Reset Password',
  sendMagicLinkButton: 'Or, email a "magic link" to sign in',
  setPassword: 'Set Password',
  password: 'Password',
  setPasswordFor: 'Set your new password for',
  loginIncorrectError: 'Email/Password incorrect',
  pleaseWait: 'Please Wait…',
  signIn: 'Sign in',
};

export const SessionCreatorScreenStrings = {
  hero: 'How long can you practice for today?',
  subtitle: 'We recommend an hour minimum',
  startButton: 'Start session',
  cancelButton: 'Cancel',
};

export const StudentHomeScreenStrings = {
  PracticeSessionWidget: {
    titleInProgress: "Resume today's practice session",
    titleCompleted: 'Finished practicing - good job!',
    techniques: 'Techniques',
    reviewItems: 'Review Items',
    button: 'Start Practicing',
  },
  Streak: {
    title: 'Practice Streak',
  },
};

export const LessonScreenStrings = {
  exerciseList: {
    title: 'Exercises',
    required: 'Required',
    optional: 'Optional',
  },
  button: {
    nextLesson: 'Next Lesson',
  },
  supplementary: {
    title: 'Supplementary Materials',
    linkText: 'Click here for contextual examples',
  },
};

export const ExerciseScreenStrings = {
  Button: {
    reviewFlow: 'Mark Reviewed',
    techniqueFlow: 'Done',
  },
};

export const RecordVideoScreenStrings = {
  ReviewVideo: {
    sendToMentorButton: 'Send',
    sendToMentorButtonUploading: 'Uploading…',
  },
  VideoUploadError: {
    title: 'Upload error',
    subtitle: 'Something went wrong uploading your video',
    buttonRetry: 'Try again',
    buttonCancel: 'Back to Lesson',
  },
  MountError: {
    title: 'Camera could not be started',
    subtitle: 'Taking you back to the lesson',
    button: 'OK',
  },
  PermissionDenied: {
    title: 'Camera permission denied',
    subtitle: 'We need it to record a video!',
    button: 'Back to the Lesson',
  },
};

export const CurriculumBrowser = {
  tabBarLabel: 'Lessons',
  navTitle: 'All Lessons',
};

export const LowTicket = {
  deniedSubtitle:
    'Group course students do not have access to mentorship. Get mentorship?',
  buttonText: 'I am interested in receiving mentorship',
};

export const SessionCompleteScreen = {
  title: 'Congratulations!',
  subtitle: 'Finished practicing',
  buttonText: 'Done',
};

export const PracticeReminderNotification = {
  title: 'Time to practice!',
  body: 'Practice makes perfect',
};

export const ChatScreenStrings = {
  videoLoading: 'Loading exercise video submissions.',
  noExercises: 'No exercise video submissions yet.',
  studentSubmission: 'Student Submission',
};
