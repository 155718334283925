import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { Item } from 'react-navigation-header-buttons';
import { Feather } from '@expo/vector-icons';
import gql from 'graphql-tag';
import { ChatScreen } from '../screens/student/ChatScreen';
import { ExerciseRatingScreen } from '../screens/student/ExerciseRatingScreen';
import { ExercisePreviewScreen } from '../screens/ExercisePreviewScreen';
import { ExerciseScreen } from '../screens/student/ExerciseScreen';
import { LessonGroupScreen } from '../screens/student/LessonGroupScreen';
import { LessonScreen } from '../screens/student/LessonScreen';
import { LessonsScreen } from '../screens/student/LessonsScreen';
import { LessonIntroScreen } from '../screens/student/LessonIntroScreen';
import { PracticeSessionScreen } from '../screens/student/PracticeSessionScreen';
import { PrivacyPolicy } from '../screens/PrivacyPolicy';
import { RecordVideoScreen } from '../screens/student/RecordVideoScreen';
import { RecordThreadVideoScreen } from '../screens/student/RecordThreadVideoScreen';
import { SessionCompleteScreen } from '../screens/student/SessionCompleteScreen';
import { SessionCreatorScreen } from '../screens/student/SessionCreatorScreen';
import { SettingsScreen } from '../screens/student/SettingsScreen';
import { StudentHomeScreen } from '../screens/student/StudentHomeScreen';
import { WebViewFullScreen } from '../screens/WebViewFullScreen';

import {
  fonts,
  fontSize,
  headerOptions,
  fullScreenCard,
  rhythm,
} from '../designSystem';
import { CurriculumBrowser } from '../strings';
import FontAwesomeHeaderButtons from '../components/HeaderButton';
import { Loading } from '../components/Loading';
import { ErrorMessage, MissingDataError } from '../components/ErrorMessage';
import { TabIcon } from '../components/TabIcon';
import { userInfo, studentTabRelationship } from '../graphql/fragments';
import {
  useStudentTabQuery,
  useStudentTabUnreadPollQuery,
} from '../graphql/types';
import { openFacebookGMICommunity } from '../services/facebook';

export const PracticeSessionStack = createStackNavigator(
  {
    SessionCreatorScreen: {
      screen: SessionCreatorScreen,
      navigationOptions: {
        header: null,
      },
    },
    PracticeSessionScreen: {
      screen: PracticeSessionScreen,
      navigationOptions: {
        header: null,
      },
    },
    LessonScreen: {
      screen: LessonScreen,
      // navigationOptions: {},
    },
    LessonIntroScreen,
    WebViewFullScreen,
    ExerciseScreen: {
      screen: ExerciseScreen,
      navigationOptions: {
        header: null,
      },
    },
    ExerciseRatingScreen: {
      screen: ExerciseRatingScreen,
      navigationOptions: {
        header: null,
      },
    },
    RecordVideoScreen: {
      screen: RecordVideoScreen,
      navigationOptions: {
        header: null,
      },
    },
    SessionCompleteScreen,
  },
  {
    defaultNavigationOptions: {
      ...headerOptions,
      gesturesEnabled: false,
    },
    cardStyle: fullScreenCard,
  }
);

const LessonBrowserStack = createStackNavigator(
  {
    LessonsScreen,
    LessonGroupScreen,
    LessonScreen: {
      screen: LessonScreen,
      params: {
        // So that exercise view/recording UX can appropriately adjust
        isLessonBrowser: true,
      },
    },
    LessonIntroScreen,
    WebViewFullScreen,
    ExerciseScreen: {
      screen: ExerciseScreen,
      params: {
        // So that exercise view/recording UX can appropriately adjust
        isLessonBrowser: true,
      },
    },
    // TODO: remove duplicate from stack, and unify navigators
    // 2020-03-10 For now this is necessary so student can
    // submit video from Lesson Browser and be able to goBack
    RecordVideoScreen: {
      screen: RecordVideoScreen,
      navigationOptions: {
        header: null,
      },
    },
  },
  {
    initialRouteName: 'LessonsScreen',
    defaultNavigationOptions: {
      ...headerOptions,
      gesturesEnabled: false,
    },
    cardStyle: fullScreenCard,
  }
);

const ChatStack = createStackNavigator(
  {
    ChatScreen,
    ExercisePreviewScreen,
    RecordThreadVideoScreen: {
      screen: RecordThreadVideoScreen,
      navigationOptions: {
        header: null,
      },
    },
    /** 2020-04-29 adding this temporarily to support
     * using this for ad-hoc submissions
     */
    RecordVideoScreen: {
      screen: RecordVideoScreen,
      navigationOptions: {
        header: null,
      },
    },
  },
  {
    defaultNavigationOptions: headerOptions,
    cardStyle: fullScreenCard,
  }
);

const HomeStack = createStackNavigator(
  {
    StudentHomeScreen: {
      screen: StudentHomeScreen,
      navigationOptions: ({ navigation }) => ({
        title: 'Guitar Mastery Intensive',

        headerRight: (
          <FontAwesomeHeaderButtons>
            <Item
              title="Community"
              iconName="facebook-square"
              buttonStyle={{ color: 'white' }}
              onPress={openFacebookGMICommunity}
            />
            <Item
              title="settings"
              iconName="gear"
              buttonStyle={{ color: 'white' }}
              onPress={() => navigation.navigate('Settings')}
            />
          </FontAwesomeHeaderButtons>
        ),
      }),
    },
    Settings: {
      screen: SettingsScreen,
      navigationOptions: {
        title: 'Settings',
      },
    },
    Privacy: {
      screen: PrivacyPolicy,
      navigationOptions: {
        title: 'Privacy Policy',
      },
    },
  },
  {
    defaultNavigationOptions: headerOptions,
  }
);

let studentTabRoutes = {
  // StorybookScreen,
  Home: {
    screen: HomeStack,
    navigationOptions: {
      title: 'Practice',
      tabBarIcon: ({ tintIcon, focused, tintColor }) => {
        return (
          <TabIcon icon={<Feather name="home" size={24} color={tintColor} />} />
        );
      },
    },
  },
  // PracticeSessionStack,
  // Storybook: StorybookScreen,
  [CurriculumBrowser.tabBarLabel]: {
    screen: LessonBrowserStack,
    tabBarLabel: CurriculumBrowser.tabBarLabel,
    navigationOptions: {
      title: 'All Lessons',
      tabBarIcon: ({ tintIcon, focused, tintColor }) => {
        return (
          <TabIcon icon={<Feather name="list" size={24} color={tintColor} />} />
        );
      },
    },
  },
};

// TODO: this should all be encapsulated somewhere…but where?

const highTicketRoutes = {
  Mentor: {
    screen: ChatStack,
    navigationOptions: ({ screenProps }) => ({
      tabBarIcon: ({ tintIcon, focused, tintColor }) => {
        return (
          <TabIcon
            icon={<Feather name="message-circle" size={24} color={tintColor} />}
            badgeVisible={screenProps.mentorNotifications}
          />
        );
      },
    }),
  },
};

const studentTabs = createBottomTabNavigator(
  { ...studentTabRoutes, ...highTicketRoutes },
  {
    tabBarOptions: {
      keyboardHidesTabBar: !(Platform.OS === 'ios'),
      labelStyle: {
        ...fonts.regular,
        fontSize: fontSize[0],
      },
      style: {
        height: rhythm[5],
      },
    },
  }
);

const HighTicketStudentStack = createStackNavigator(
  {
    StudentTabs: studentTabs,
    PracticeSessionStack,
  },
  {
    headerMode: 'none',
    mode: 'modal',
    initialRouteName: 'StudentTabs',
    cardStyle: fullScreenCard,
  }
);

export const STUDENT_TAB_UNREAD_POLL = gql`
  query StudentTabUnreadPoll {
    studentMentorRelationships {
      id
      unreadCount
    }
  }
`;
export const STUDENT_TAB_QUERY = gql`
  query StudentTab {
    user {
      id
      student {
        id
        studentType
      }
    }

    studentMentorRelationships {
      ...StudentTabRelationship
    }
  }
  ${studentTabRelationship}
  ${userInfo}
`;

import { Notification } from 'expo/build/Notifications/Notifications.types';
import { Notifications } from 'expo';
import { showMessage } from 'react-native-flash-message';
import {
  PRACTICE_REMINDER_CATEGORY,
  PRACTICE_REMINDER,
} from '../utils/pushNotifications';
import { trackError } from '../utils/analytics';

export const StudentNavigator = ({ navigation, screenProps }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [relationshipInfo, setRelationshipInfo] = useState<{
    mentorName: string;
    mainThreadId: number | null;
  }>({
    mentorName: '',
    mainThreadId: null,
  });
  useEffect(() => {
    const subscription = Notifications.addListener(handleNotification);

    return () => {
      // console.log('unmount StudentNavigator');
      subscription.remove();
    };
  }, []);

  const { data, loading, error, refetch } = useStudentTabQuery({
    errorPolicy: 'all',
  });
  const unreadPoll = useStudentTabUnreadPollQuery({ pollInterval: 5 * 1000 });

  const handleNotification = (notification: Notification) => {
    const isMessage =
      notification.data.type === 'message' ||
      notification.data.type === 'thread';
    const notificationThreadId = notification.data.messageThreadId;
    const openMessage = () =>
      navigation.navigate('ChatScreen', {
        currentThreadId: notificationThreadId,
      });

    switch (notification.origin) {
      case 'received': {
        // received whilst app is foregrounded
        refetch();
        if (isMessage) {
          const senderName = notification.data.senderFullName || 'mentor';
          // Delay notifications to give the message a chance to load in thread poll
          setTimeout(
            () =>
              showMessage({
                message: `New message from ${senderName}`,
                type: 'default',
                onLongPress: openMessage,
                onPress: openMessage,
              }),
            3 * 1000
          );
        }
        return;
      }
      case 'selected': {
        // selected from system UI
        if (
          notification.data.category ===
          `GMI:${PRACTICE_REMINDER_CATEGORY}/${PRACTICE_REMINDER}`
        ) {
          return navigation.navigate('SessionCreatorScreen');
        } else if (isMessage) {
          openMessage();
        }

        return;
      }
    }
  };

  useEffect(() => {
    if (
      data &&
      data.studentMentorRelationships &&
      data.studentMentorRelationships[0]
    ) {
      const dbRelationship = data.studentMentorRelationships[0];
      const mentorChatThread = dbRelationship.messageThread;
      if (!mentorChatThread) throw new Error('empty mentor chat thread');

      const mainThreadId = parseInt(mentorChatThread.id);
      const mentorName = mentorChatThread.mentor.user.fullName;
      setRelationshipInfo({ mainThreadId, mentorName });
    }
  }, [data]);

  useEffect(() => {
    if (
      unreadPoll.data &&
      unreadPoll.data.studentMentorRelationships &&
      unreadPoll.data.studentMentorRelationships[0]
    ) {
      const unread = unreadPoll.data.studentMentorRelationships[0].unreadCount;

      if (unread !== null && unread !== undefined) setUnreadCount(unread);
    }
  }, [unreadPoll.data]);

  if (loading) return <Loading />;
  if (error) {
    if (!data) return <ErrorMessage error={error} />;
    else trackError(error);
  }

  if (!data || !data.studentMentorRelationships || !data.user)
    return <MissingDataError type="Student Tab" />;

  return (
    <HighTicketStudentStack
      navigation={navigation}
      screenProps={{
        ...screenProps,
        mainThreadId: relationshipInfo.mainThreadId,
        mentorName: relationshipInfo.mentorName,
        mentorNotifications: unreadCount > 0,
      }}
    />
  );
};

StudentNavigator.router = HighTicketStudentStack.router;
