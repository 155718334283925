import { useLayoutEffect } from 'react';
import { useNavigation } from 'react-navigation-hooks';

export const useOnBlur = (fn: () => void) => {
  const { addListener } = useNavigation();
  useLayoutEffect(() => {
    // Note: `willBlur` seems to work cross-platform (not `DidBlur`)
    // Also, this may not fire on the initial screen of the navigator
    // known bug https://github.com/react-navigation/react-navigation/issues/3446
    // This is fixed in later versions and may require upgrade to v4 or v5
    const listener = addListener('willBlur', () => {
      return fn();
    });
    return () => {
      return listener.remove();
    };
  }, [fn]);
};
