import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';
import { colors, rhythm } from '../designSystem';

interface ProgressProps {
  progress: number;
  min?: number;
  style?: StyleProp<ViewStyle>;
  barStyle?: StyleProp<ViewStyle>;
}

const makeWidth = (pct: number, min: number) => `${Math.max(min, pct * 100)}%`;

export const Progress: React.FC<ProgressProps> = ({
  min = 10,
  progress,
  style,
  barStyle,
}) => {
  return (
    <View style={[styles.container, style]}>
      <View
        style={[styles.bar, { width: makeWidth(progress, min) }, barStyle]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: colors.accentMuted,
    height: rhythm[1],
  },
  bar: {
    backgroundColor: colors.accent,
    minWidth: rhythm[2],
  },
});
