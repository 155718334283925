import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors, fonts, fontSize } from '../designSystem';
import { ApolloError } from 'apollo-client';
import { Button } from './Button';
import { useSignOut } from '../hooks/useSignOut';
import { trackEvent, trackError } from '../utils/analytics';

type BasicError = { name: string; message: string };
interface ErrorProps {
  error: ApolloError | BasicError;
  /**
   * Use any string for a unique identifier making lookup easier
   */
  key?: string;
}
interface MissingDataProps {
  /**
   * Use any string to indicate the screen or location the error happened
   */
  type: string;
}

export function missingDataError(area: string): BasicError {
  return {
    name: 'Missing Data',
    message: `Query from ${area} did not receive required data`,
  };
}

export const MissingDataError: React.FC<MissingDataProps> = ({ type }) => (
  <ErrorMessage error={missingDataError(type)} />
);

export const ErrorMessage: React.FC<ErrorProps> = ({ error, key = '' }) => {
  const visibleError = { ...error, message: `VIS:${key} ${error.message}` };
  trackError(visibleError);
  trackEvent('fatal-error-screen', error);

  const { signOut } = useSignOut();
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{error.name}</Text>
      <Text style={styles.text}>{error.message}</Text>
      <View style={{ padding: 20 }}>
        <Button
          onPress={() => {
            trackEvent('click-error-sign-out');
            signOut();
          }}
          title={'Click to sign out'}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightGrey,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ...fonts.regular,
    fontSize: fontSize[0],
    color: colors.darkGrey,
  },
});
