import React, { memo, useEffect, useState } from 'react';
import { StyleSheet, View, Text, ViewStyle } from 'react-native';
import {
  format,
  isSameDay,
  isToday,
  subWeeks,
  startOfWeek,
  getDayOfYear,
  setDayOfYear,
  addWeeks,
  endOfWeek,
  eachDay,
  getDay,
  startOfToday,
} from 'date-fns';
import times from 'ramda/src/times';
import any from 'ramda/src/any';
import range from 'ramda/src/range';
import splitEvery from 'ramda/src/splitEvery';
import { colors, fonts, fontSize } from '../designSystem';
import { Dot } from './Badge';
import { byPlatform } from '../utils/platform';

const SMALL_DOT = 8;
const LARGE_DOT = 24;

const BlankDay = () => (
  <View style={[styles.day, styles.dayBlank]}>
    <Dot size={SMALL_DOT} status="completed" color={colors.accentMuted} />
  </View>
);

interface DayProps {
  isToday?: boolean;
  practicedToday?: boolean;
}
const Day: React.FC<DayProps> = ({
  practicedToday = false,
  isToday = false,
}) => {
  return (
    <View style={[styles.day, isToday && styles.dayToday]}>
      {practicedToday ? (
        <Dot
          size={LARGE_DOT}
          status="completed"
          color={isToday ? colors.accent : colors.white}
        />
      ) : (
        <Dot
          size={SMALL_DOT}
          status="completed"
          color={isToday ? colors.accent : colors.accentMuted}
        />
      )}
    </View>
  );
};

export interface PracticeRecord {
  id: string;
  createdAt: Date;
}

const practicedOnDate = (dates: Date[], date: Date): boolean =>
  any(_date => isSameDay(_date, date), dates);

interface CalendarProps {
  lookbackWeeks?: number;
  lookaheadWeeks?: number;
  records: PracticeRecord[];
}

const _CalendarHeatmap: React.FC<CalendarProps> = ({
  records,
  lookbackWeeks = 3,
  lookaheadWeeks = 0,
}) => {
  const [slots, setSlots] = useState<JSX.Element[]>(
    times(d => <BlankDay key={`blank-${d}`} />, 7 * 4)
  );
  const selectedDate = startOfToday();

  useEffect(() => {
    const recordDates = records.map(r => r.createdAt);

    const firstWeek = subWeeks(selectedDate, lookbackWeeks);
    const startOfCalendar = startOfWeek(firstWeek);
    let startDayOfYearOfCalendar = getDayOfYear(startOfCalendar);
    const todayDayOfWeek = getDay(selectedDate);
    const todayDayOfYear = getDayOfYear(selectedDate);

    const endWeek =
      todayDayOfWeek >= 3
        ? addWeeks(selectedDate, lookaheadWeeks)
        : selectedDate;
    const endOfCalendar = endOfWeek(endWeek);
    const endDayOfYearOfCalendar = getDayOfYear(endOfCalendar);
    const blankDiff = endDayOfYearOfCalendar - todayDayOfYear;
    const blanks = times(d => <BlankDay key={`blank-${d}`} />, blankDiff);
    // startDiff needed to be set to 3 for the first few weeks of 2020, but then caused problems.
    // We'll have a new cal by 2021, otherwise FIX THIS.
    const startDiff = 0;
    const startBlanks = times(d => <BlankDay key={`blank-${d}`} />, startDiff);
    if (startDayOfYearOfCalendar > todayDayOfYear) {
      startDayOfYearOfCalendar = 1;
    }
    const monthDayRange = range(
      startDayOfYearOfCalendar,
      todayDayOfYear + 1
    ).map(d => setDayOfYear(selectedDate, d));

    const monthDays = monthDayRange.map((date, d) => {
      const practicedToday = practicedOnDate(recordDates, date);

      return (
        <Day
          key={`day-${d}`}
          isToday={isToday(date)}
          practicedToday={practicedToday}
        />
      );
    });

    const totalSlots = [...startBlanks, ...monthDays, ...blanks];

    setSlots(totalSlots);
  }, [records, lookbackWeeks, lookaheadWeeks]);

  const shortWeekdays = eachDay(
    startOfWeek(selectedDate),
    endOfWeek(selectedDate)
  ).map(d => format(d, 'dd'));

  const header = (
    <View style={{ flexDirection: 'row' }}>
      {shortWeekdays.map(d => (
        <View key={d} style={styles.day}>
          <Text style={[styles.dayName]}>{d}</Text>
        </View>
      ))}
    </View>
  );

  const weeks = splitEvery(7, slots).map((week, i) => (
    <View key={`week-${i}`} style={{ flexDirection: 'row' }}>
      {week}
    </View>
  ));

  return (
    <View>
      {header}
      {weeks}
    </View>
  );
};

const styles = StyleSheet.create({
  dayName: {
    ...fonts.regular,
    color: colors.white,
    fontSize: fontSize[0],
    textTransform: 'uppercase',
  },
  day: {
    width: byPlatform<ViewStyle['width']>({
      default: `${(1 / 7) * 100}%`,
      web: 36,
    }),
    height: byPlatform<ViewStyle['height']>({
      default: 'auto',
      web: 36,
    }),
    aspectRatio: 1,
    // margin: 2,
    backgroundColor: colors.accent,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayBlank: {},
  dayToday: {
    backgroundColor: colors.white,
  },
});

export const CalendarHeatmap = memo(_CalendarHeatmap);
