import React, { useRef, useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  LayoutChangeEvent,
  Dimensions,
  Platform,
} from 'react-native';
import { useMachine } from '@xstate/react';
import { Video as ExpoVideo } from 'expo-av';
import { videoMachine } from '../machines/videoMachine';
import { assign } from 'xstate';
import { AVPlaybackStatus } from 'expo-av/build/AV';
import { colors } from '../designSystem';
import { useOnBlur } from '../hooks/useOnBlur';
import { byPlatform } from '../utils/platform';

interface VideoProps {
  videoUri: string;
  posterUri: string;
}

export const Video: React.FC<VideoProps> = ({ videoUri, posterUri }) => {
  const videoRef = useRef<ExpoVideo>(null);

  useOnBlur(() => {
    send('PAUSE');
  });

  const [videoHeight, setVideoHeight] = useState<number>(0);
  const [updatingKey, setUpdatingKey] = useState<number>(0);

  const [current, send] = useMachine(videoMachine, {
    immediate: true,
    actions: {
      setVideo: assign({
        video: (_context, event) => event.video,
      }),
      playVideo: (context, event) => {
        if (context && context.video && context.video.playAsync)
          context.video.playAsync();
      },
      pauseVideo: (context, event) => {
        if (
          context &&
          context.video &&
          context.video.getStatusAsync &&
          context.video.pauseAsync
        ) {
          context.video.getStatusAsync().then(status => {
            if (status.isLoaded) context.video.pauseAsync();
          });
        }
      },
    },
  });

  const onError = (error: string) => send('FAIL', { error });

  const onLoad = (status: AVPlaybackStatus) => {
    send('LOADED', { video: videoRef.current, status });
  };

  const onLayout: (e: LayoutChangeEvent) => void = e => {
    // enforce aspect ratio on the container
    setVideoHeight((e.nativeEvent.layout.width * 9) / 16);
  };

  /**
   * This is needed to prevent a bug where onLayout doesn't
   * happen when app is started in portrait. 🤷‍♂️
   */
  const forceViewLayout = () => {
    // ok ladies and gents, this is a weird one, but tolerate it for now.
    // we have to change the updatingKey on mobile in case rotation changes
    // but on Web that kills the ability to full screen because the component
    // gets confused if you change its key during full screen-ifying.
    // So we only update the key for mobile, where it's needed
    if (Platform.OS !== 'web') setUpdatingKey(k => k + 1);
  };
  Dimensions.addEventListener('change', forceViewLayout);
  useEffect(() => {
    return () => Dimensions.removeEventListener('change', forceViewLayout);
  });

  const heightByPlatform = byPlatform<string | number>({
    web: videoHeight,
    mobile: '100%',
  });

  return (
    <View
      style={{
        ...styles.container,
        height: heightByPlatform,
        maxHeight: heightByPlatform,
      }}
      key={`${updatingKey}-usethistoforcelayout`}
      onLayout={onLayout}
    >
      {current.matches('loading') ||
        (current.matches({ ready: 'idle' }) && (
          <TouchableOpacity onPress={() => send('PLAY')}>
            <Image source={{ uri: posterUri }} style={styles.poster} />
          </TouchableOpacity>
        ))}

      <ExpoVideo
        ref={videoRef}
        source={{ uri: videoUri }}
        isLooping={false}
        useNativeControls={true}
        onError={onError}
        onLoad={onLoad}
        style={styles.video}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    aspectRatio: 16 / 9,
    flex: 1,
    backgroundColor: colors.accentMuted,
    alignItems: 'center',
  },
  poster: {
    flex: 1,
    aspectRatio: 16 / 9,
  },
  video: {
    flex: 1,
    width: byPlatform({
      web: '100%',
      mobile: 'auto',
    }),
    aspectRatio: 16 / 9,
  },
});
