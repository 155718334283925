import React from 'react';
import { Platform, Text, ScrollView } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { SectionHeader } from '../../components/SectionHeader';
import { Loading } from '../../components/Loading';
import { ErrorMessage } from '../../components/ErrorMessage';

import { SimpleRow } from '../../components/Rows';
import { useCurrentUserQuery } from '../../graphql/types';

import { Linking } from 'expo';
import Constants from 'expo-constants';
import { colors, rhythm } from '../../designSystem';
import { useSignOut } from '../../hooks/useSignOut';
import { trackEvent } from '../../utils/analytics';
export const SettingsScreen: React.FC = () => {
  const { navigate } = useNavigation();

  const { signOut } = useSignOut();
  const { data, error, loading } = useCurrentUserQuery();

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  // const { data, loading, error, client } = useSettingsQuery();
  // const [datePickerVisible, setDatePickerVisible] = useState(false);

  // const [updatePracticeReminderSettings] = useUpdatePracticeRemindersMutation();

  const handleContactSupport = () => {
    trackEvent('click-support');
    Linking.openURL(
      `mailto:contact@guitarmasteryintensive.com?subject=Support [${Platform.OS}-${Constants.manifest.version}]`
    );
  };

  // const { chatNotifications, practiceReminder } = data.userSettings;

  // const date = new Date(practiceReminder.date) || roundTimeUpToNearestMinutes(new Date(), 15);

  // const updatePracticeReminderNotification = async (newReminder: any) => {
  //   const { userSettings: { practiceReminder } } = data;

  //   let mergedReminder = { ...practiceReminder, ...newReminder }

  //   updatePracticeReminderSettings({ variables: { ...mergedReminder }})

  //   let notificationId = null;
  //   try {
  //     if (mergedReminder.enabled) {
  //       notificationId = await scheduleNotification(mergedReminder.date) as string;
  //     } else {
  //       if (mergedReminder.notificationId) {
  //         await cancelScheduledNotification(mergedReminder.notificationId);
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   updatePracticeReminderSettings({ variables: { ...mergedReminder, notificationId }})
  // }

  // const onUpdatePracticeReminderEnabled = async (enabled: boolean) => {
  //   const fixedDate = futureDate(date);

  //   // console.log(distanceInWordsToNow(fixedDate))

  //   return updatePracticeReminderNotification({ enabled, date: fixedDate.toISOString() });
  // }

  // const onDateChange = async (newDate: Date) => {
  //   const fixedDate = futureDate(newDate);

  //   // console.log(distanceInWordsToNow(fixedDate))

  //   setDatePickerVisible(false);

  //   return updatePracticeReminderNotification({ date: fixedDate.toISOString() });
  // }

  // const onUpdateChatNotifications = async (enabled: boolean) => {
  //   client.writeData({
  //     data: assocPath(
  //       ['userSettings', 'chatNotifications'],
  //       enabled,
  //       data
  //     )
  //   })
  // }

  return (
    <ScrollView style={{ flex: 1, backgroundColor: colors.lightGrey }}>
      <Container style={{ flex: 1 }}>
        {/* <SectionHeader>Notifications</SectionHeader> */}
        {/* <SimpleRow title='Test notification' onPress={() => sendPracticeNotification()} /> */}
        {/* <ToggleRow
          title='Remind me to practice'
          value={practiceReminder.enabled}
          onValueChange={onUpdatePracticeReminderEnabled}
        /> */}
        {/* <SimpleRow
          title='Daily reminder schedule'
          subtitle={`Daily at ${format(date, 'h:mm A')}`}
          disabled={!practiceReminder.enabled}
          onPress={() => setDatePickerVisible(true)}
        /> */}
        {/* <DatePickerModal
          visible={datePickerVisible}
          date={date}
          minuteInterval={15}
          mode='time'
          onDateChange={onDateChange}
          onDismiss={() => setDatePickerVisible(false)}
        /> */}
        {/* <ToggleRow title='Chat notifications' value={chatNotifications} onValueChange={onUpdateChatNotifications} /> */}

        <SectionHeader>Guitar Mastery Intensive</SectionHeader>
        {/* TODO: how to contact support? */}
        {data && data.user && (
          <SimpleRow title={`Signed in as ${data.user.email}`} />
        )}
        <SimpleRow title="Contact Support" onPress={handleContactSupport} />
        <SimpleRow title="Privacy policy" onPress={() => navigate('Privacy')} />
        <Text style={{ marginBottom: rhythm[4] }}>
          v{Constants.manifest.version}
        </Text>

        <Button title="Sign out" onPress={signOut} />
      </Container>
    </ScrollView>
  );
};
