import { createStackNavigator } from 'react-navigation-stack';
import { SignInScreen } from '../screens/signedOut/SignInScreen';
import { headerOptions } from '../designSystem';
import { SetPasswordScreen } from '../screens/signedOut/SetPasswordScreen';
import { MagicLinkPendingScreen } from '../screens/signedOut/MagicLinkPendingScreen';

export const SignedOutNavigator = createStackNavigator(
  {
    SignIn: {
      screen: SignInScreen,
      navigationOptions: {
        title: 'Guitar Mastery Intensive - Sign In',
        headerLeft: null,
      },
    },
    SetPassword: {
      screen: SetPasswordScreen,
      navigationOptions: {
        title: 'Guitar Mastery Intensive - Set Password',
        headerLeft: null,
      },
    },
    MagicLinkPending: {
      screen: MagicLinkPendingScreen,
      navigationOptions: {
        title: 'Guitar Mastery Intensive - Magic Link',
      },
    },
  },
  {
    defaultNavigationOptions: headerOptions,
    mode: 'modal',
  }
);
