import React, { useMemo, useEffect, useContext } from 'react';
import { View } from 'react-native';

import { useNavigation } from 'react-navigation-hooks';
import { Loading } from '../Loading';
import { ChatThread, VideoCarousel } from '.';
import {
  useCreateMessageMutation,
  useChatThreadQuery,
  useChatAllThreadsQuery,
  useChatActivityQuery,
  ChatActivityQuery,
  MessageWithAttachmentsFragment,
} from '../../graphql/types';
import { ErrorMessage } from '../ErrorMessage';

import { NavigationScreenType } from '../../utils/navigationUtils';
import { useReadMessages } from '../../hooks/useReadMessages';

import { useMemoizedCarouselData } from '../../hooks/useMemoizedCarouselData';
import {
  createOnSend,
  ChatUserType,
  ChatUserRole,
  ChatThreadType,
  dbMessagesToChatMessages,
  dbThreadToChatThread,
} from '../../utils/chat';
import { hashMessageThread } from '../../utils/icanhashcheezeburger';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useOnFocus } from '../../hooks/useOnFocus';
import { ChatContext } from '../../services/ChatContext';
import { usePortraitLockExceptIpad } from '../../hooks/useMobileOrientationLock';
import { trackError } from '../../utils/analytics';

interface ChatViewProps {
  mainThreadId: number;
  currentThreadId: number;
  chatUser: ChatUserType;
  userRole: ChatUserRole;
  relationshipId: number;
  isRelationshipExpired?: boolean;
}

enum ChatUpdateStatus {
  Fail = 0,
  Success = 1,
}

export const ChatView: NavigationScreenType<ChatViewProps> = ({
  mainThreadId,
  currentThreadId,
  chatUser,
  userRole,
  relationshipId,
  isRelationshipExpired = false,
}) => {
  const { setParams, isFocused } = useNavigation();
  usePortraitLockExceptIpad({
    debugId: 'ChatView',
    onlyWhenFocused: true,
    isFocused: isFocused(),
  });
  const { lastFetchedTimeForThread, updateFetchedTimeForThread } = useContext(
    ChatContext
  );
  const lastFetched = lastFetchedTimeForThread(currentThreadId);

  const isMainThread = currentThreadId === mainThreadId;

  // console.log('ChatView Re-Render, lastFetchedTimeForThread=', lastFetched);

  const forceRerender = useForceRerender();

  const [createMessage] = useCreateMessageMutation({ ignoreResults: true });
  const chatQuery = useChatActivityQuery({
    variables: { since: lastFetched },
  });

  function processIncoming(data: ChatActivityQuery): ChatUpdateStatus {
    // console.log('PROCESSING data on chatactivity', data);
    let errorCount = 0;
    if (!currThread.updateQuery) {
      trackError(new Error('MessageType currThread not ready to update'));
      return ChatUpdateStatus.Fail;
    }

    if (data.chats && data.chats.chats) {
      data.chats.chats.forEach(incoming => {
        if (incoming === null || incoming === undefined || !incoming.__typename)
          return;
        switch (incoming.__typename) {
          case 'MessageThreadType':
            //  DON'T EVEN WORRY ABOUT THIS, BECAUSE THE MESSAGETHREAD
            // WON'T BE VALID UNTIL A NEW ATTACHMENT COMES THRU/MESSAGE COMPLETE
            // WHICH WILL COME IN ON THE MESSAGE TYPE :) WE WILL RUN
            // REFETCH ON ALLTHREADS AT THAT POINT TO GET THE GOOD STUFF.
            break;
          case 'MessageType':
            // if message is in this thread, update/add it.
            if (incoming.messageThreadId === currentThreadId) {
              currThread.updateQuery(prevData => {
                if (
                  !prevData ||
                  !prevData.messageThread ||
                  !prevData.messageThread.messages
                ) {
                  trackError(new Error('currThread prevData missing shape'));
                  errorCount++;
                  return prevData;
                }

                /**
                 * See if we can find the exact message already, with same id,
                 * for updating it.
                 */
                const idMatchIndex = prevData.messageThread.messages.findIndex(
                  m => m && m.id == incoming.id
                );

                /**
                 * See if this message matches a pending message on contents only,
                 * because sometimes these chat results come in before `sent` query
                 * is done processing.
                 */
                const contentMatchIndex = prevData.messageThread.messages.findIndex(
                  m =>
                    m && isNaN(Number(m.id)) && m.contents === incoming.contents
                );

                /**
                 * Defer to the id match if available
                 * But if the message matches on content, update it based on that
                 * Otherwise we'll create a new one
                 */
                const existingIndex =
                  idMatchIndex >= 0
                    ? idMatchIndex
                    : contentMatchIndex >= 0
                    ? contentMatchIndex
                    : -1;

                if (existingIndex >= 0) {
                  const existingMessage = prevData.messageThread.messages[
                    existingIndex
                  ] as MessageWithAttachmentsFragment;
                  prevData.messageThread.messages[existingIndex] = {
                    ...existingMessage,
                    // the only two fields that would change
                    complete: incoming.complete,
                    read: incoming.read,
                  };
                } else {
                  const newMessage: MessageWithAttachmentsFragment = {
                    __typename: 'MessageType',
                    id: incoming.id,
                    complete: incoming.complete,
                    contents: incoming.contents,
                    createdAt: incoming.createdAt,
                    read: incoming.read,
                    sender: incoming.sender,
                    attachments: incoming.attachments, // ??
                    // exercise leave out
                  } as MessageWithAttachmentsFragment;
                  prevData.messageThread.messages.push(newMessage);
                }
                return prevData;
              });
            } else {
              // if message is not in this thread, and IS complete, run allthreads
              allThreads.refetch();
            }

            break;
          case 'MessageAttachmentType':
            if (!currThread.data || !currThread.data.messageThread) {
              trackError(new Error('Attachment: no currThread data to update'));
              errorCount++;
              return;
            }

            const existingIndex =
              currThread.data.messageThread.messages &&
              currThread.data.messageThread.messages.findIndex(
                m => m && parseInt(m.id) === incoming.messageId
              );

            if (!!existingIndex && existingIndex >= 0) {
              // attachment is in this thread, update/add it.
              currThread.updateQuery(prevData => {
                if (
                  !prevData ||
                  !prevData.messageThread ||
                  !prevData.messageThread.messages
                ) {
                  trackError(
                    new Error(
                      'Attachment updateQuery: no currThread data to update'
                    )
                  );
                  errorCount++;
                  return prevData;
                }
                const message = prevData.messageThread.messages[
                  existingIndex
                ] as MessageWithAttachmentsFragment;
                const attachments = message.attachments;
                // replace attachment or add it

                prevData.messageThread.messages[existingIndex] = {
                  ...message,
                  attachments: [incoming],
                };
                return prevData;
              });
            } else {
              // if NOT in this thread, time to run allthreads
              // console.log('refetching allthreads cuz attachemtn changed');
              if (!!allThreads.refetch) allThreads.refetch();
            }
            break;
        }
      });
    }
    if (errorCount > 0) {
      trackError(new Error(`processIncoming had ${errorCount} errors`));
    }
    return errorCount === 0 ? ChatUpdateStatus.Success : ChatUpdateStatus.Fail;
  }

  const startChatPolling = () => {
    // console.log('refetch & start chat polling');
    chatQuery.refetch({ relationshipId, since: lastFetched });
    chatQuery.startPolling(1.9 * 1000); // double check this polls on correct since
  };

  useEffect(() => {
    if (lastFetched) {
      startChatPolling();
    }
  }, [lastFetched]);

  useEffect(() => {
    // console.log('chatactivity change', chatQuery);
    if (chatQuery.data && chatQuery.data.chats && chatQuery.data.chats.since) {
      const result = processIncoming(chatQuery.data);
      if (result === ChatUpdateStatus.Success)
        updateFetchedTimeForThread(currentThreadId, chatQuery.data.chats.since);
    } else {
      // console.log('no new data');
    }
  }, [chatQuery]);

  const currThread = useChatThreadQuery({
    variables: { threadId: currentThreadId },
  });
  const allThreads = useChatAllThreadsQuery({
    variables: {
      relationshipId,
      hasAttachments: true, // only include threads with attachments
    },
  });

  useEffect(() => {
    if (
      currThread.data &&
      !currThread.loading &&
      allThreads.data &&
      !allThreads.loading
    ) {
      // console.log('*** curthread all threads effect starting polling');
      startChatPolling();
    } else {
      chatQuery.stopPolling();
    }
  }, [currThread, allThreads]);

  useOnFocus(forceRerender);

  /**
   * Marks any unread messages in this thread as read
   */
  useReadMessages(currThread.data, userRole, [
    'ChatAllThreads',
    userRole === 'student' ? 'StudentTab' : 'MentorIndex',
  ]);

  /**
   * Actual carousel data
   */

  const carouselData = useMemoizedCarouselData(
    allThreads.data,
    mainThreadId,
    setParams
  );

  /**
   * For memoization of chat thread
   */
  const currThreadHash =
    currThread &&
    currThread.data &&
    hashMessageThread(currThread.data.messageThread);

  const memoizedThread = useMemo(() => {
    const simplified =
      (currThread &&
        currThread.data &&
        currThread.data.messageThread &&
        dbThreadToChatThread(
          currThread.data.messageThread,
          relationshipId,
          isMainThread
        )) ||
      ({} as ChatThreadType);

    return simplified;
  }, [currThreadHash]);

  /**
   * Sends message, adds to "pending", & forces re-render after send mutation is done
   */
  const onSend = useMemo(() => {
    // console.log('creating on send function');
    return createOnSend(
      createMessage,
      currThread,
      currentThreadId,
      chatUser,
      forceRerender
    );
  }, [createMessage, currentThreadId, forceRerender]);

  /** Avoid expensive re-renders if not in view */
  if (!isFocused()) return null;

  /**
   * Check data is valid & ready for processing
   */
  if (currThread.loading) return <Loading />;
  if (currThread.error) return <ErrorMessage error={currThread.error} />;

  // console.log('rendering with messages', memoizedThread);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ display: isMainThread ? 'flex' : 'none' }}>
        <VideoCarousel
          data={carouselData}
          isLoading={allThreads.loading}
          isAddThreadEnabled={userRole === 'student' && !isRelationshipExpired}
        />
      </View>
      <ChatThread
        messageThread={memoizedThread}
        chatUser={chatUser}
        onSend={onSend}
        isMessagingDisabled={isRelationshipExpired}
      />
    </View>
  );
};
