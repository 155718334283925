import React from 'react';
import { StyleSheet, Platform, StatusBar } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { colors, rhythm } from '../designSystem';
import { byPlatform } from '../utils/platform';

export enum FadePosition {
  'top',
  'bottom',
}

interface FadeProps {
  position?: FadePosition;
  padded?: boolean;
}

export const Fade: React.FC<FadeProps> = ({
  position = FadePosition.bottom,
  padded,
  children,
}) => {
  const isBottom = position === FadePosition.bottom;

  return (
    <LinearGradient
      colors={
        isBottom
          ? [colors.lightGreyTransparent, colors.lightGrey]
          : [colors.lightGrey, colors.lightGreyTransparent]
      }
      locations={isBottom ? [0, 0.5] : [0.5, 0]}
      style={[
        styles.container,
        padded && styles.padded,
        isBottom ? styles.bottom : styles.top,
      ]}
    >
      {children}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    flex: 1,
  },
  padded: {
    padding: rhythm[2],
  },
  top: {
    top: 0,
  },
  bottom: {
    bottom: byPlatform({
      web: 0,
      ios: 0,
      android: StatusBar.currentHeight < 50 ? 0 : StatusBar.currentHeight,
    }),
  },
});
