import { StyleSheet, Dimensions } from 'react-native';
import { colors, rhythm, fonts, fontSize } from '../../designSystem';
import { byPlatform } from '../../utils/platform';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  innerContainer: {
    flex: 1,
    alignSelf: 'center',
    width: Dimensions.get('screen').width < 640 ? '100%' : 640,
  },
  animation: {
    alignSelf: 'center',
    height: 250,
  },
  heroText: {
    ...fonts.bold,
    // fontWeight: 'bold',
    textAlign: 'center',
    fontSize: fontSize[2],
    color: colors.darkGrey,
  },
  heroTextLastLine: {
    marginBottom: rhythm[2],
  },
  subtitleText: {
    ...fonts.regular,
    textAlign: 'center',
    fontSize: fontSize[1],
    marginBottom: rhythm[2],
    color: colors.darkGrey,
  },
  bold: {
    ...fonts.bold,
  },
  label: {
    ...fonts.regular,
    color: colors.darkGrey,
    paddingHorizontal: rhythm[2],
    paddingVertical: rhythm[1],
  },
  input: {
    ...fonts.regular,
    fontSize: fontSize[1],
    color: colors.darkGrey,
    backgroundColor: colors.white,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    paddingVertical: rhythm[1],
    paddingHorizontal: rhythm[2],
    borderRadius: rhythm[0],
  },
  inputError: {
    borderColor: colors.red,
  },
  inputMessage: {
    borderColor: colors.defaultBlue,
  },
  inputMessageArea: {
    marginVertical: rhythm[1],
    paddingHorizontal: rhythm[2],
    paddingVertical: rhythm[1],
  },
  errorText: {
    color: colors.red,
    backgroundColor: colors.redMuted,
  },
  messageText: {
    color: colors.defaultBlue,
    backgroundColor: colors.defaultBlueMuted,
  },
});
