import React, { useState, useEffect } from 'react';
import { View, StatusBar, Modal } from 'react-native';
import { OrientationLock, lockAsync } from 'expo-screen-orientation';
import { useNavigation } from 'react-navigation-hooks';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';

import { VideoReview, UploadVideoFn } from './VideoReview';
import { VideoCapture } from './VideoCapture';
import { Interstitial } from '../Interstitial';
import { RecordVideoScreenStrings as S } from '../../strings';
import { cameraMachine, CameraContext } from '../../machines/cameraMachine';
import { useOnBlur } from '../../hooks/useOnBlur';
import { colors } from '../../designSystem';
import {
  asyncLockPortraitExceptIpad,
  asyncLockLandscapeExceptIpad,
} from '../../hooks/useMobileOrientationLock';

interface MobileVideoCameraProps {
  uploadVideo: UploadVideoFn;
  onUploadComplete?: () => void;
}

export const MobileVideoCamera: React.FC<MobileVideoCameraProps> = ({
  uploadVideo,
  onUploadComplete,
}) => {
  const { goBack, isFocused } = useNavigation();
  const [doShowVideo, setDoShowVideo] = useState(true);

  const [current, send] = useMachine(cameraMachine, {
    actions: {
      setCamera: assign<CameraContext>({
        camera: (_context, event) => event.camera,
      }),
    },
  });
  useEffect(() => {
    if (isFocused()) {
      asyncLockLandscapeExceptIpad();
    }
  }, [isFocused()]);

  useOnBlur(() => {
    setDoShowVideo(false);
    asyncLockPortraitExceptIpad();
  });

  const { videoUri } = current.context;

  const gotoCapture = () => {
    send('GOTO_CAPTURE');
  };

  // Makes the navigation transition away from camera a little easier on the eyes
  if (!doShowVideo)
    return <View style={{ flex: 1, backgroundColor: colors.darkGrey }} />;

  /* <Modal /> is used to prevent tab navigator from showing tabs */
  return (
    <Modal
      visible={true}
      animated={false}
      supportedOrientations={['portrait', 'landscape']}
    >
      <View style={{ flex: 1 }}>
        <StatusBar hidden />
        {current.matches({ requestPermissions: 'failure' }) && (
          <Interstitial
            title={S.PermissionDenied.title}
            subtitle={S.PermissionDenied.title}
            buttonText={S.PermissionDenied.title}
            onPress={goBack}
          />
        )}
        {current.matches('capture') ? (
          <VideoCapture current={current} send={send} />
        ) : current.matches('review') && !!videoUri ? (
          <VideoReview
            videoUri={videoUri}
            returnToCapture={gotoCapture}
            uploadVideo={uploadVideo}
            onUploadComplete={() => !!onUploadComplete && onUploadComplete()}
          />
        ) : null}
      </View>
    </Modal>
  );
};
