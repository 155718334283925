import React, { useState, useEffect } from 'react';
import { useNavigation } from 'react-navigation-hooks';
import gql from 'graphql-tag';

import { useStudentMentorRelationshipsQuery } from '../../graphql/types';
import { useCreateNewMessageThread } from '../../graphql/complexHooks';
import { Loading } from '../../components/Loading';
import { VideoRecorder } from '../../components/camera/VideoRecorder';
import { useReportProgress } from '../../hooks/useReportProgress';

// TODO: relationship id should just be saved in localstorage when user logs in
export const RECORD_VIDEO_SCREEN_RELATIONSHIP_QUERY = gql`
  query StudentMentorRelationships {
    studentMentorRelationships {
      id
    }
  }
`;

export const RecordVideoScreen = () => {
  const [messageThreadId, setMessageThreadId] = useState<number | null>(null);
  const { navigate, getParam } = useNavigation();
  const exerciseProgressId: number | null = getParam(
    'exerciseProgressId',
    null
  );
  const exerciseId = parseInt(getParam('exerciseId')); // may be NaN
  const exitScreen: string | null = getParam('exitScreen');
  const subject: string | null = getParam('threadSubject');

  const rating = parseInt(getParam('rating', null)); // may be NaN
  const segmentId = parseInt(getParam('segmentId', null)); //also maybe NaN

  if (!exitScreen) throw new Error('Required exit screen param');
  if (!subject) throw new Error('Record Submission: Subject required');
  if (!exerciseId)
    throw new Error('exercise Id required to be passed, pass -1 if no id');

  const createMessageThread = useCreateNewMessageThread();
  const { data } = useStudentMentorRelationshipsQuery();

  const { reportProgress } = useReportProgress();

  /**
   * When user navigates to this screen, create a new message thread
   * then pass it to the VideoRecorder component
   */

  useEffect(() => {
    if (
      messageThreadId === null &&
      data &&
      data.studentMentorRelationships &&
      data.studentMentorRelationships[0]
    ) {
      const relationshipId = parseInt(data.studentMentorRelationships[0].id);

      createMessageThread({
        relationshipId,
        subject,
        exerciseProgressId,
      }).then(threadId => setMessageThreadId(threadId));
    }
  }, [data, messageThreadId]);

  if (!messageThreadId) return <Loading />;

  const onExit = () => {
    const navigateAway = () => navigate(exitScreen);
    if (rating && segmentId && exerciseId >= 0) {
      /**
       * If this screen was navigated to with a rating & segment
       * to submit the progress report, run the reportProgress mutation
       */

      reportProgress({
        exerciseId,
        segmentId,
        rating,
      }).then(navigateAway);
    } else {
      navigateAway();
    }
  };

  return (
    <VideoRecorder
      messageThreadId={messageThreadId}
      exerciseId={exerciseId >= 0 ? exerciseId : undefined}
      onExit={onExit}
    />
  );
};
