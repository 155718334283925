import React from 'react';
import { StatusBar } from 'react-native';
import { ScrollView } from 'react-navigation';
import findIndex from 'ramda/src/findIndex';
import uniq from 'ramda/src/uniq';
import { useNavigation } from 'react-navigation-hooks';

import { rhythm } from '../designSystem';
import {
  usePracticeScreenExercisesQuery,
  LastSegmentExercisesFragment,
  PracticeScreenExerciseFragment,
} from '../graphql/types';
import { TechniqueListItem, getLessonsFromSegment } from '../dataLoader';
import { Button } from '../components/Button';
import { Container } from '../components/Container';
import { Progress } from '../components/SkinnyProgress';
import { ReviewExercisesList } from '../components/ReviewExercisesList';
import { TechniquesList } from '../components/TechniquesList';
import { Fade } from '../components/Fade';
import { ErrorMessage, MissingDataError } from './ErrorMessage';
import { Loading } from './Loading';

interface PracticeSessionViewProps {
  lastSegment: LastSegmentExercisesFragment;
}

export const PracticeSessionView: React.FC<PracticeSessionViewProps> = ({
  lastSegment,
}) => {
  const navigation = useNavigation();

  // 🤷‍♂️empty array backup is kinda ugly but protects from TS errors
  const newExerciseIds = lastSegment.newExerciseIds || [];
  const reviewExerciseIds = lastSegment.reviewExerciseIds || [];
  const completedExerciseIds = lastSegment.completedExerciseIds || [];

  const completedPercent =
    completedExerciseIds.length /
    (newExerciseIds.length + reviewExerciseIds.length);

  const allExerciseIds = uniq([...newExerciseIds, ...reviewExerciseIds]);

  const exercisesQ = usePracticeScreenExercisesQuery({
    variables: {
      ids: allExerciseIds,
    },
  });
  if (exercisesQ.loading) return <Loading />;
  if (exercisesQ.error && !exercisesQ.data)
    return <ErrorMessage error={exercisesQ.error} />;
  if (!exercisesQ.data || !exercisesQ.data.exercises)
    return <MissingDataError type="Practice Session View" />;
  const exerciseData = exercisesQ.data.exercises;
  const isExercise = (
    e: typeof exerciseData[0]
  ): e is PracticeScreenExerciseFragment => e !== null && e !== undefined;

  const exercises = exercisesQ.data.exercises.filter(isExercise);
  const [techniques, reviewItems] = getLessonsFromSegment(
    lastSegment,
    exercises
  );

  const handleCompleteTechnique = lessonId => {
    if (!lessonId) {
      return navigation.pop();
    }
    const techniqueIndex = findIndex<TechniqueListItem>(
      item => item.type === 'lesson' && item.lesson.id === lessonId
    )(techniques);

    if (completedPercent >= 1) {
      return navigation.navigate('SessionCompleteScreen');
    } else if (techniqueIndex >= techniques.length - 1) {
      return navigation.pop();
    } else {
      const nextLesson = techniques[techniqueIndex + 1];
      navigation.pop();

      if (nextLesson.type === 'lessonIntro') {
        return navigation.navigate('LessonIntroScreen', {
          lessonGroupVideos: JSON.stringify(nextLesson.videos),
        });
      } else {
        return navigation.navigate('LessonScreen', {
          lessonId: nextLesson.lesson.id,
          lessonGroupTitle: nextLesson.lessonGroupTitle,
          moduleTitle: nextLesson.moduleTitle,
          onComplete: handleCompleteTechnique,
        });
      }
    }
  };

  return (
    <>
      <Progress progress={completedPercent} style={{ height: 24 }} />
      <ScrollView>
        <Container
          style={{
            paddingVertical: rhythm[4],
            paddingBottom: rhythm[4] + (StatusBar.currentHeight || 0),
          }}
        >
          {lastSegment && (
            <>
              <ReviewExercisesList reviewExercises={reviewItems} />
              <TechniquesList
                techniques={techniques}
                onCompleteItem={handleCompleteTechnique}
              />
            </>
          )}
        </Container>
      </ScrollView>
      <Fade padded>
        <Button
          title="Complete session"
          onPress={() => navigation.navigate('SessionCompleteScreen')}
        />
      </Fade>
    </>
  );
};
