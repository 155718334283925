import React from 'react';
import { View } from 'react-native';
import {
  HeaderButtons,
  HeaderButton as HB,
  Item,
} from 'react-navigation-header-buttons';
import { FontAwesome } from '@expo/vector-icons';
// import HeaderButtons, { HeaderButton } from 'react-navigation-header-buttons';

export const HeaderButton = passMeFurther => (
  // the `passMeFurther` variable here contains props from <Item .../> as well as <HeaderButtons ... />
  // and it is important to pass those props to `HeaderButton`
  // then you may add some information like icon size or color (if you use icons)
  <HB
    {...passMeFurther}
    IconComponent={FontAwesome}
    iconSize={23}
    color="blue"
  />
);

// FontAwesomeHeaderButtons.js
/**
 * @flow
 */

// See https://github.com/vonovak/react-navigation-header-buttons#how-to-integrate-in-your-project.

const SolidIcon = ({ style, ...otherProps }) => (
  <View style={style}>
    <FontAwesome type="solid" {...otherProps} />
  </View>
);

const RegularIcon = ({ style, ...otherProps }) => (
  <View style={style}>
    <FontAwesome type="regular" {...otherProps} />
  </View>
);

const LightIcon = ({ style, ...otherProps }) => (
  <View style={style}>
    <FontAwesome type="light" {...otherProps} />
  </View>
);

type Props = {
  iconType?: string;
  iconSize?: number;
};

const FontAwesomeHeaderButton = (props: Props) => {
  let FontAwesomeIcon;

  if (props.iconType === 'solid') {
    FontAwesomeIcon = SolidIcon;
  } else if (props.iconType === 'regular') {
    FontAwesomeIcon = RegularIcon;
  } else {
    FontAwesomeIcon = LightIcon;
  }

  return <HeaderButton {...props} IconComponent={FontAwesomeIcon} />;
};

FontAwesomeHeaderButton.defaultProps = {
  iconType: 'light',
  iconSize: 18,
};

export const FontAwesomeHeaderButtons = props => (
  <HeaderButtons
    HeaderButtonComponent={FontAwesomeHeaderButton}
    OverflowIcon={<FontAwesome name="ellipsis-v" type="light" size={24} />}
    {...props}
  />
);

export default FontAwesomeHeaderButtons;
