import { useMemo } from 'react';
import { distanceInWordsToNow } from 'date-fns';

import {
  Maybe,
  ChatAllThreadsQuery,
  CarouselDataFragment,
} from '../graphql/types';

import { ChipProps } from '../components/chat';

const hashChip = (c: ChipProps): string =>
  (c.badge ? '1' : '0') + c.imageUrl + c.subtitle + c.title;

const hashChips = (c: ChipProps[]): string =>
  c.reduce((acc, curr, i) => acc + i + hashChip(curr), '');

/**
 * Takes the message threads for this relationship and turns
 * them into carousel data for the Submissions Carousel.
 *
 * @param threads messages threads that have attachments
 * @param mainThreadId so we can exclude that from carousel
 * @param setParams for setting up the onPress
 */
export function useMemoizedCarouselData(
  threads: Pick<ChatAllThreadsQuery, 'messageThreads'> | undefined,
  mainThreadId,
  setParams: (params: any) => void
): ChipProps[] {
  const messageThreadArray = (threads && threads.messageThreads) || [];

  /** Exclude the main message thread plus any null/undefined schtuff */
  const isCarouselData = (
    mt: Maybe<CarouselDataFragment> | undefined
  ): mt is CarouselDataFragment => !!mt && parseInt(mt.id) !== mainThreadId;

  const sortedByTime = messageThreadArray
    .filter(isCarouselData)
    .sort((a, b) =>
      new Date(a.lastMessageAt) <= new Date(b.lastMessageAt) ? 1 : -1
    );

  const previews: ChipProps[] = sortedByTime.map(thread => {
    if (!thread) throw new Error('need a real thread here');
    const threadSubject = thread.subject || 'need subject';

    const exerciseId =
      (thread.exercises && thread.exercises[0] && thread.exercises[0].id) || -1;

    return {
      title: threadSubject,
      imageUrl: thread.s3ThumbnailUrl || '',
      subtitle: distanceInWordsToNow(thread.lastMessageAt, {
        addSuffix: true,
      }),
      badge: thread.unreadCount > 0,
      onPress: () =>
        setParams({
          currentThreadId: parseInt(thread.id),
          subject: threadSubject,
          exerciseId: exerciseId,
        }),
    };
  });

  const previewHash = hashChips(previews);

  return useMemo(() => previews, [previewHash]);
}
