import React from 'react';
import { Day } from 'react-native-gifted-chat';
import { fonts, fontSize } from '../../designSystem';

export const RenderDay: React.FC = props => (
  <Day
    {...props}
    textStyle={{
      ...fonts.regular,
      fontSize: fontSize[0],
    }}
  />
);
