import React, { useMemo } from 'react';
import {
  GiftedChat,
  IMessage as GiftedMessage,
} from 'react-native-gifted-chat';
import { Platform } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { colors } from '../../designSystem';

import {
  ChatUserType,
  ChatThreadType,
  dbUserToGiftedUser,
  chatMessageToGiftedMessage,
  noIncompleteMessage,
} from '../../utils/chat';
import { HighTicketExpiredUpsell } from '../HighTicketExpiredUpsell';
import { RecordVideoButton } from './RecordVideoButton';
import { RenderDay } from './RenderDay';
import { RenderBubble, RenderSystemMessage } from './RenderBubble';
import { hashChatThread } from '../../utils/icanhashcheezeburger';

interface ChatThreadProps {
  messageThread: ChatThreadType;
  chatUser: ChatUserType;
  onSend: (messages: GiftedMessage[]) => void;
  isMessagingDisabled?: boolean;
}

const disableSendingMessagesProps = {
  minInputToolbarHeight: 0,
  renderInputToolbar: () => null,
};

export const ChatThread: React.FC<ChatThreadProps> = ({
  messageThread,
  chatUser,
  onSend,
  isMessagingDisabled = false,
}) => {
  const navigation = useNavigation();

  const messageThreadHash = hashChatThread(messageThread);

  const currentUser = dbUserToGiftedUser(chatUser);

  const messages = useMemo(() => {
    let messages = messageThread.messages
      .filter(noIncompleteMessage)
      .map(chatMessageToGiftedMessage);

    const sortedMessages = messages.sort((a, b) =>
      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
    );

    return sortedMessages;
  }, [messageThreadHash]);

  const startNewVideo = () => {
    navigation.navigate('RecordThreadVideoScreen', {
      messageThreadId: messageThread.id,
    });
  };

  const RenderActions = () => <RecordVideoButton onPress={startNewVideo} />;

  const listViewExtraProps = {
    initialNumToRender: Platform.OS === 'web' ? messages.length : 50,
  };

  return (
    <>
      <GiftedChat
        messages={messages}
        onSend={onSend}
        listViewProps={listViewExtraProps}
        keyboardShouldPersistTaps={'handled'}
        user={currentUser}
        renderActions={RenderActions}
        renderDay={RenderDay}
        renderBubble={RenderBubble}
        renderSystemMessage={RenderSystemMessage}
        scrollToBottom={true}
        {...(isMessagingDisabled && disableSendingMessagesProps)}
      />
      {isMessagingDisabled && (
        <HighTicketExpiredUpsell
          containerStyle={{ backgroundColor: colors.accent }}
        />
      )}
    </>
  );
};
