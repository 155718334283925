import React from 'react';
import { createStackNavigator } from 'react-navigation-stack';
import { Item } from 'react-navigation-header-buttons';

import { fullScreenCard, headerOptions } from '../designSystem';
import { ExercisePreviewScreen } from '../screens/ExercisePreviewScreen';
import { MentorHomeScreen } from '../screens/mentor/MentorHomeScreen';
import { SettingsScreen } from '../screens/mentor/SettingsScreen';
import { MentorChatScreen } from '../screens/mentor/ChatScreen';
import { openFacebookGMICommunity } from '../services/facebook';
import FontAwesomeHeaderButtons from '../components/HeaderButton';
import { PrivacyPolicy } from '../screens/PrivacyPolicy';
import { RecordThreadVideoScreen } from '../screens/mentor/RecordThreadVideoScreen';
// import { RecordVideoScreen } from '../screens/mentor/RecordVideoScreen';

export const MentorNavigator = createStackNavigator(
  {
    MentorHomeScreen: {
      screen: MentorHomeScreen,
      navigationOptions: ({ navigation }) => ({
        title: 'Guitar Mastery Intensive',
        headerRight: (
          <FontAwesomeHeaderButtons>
            <Item
              title="Community"
              iconName="facebook-square"
              buttonStyle={{ color: 'white' }}
              onPress={openFacebookGMICommunity}
            />
            <Item
              title="settings"
              iconName="gear"
              buttonStyle={{ color: 'white' }}
              onPress={() => navigation.navigate('MentorSettings')}
            />
          </FontAwesomeHeaderButtons>
        ),
      }),
    },
    MentorChat: MentorChatScreen,
    ExercisePreview: ExercisePreviewScreen,
    MentorSettings: SettingsScreen,
    // RecordVideoScreen: {
    //   screen: RecordVideoScreen,
    //   navigationOptions: { header: null },
    // },
    RecordThreadVideoScreen: {
      screen: RecordThreadVideoScreen,
      navigationOptions: {
        header: null,
      },
    },
    Privacy: {
      screen: PrivacyPolicy,
      navigationOptions: {
        title: 'Privacy Policy',
      },
    },
  },
  {
    defaultNavigationOptions: headerOptions,
    cardStyle: fullScreenCard,
  }
);
