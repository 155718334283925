import React, { useState } from 'react';
import { ScrollView, RefreshControl, ScrollViewProps } from 'react-native';
import { trackError } from '../utils/analytics';

interface RefetchScrollViewProps {
  refetch?: (_?: any) => Promise<any>;
}

export const RefetchScrollView: React.FC<RefetchScrollViewProps &
  ScrollViewProps> = props => {
  const [isViewRefreshing, setIsViewRefreshing] = useState(false);
  const refreshView = () => {
    setIsViewRefreshing(true);
    if (!!props.refetch)
      props
        .refetch()
        .then(() => setIsViewRefreshing(false))
        .catch(err => trackError(err));
  };

  return (
    <ScrollView
      {...props}
      refreshControl={
        props.refetch && (
          <RefreshControl
            refreshing={isViewRefreshing}
            onRefresh={refreshView}
          />
        )
      }
    />
  );
};
