import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import gql from 'graphql-tag';
import { useNavigation } from 'react-navigation-hooks';
import { Item } from 'react-navigation-header-buttons';
import { HeaderBackButton } from 'react-navigation-stack';
import { useMentorChatStudentInfoQuery } from '../../graphql/types';

import { MissingDataError } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { trackEvent } from '../../utils/analytics';
import { StudentReportCard } from '../../components/StudentReportCard';
import { NavigationScreenType } from '../../utils/navigationUtils';
import FontAwesomeHeaderButtons from '../../components/HeaderButton';
import { chatParams, ChatUserType, dbUserToChatUser } from '../../utils/chat';
import { ChatView } from '../../components/chat/ChatView';
import { ChatContext } from '../../services/ChatContext';
import { useOnFocus } from '../../hooks/useOnFocus';
import { useOnBlur } from '../../hooks/useOnBlur';
import { useForceRerender } from '../../hooks/useForceRerender';
import { ExerciseViewButton } from '../../components/ExerciseViewButton';

export const MENTOR_CHAT_STUDENT_INFO = gql`
  query MentorChatStudentInfo($mainThreadId: Int!) {
    messageThread(id: $mainThreadId) {
      id
      mentor {
        id
        user {
          id
          ...User
        }
      }
      student {
        id
        user {
          id
          ...User
        }
        segments {
          id
          createdAt
        }
      }
    }
  }
`;

export const MentorChatScreen: NavigationScreenType = () => {
  const { getParam } = useNavigation();
  const { setCurrentThreadId } = useContext(ChatContext);
  const [isFocused, setIsFocused] = useState(true);
  const forceRerender = useForceRerender();

  const {
    showProfile,
    mainThreadId,
    currentThreadId,
    relationshipId,
    isMainThread,
  } = chatParams(getParam);

  setCurrentThreadId(currentThreadId);

  const studentInfo = useMentorChatStudentInfoQuery({
    variables: { mainThreadId },
  });

  useOnFocus(() => {
    forceRerender();
    setIsFocused(true);
  });
  useOnBlur(() => setIsFocused(false));

  if (!isFocused) {
    return null;
  }

  if (studentInfo.loading) return <Loading />;
  if (
    !studentInfo.data ||
    !studentInfo.data.messageThread ||
    !studentInfo.data.messageThread.student
  ) {
    return <MissingDataError type="Student Segments" />;
  }

  // TODO consider memoizing?
  const chatUser: ChatUserType = dbUserToChatUser(
    studentInfo.data.messageThread.mentor.user
  );
  const student = studentInfo.data.messageThread.student;
  return (
    <View style={{ flex: 1 }}>
      <StudentReportCard
        student={student}
        showCalendar={showProfile}
        showLastPracticed={isMainThread}
      />
      <ChatView
        userRole="mentor"
        chatUser={chatUser}
        mainThreadId={mainThreadId}
        currentThreadId={currentThreadId}
        relationshipId={relationshipId}
      />
    </View>
  );
};

MentorChatScreen.navigationOptions = ({ navigation }) => {
  // Probably need to refactor this and remove 'chatParams' or make it actually cross-app
  const {
    mainThreadId,
    baseTitle,
    subject,
    isMainThread,
    showProfile,
    exerciseId,
  } = chatParams(navigation.getParam);

  const title = subject.length > 0 ? subject : baseTitle;

  const goBack = isMainThread
    ? () => navigation.navigate('MentorHomeScreen')
    : () =>
        navigation.setParams({
          currentThreadId: mainThreadId,
          subject: '',
        });

  const hasExercise = !isMainThread && parseInt(exerciseId) > 0;
  const ExerciseButton = () =>
    hasExercise ? (
      <ExerciseViewButton
        hasRightSibling
        onPress={() => {
          trackEvent('mentor-preview-exercise', { exerciseId });
          navigation.navigate('ExercisePreview', { exerciseId, subject });
        }}
      />
    ) : null;

  return {
    title,
    headerLeft: (
      <HeaderBackButton key={'back'} onPress={goBack} tintColor={'white'} />
    ),
    headerRight: (
      <>
        <ExerciseButton />
        <FontAwesomeHeaderButtons>
          <Item
            title="profile"
            key="profile"
            iconName="user"
            buttonStyle={{ color: 'white' }}
            onPress={() => {
              navigation.setParams({ showProfile: !showProfile });
            }}
          />
        </FontAwesomeHeaderButtons>
      </>
    ),
  };
};
