import { useEffect, useRef } from 'react';
import { useReadMessagesMutation, ChatThreadQuery } from '../graphql/types';
import {
  hashMessageThread,
  hashStringArray,
} from '../utils/icanhashcheezeburger';
import { trackError, trackEvent } from '../utils/analytics';

type MessageThreadQuery = ChatThreadQuery | undefined;

export const useReadMessages = (
  data: MessageThreadQuery,
  currentUserType: 'mentor' | 'student',
  refetchQueries?: string[],
  onReadHandler?: () => void
) => {
  const [readMessages, {}] = useReadMessagesMutation({});
  const alreadyMarked = useRef<number[]>([]);
  const dataHash = data && hashMessageThread(data.messageThread);

  const refetchHash = hashStringArray(refetchQueries || []);

  useEffect(() => {
    if (data && data.messageThread && data.messageThread.messages) {
      const unreadMessages = data.messageThread.messages.filter(
        message =>
          message &&
          message.sender &&
          message.sender.userType !== currentUserType &&
          message.read === false
      );

      type Message = typeof unreadMessages[0];

      const onlyNewMessages = (message: Message) =>
        message &&
        !alreadyMarked.current.find(id => id === parseInt(message.id));

      /**
       * Array of message ids for unread messages we haven't already
       * initiated the "mark as read" mutation for
       */
      const ids = unreadMessages
        .filter(onlyNewMessages)
        .map(m => m && parseInt(m.id)) as number[];

      if (ids.length > 0) {
        // Add this id to list of "already marked" ids
        alreadyMarked.current.concat(ids);
        readMessages({ variables: { ids }, refetchQueries })
          .then(_ => {
            trackEvent('marked-messages-read', { messages: ids.length });
            if (onReadHandler) {
              onReadHandler();
            }
          })
          .catch(err => {
            trackError(err);
          });
      }
    }
  }, [dataHash, currentUserType, refetchHash]);
};
