import gql from 'graphql-tag';
import { Resolvers } from 'apollo-client';
import assocPath from 'ramda/src/assocPath';
import { SETTINGS_SCREEN_QUERY } from './queries';
import { ExerciseTitleFragment } from './types';

export const typeDefs = gql`
  extend type UserType {
    fullName: String!
  }

  extend type LessonType {
    supplementaryJSON: JSONString!
  }

  extend type Query {
    userSettings: UserSettings
  }

  extend type Mutation {
    updatePracticeReminderSettings(
      enabled: Boolean
      notificationId: String
      date: DateTime
    ): PracticeReminderSettings
  }

  interface PracticeReminderSettings {
    enabled: Boolean
    date: DateTime
    notificationId: ID
  }

  interface UserSettings {
    practiceReminder: PracticeReminderSettings
    chatNotifications: Boolean
  }
`;

export const resolvers: Resolvers = {
  // Query: {
  //   userSettings: (_, __, { client })=> {
  //     const data = client.readQuery({ query: SETTINGS_SCREEN_QUERY });

  //     return data.userSettings;
  //   }
  // },
  Mutation: {
    updatePracticeReminderSettings: async (_, args, { client }) => {
      const data = client.readQuery({ query: SETTINGS_SCREEN_QUERY });

      const {
        userSettings: { practiceReminder },
      } = data;

      const mergedReminder = {
        ...practiceReminder,
        ...args,
      };

      client.writeData({
        data: assocPath(
          ['userSettings', 'practiceReminder'],
          mergedReminder,
          data
        ),
      });

      return mergedReminder;
    },
  },
  UserType: {
    fullName: (user, _, __) => {
      return user.firstName && user.lastName
        ? user.firstName + ' ' + user.lastName
        : '';
    },
  },
  LessonType: {
    supplementaryJSON: (lesson, _, __) => {
      try {
        return JSON.parse(lesson.supplementary);
      } catch {
        return {};
      }
    },
  },
};

// TODO make this into a proper resolver
export const resolveExerciseTitle = (
  exercise: ExerciseTitleFragment | null | undefined
) => {
  if (
    !exercise ||
    !exercise.lesson ||
    !exercise.lesson.title ||
    !exercise.lesson.lessonGroup ||
    !exercise.lesson.lessonGroup.title
  )
    throw new Error('did not get required info for exercise title');
  return `${exercise.lesson.lessonGroup.title} - ${exercise.lesson.title}`;
};
