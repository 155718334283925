import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import { colors, rhythm, fontSize, fonts } from '../../designSystem';

export const chipWidth = 144;
export const chipPadding = rhythm[2];
const itemWidth = chipWidth + rhythm[1] * 2;

export interface ChipProps {
  title: string;
  subtitle?: string;
  onPress?: any;
  badge?: boolean;
  imageUrl: string;
}

interface SlenderChipProps {
  onPress: () => void;
  disabled: boolean;
  customBackgroundColor: string;
}

const blackPng144 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQCAQAAABNTyozAAAAwklEQVR42u3QMQ0AAAgDsM2/aHBAwt9KaDPhUEGCBAkSJEiQIEEIEiRIkCBBggQhSJAgQYIECRKEIEGCBAkSJEiQIAQJEiRIkCBBghAkSJAgQYIECUKQIEGCBAkSJEgQggQJEiRIkCBBCBIkSJAgQYIEIUiQIEGCBAkSJAhBggQJEiRIkCAECRIkSJAgQYIQJEiQIEGCBAkShCBBggQJEiRIEIIECRIkSJAgQQgSJEiQIEGCBAlCkCBBggQJEiQIQT8L/lWQATarcawAAAAASUVORK5CYII=';

export const SlenderChip: React.FC<SlenderChipProps> = ({
  children,
  onPress,
  disabled,
  customBackgroundColor,
}) => {
  const backgroundColor = customBackgroundColor || colors.accent;
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={onPress}
      disabled={disabled}
    >
      <View style={chipStyles.slenderChipWrapper}>
        <View style={[chipStyles.slenderChip, { backgroundColor }]}>
          {children}
        </View>
      </View>
    </TouchableOpacity>
  );
};
export const Chip: React.FC<ChipProps> = props => {
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={props.onPress}
    >
      <View style={chipStyles.chipWrapper}>
        <ImageBackground
          imageStyle={chipStyles.chipImage}
          style={chipStyles.chip}
          source={{ uri: props.imageUrl || blackPng144 }}
        >
          <Text style={chipStyles.chipText}>{props.title}</Text>
          <Text style={chipStyles.chipSubtitle}>{props.subtitle}</Text>
        </ImageBackground>
      </View>
      {props.badge && (
        <View
          style={{
            marginLeft: -rhythm[2] / 2,
            backgroundColor: colors.accent,
            height: rhythm[2],
            width: rhythm[2],
            borderRadius: rhythm[2],
            borderWidth: 2,
            borderColor: colors.white,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export const chipStyles = StyleSheet.create({
  chipWrapper: {
    width: itemWidth,
    height: chipWidth,
    paddingHorizontal: chipPadding,
    paddingVertical: chipPadding,
  },
  slenderChipWrapper: {
    // width: itemWidth / 3,
    height: chipWidth,
    paddingLeft: chipPadding,
    paddingVertical: chipPadding,
  },
  chip: {
    flex: 1,
    padding: rhythm[1],
    backgroundColor: colors.darkGrey,
    width: chipWidth,
    borderRadius: rhythm[1],
    overflow: 'hidden',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  slenderChip: {
    flex: 1,
    padding: rhythm[1],
    // width: chipWidth / 3,
    borderRadius: rhythm[1],
    // backgroundColor: colors.accent,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipImage: {
    opacity: 0.8,
  },
  chipText: {
    ...fonts.bold,
    color: colors.white,
    fontSize: fontSize[0],
  },
  chipSubtitle: {
    ...fonts.regular,
    color: colors.white,
    fontSize: fontSize[0],
  },
});
