import React from 'react';
import { StyleSheet, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { colors, rhythm } from '../designSystem';

interface TabIconProps {
  badgeVisible?: boolean;
  icon: React.ReactNode;
}

export const TabIcon: React.FC<TabIconProps> = ({ badgeVisible, icon }) => (
  <View style={styles.container}>
    {icon}
    {!!badgeVisible && <View style={styles.badge} />}
  </View>
);

const BADGE_SIZE = rhythm[1];
const BADGE_SIZE_PADDED = BADGE_SIZE + 4;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    // height: 32,
    // width: 32,
  },
  badge: {
    height: BADGE_SIZE_PADDED,
    width: BADGE_SIZE_PADDED,
    backgroundColor: colors.accent,
    borderRadius: BADGE_SIZE_PADDED,
    position: 'absolute',
    top: -BADGE_SIZE_PADDED / 2,
    right: -BADGE_SIZE_PADDED / 2,
    borderWidth: 2,
    borderColor: colors.white,
  },
});
