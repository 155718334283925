import { useState, useEffect } from 'react';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import { createClient } from '../graphql/apolloClient';

export const useClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    createClient()
      .then(setClient)
      .then(_ => setLoading(false));

    return () => {};
  }, []);

  return { client, loading };
};
