import {
  IMessage as GiftedMessage,
  User as GiftedUser,
} from 'react-native-gifted-chat';
import {
  Maybe,
  MessageAttachmentType,
  MessageFragment,
  MessageWithAttachmentsFragment,
  UserType,
  CurrentThreadInfoFragment,
  MessageType,
  MessageAttachmentDownloadInfoFragment,
  MentorIndexStudentMentorRelationshipFragment,
} from '../../graphql/types';
import {
  ChatMessageType,
  ChatAttachmentType,
  ChatUserType,
  ChatThreadType,
  ChatRelationshipType,
} from './types';
import { attachmentForMessage, textForMessage } from './misc';

export const dbUserToChatUser = (
  user: Pick<UserType, 'id' | 'firstName' | 'lastName'>
): ChatUserType => {
  // console.log('dbuser to chat user', user);
  // findout why this is called so damn much
  return {
    id: parseInt(user.id),
    fullName: [user.firstName, user.lastName].join(' '),
  };
};

type DbMessageAttachment = Pick<
  MessageAttachmentType,
  'id' | 'complete' | 's3ThumbnailUrl' | 's3Url'
>;
type DbMessageAttachments = Maybe<DbMessageAttachment>[] | null | undefined;

export function dbAttachmentsToChatAttachment(
  dbAttachments: DbMessageAttachments,
  { messageId, messageThreadId }
): ChatAttachmentType | undefined {
  if (!dbAttachments || !dbAttachments[0]) return undefined;
  const dbAttachment = dbAttachments[0];

  const chatAttachment: ChatAttachmentType = {
    id: parseInt(dbAttachment.id),
    messageId,
    messageThreadId,
    complete: dbAttachment.complete,
    s3Url: dbAttachment.s3Url || undefined,
    s3ThumbnailUrl: dbAttachment.s3ThumbnailUrl || undefined,
  };
  return chatAttachment;
}

// Todo Clean me up
interface DbMessageConversionInfo {
  messageThreadId: number;
}

const isMessageWithAttachments = (
  message: MessageFragment | MessageWithAttachmentsFragment
): message is MessageWithAttachmentsFragment =>
  (message as MessageWithAttachmentsFragment).attachments !== undefined;

/**
 * Converts db Message to ChatMessage
 * @param message
 * @param info
 */
function dbMessageToChatMessage(
  message: MessageWithAttachmentsFragment,
  info: DbMessageConversionInfo
): ChatMessageType {
  if (!message.sender || !message.sender.id)
    throw new Error(`need message sender for message ${message.id}`);

  const messageId = isNaN(Number(message.id))
    ? message.id
    : parseInt(message.id);
  const sender = dbUserToChatUser(message.sender);
  const attachment = isMessageWithAttachments(message)
    ? dbAttachmentsToChatAttachment(message.attachments, {
        messageThreadId: info.messageThreadId,
        messageId,
      })
    : undefined;

  return {
    id: messageId,
    messageThreadId: info.messageThreadId,
    createdAt: new Date(message.createdAt),
    contents: message.contents,
    sent: message.complete,
    read: message.read,
    sender,
    attachment,
  };
}

/**
 * Convert db Messages to ChatMessages[] (keeps array order)
 */
export function dbMessagesToChatMessages(
  dbMessages: MessageWithAttachmentsFragment[],
  { messageThreadId }: { messageThreadId: number }
): ChatMessageType[] {
  const chatMessages: ChatMessageType[] = dbMessages.map(m =>
    dbMessageToChatMessage(m, { messageThreadId })
  );

  return chatMessages;
}

const isMessageWithIncompleteAttachment = (
  message: MessageWithAttachmentsFragment
) => message.contents.length === 0 && message.complete == false;
/**
 * current message thread - only updates if hash changes
 */
const isMessage = (
  message: Maybe<MessageWithAttachmentsFragment>
): message is MessageWithAttachmentsFragment =>
  message !== null &&
  message !== undefined &&
  !isMessageWithIncompleteAttachment(message);

export const dbThreadToChatThread = (
  t: CurrentThreadInfoFragment,
  relationshipId: number,
  isMainThread: boolean
): ChatThreadType => {
  const messageThreadId = parseInt(t.id);
  return {
    id: messageThreadId,
    subject: t.subject || '',
    relationshipId,
    isMainThread,
    unreadCount: -1, //unused
    exerciseId: undefined,
    exerciseProgressId: undefined,
    s3ThumbnailUrl: undefined,
    messages: t.messages
      ? dbMessagesToChatMessages(t.messages.filter(isMessage), {
          messageThreadId,
        })
      : [],
  };
};

export const dbUserToGiftedUser = (user: ChatUserType): GiftedUser => ({
  _id: user.id,
  name: user.fullName,
});

export const noIncompleteMessage = (message: ChatMessageType) =>
  !message.attachment || message.attachment.complete;

export const chatMessageToGiftedMessage = (
  message: ChatMessageType
): GiftedMessage => {
  const attachment = attachmentForMessage(message);
  const msgText = textForMessage(message);
  const isSystemMessage = msgText !== message.contents;

  return {
    _id: message.id,
    text: msgText,
    createdAt: message.createdAt,
    received: message.read,
    pending: !message.sent,
    sent: message.sent,
    system: isSystemMessage,
    user: dbUserToGiftedUser(message.sender),
    ...attachment,
  };
};

/**
 * Fetch last message (or attachment)
 */
const getLastMessage = (
  message: Maybe<
    Pick<MessageType, 'id' | 'contents'> & {
      attachments: Maybe<Maybe<MessageAttachmentDownloadInfoFragment>[]>;
    }
  >
): string | undefined => {
  let lastMessage: string | undefined = undefined;
  if (message) {
    if (message.contents) {
      lastMessage = message.contents;
    } else if (message.attachments && message.attachments.length > 0) {
      lastMessage = '[attachment]';
    }
  }
  return lastMessage;
};

export const mentorIndexRelationshipToChatRelationship = (
  dbrel: MentorIndexStudentMentorRelationshipFragment
): ChatRelationshipType => {
  if (!dbrel) throw new Error('dbrelationshiptochat bad data');
  if (!dbrel.messageThread) throw new Error('dbrel no messagethread');
  const relationshipId = parseInt(dbrel.id);
  const threadId = parseInt(dbrel.messageThread.id);

  const lastMessage = getLastMessage(dbrel.messageThread.lastMessage);

  const chatRelationship: ChatRelationshipType = {
    id: relationshipId,
    student: dbUserToChatUser(dbrel.messageThread.student.user),
    mentor: dbUserToChatUser(dbrel.messageThread.mentor.user),
    unreadCount: dbrel.unreadCount || 0,
    relationshipEndDate: new Date(dbrel.endedAt),
    relationshipStartDate: new Date(dbrel.startedAt),
    mainThreadId: threadId,
    lastActivity: dbrel.messageThread.lastActivity || undefined,
    lastMainThreadMessage: lastMessage,
    messageThreads: {},
  };
  return chatRelationship;
};
