import { AsyncStorage } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { AUTH_TOKEN_KEY } from '../config';
import { clearUser } from '../utils/analytics';

export const signOut = () => {
  clearUser();
  return AsyncStorage.removeItem(AUTH_TOKEN_KEY);
};

export const useSignOut = () => {
  const { navigate } = useNavigation();
  const signOutAndNavigate = () => {
    signOut().then(() => navigate('AuthLoading'));
  };

  return { signOut: signOutAndNavigate };
};
