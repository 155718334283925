import React, { useRef, useMemo } from 'react';
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Alert,
  Dimensions,
  ViewStyle,
} from 'react-native';
import { Video } from 'expo-av';

import { SeekBar } from './SeekBar';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import { Feather } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';
import { CloseButtonX } from '../CloseButtonX';
import { ProgressButton } from '../Button';
import { RecordVideoScreenStrings as S } from '../../strings';
import { rhythm, colors } from '../../designSystem';
import {
  createReviewMachine,
  ReviewContext,
} from '../../machines/reviewMachine';
import * as Sentry from 'sentry-expo';
import { trackError, trackEvent } from '../../utils/analytics';

const { width: winWidth, height: winHeight } = Dimensions.get('window');

export type UploadVideoFn = (
  videoUri: string,
  onProgress?: (event: ProgressEvent) => void
) => Promise<any>;

interface VideoReviewProps {
  videoUri: string;
  returnToCapture: () => void;
  onUploadComplete?: () => void;
  uploadVideo: UploadVideoFn;
}

export const VideoReview: React.FC<VideoReviewProps> = ({
  videoUri,
  returnToCapture,
  onUploadComplete,
  uploadVideo,
}) => {
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const videoRef = useRef<Video>(null);

  const reviewMachine = useMemo(() => {
    return createReviewMachine(videoUri);
  }, [videoUri]);

  const [current, send] = useMachine(reviewMachine, {
    immediate: true,
    actions: {
      play: () => {
        if (!videoRef.current) throw new Error('play: null videoref');
        videoRef.current.playAsync();
      },
      pause: () => {
        if (!videoRef.current) throw new Error('pause: null videoref');
        videoRef.current.pauseAsync();
      },
      setVideo: assign<ReviewContext>({
        video: (_context, event) => event.video,
        duration: (_context, event) => event.status.durationMillis || 0,
      }),
      setElapsed: assign<ReviewContext>({
        elapsed: (_context, event) => event.status.positionMillis || 0,
      }),
      // setUploadProgress: assign<ReviewContext>({
      //   uploadProgress: (_context, event) => {
      //     console.log('setting upload progress to', event.value);

      //     return {
      //       value: event.value,
      //       max: event.max,
      //     };
      //   },
      // }),
      uploadComplete: () => {
        if (typeof onUploadComplete === 'function') onUploadComplete();
      },
      dismiss: () => {
        if (!videoRef.current) throw new Error('dismiss: null videoref');
        videoRef.current.pauseAsync().then(() => {
          returnToCapture();
        });
      },
    },
    services: {
      uploadFailure: (context, event) => (callback, onEvent) => {
        trackError('video upload failure');
        trackEvent('camera-upload-fail');
        Alert.alert('Error', 'Something went wrong when uploading your video', [
          {
            text: 'Back to Camera',
            onPress: () => {
              returnToCapture();
            },
          },
          {
            text: 'Retry upload',
            onPress: () => {
              callback('INIT_UPLOAD');
            },
          },
        ]);
      },
      uploadVideo: (context, event) => (callback, onEvent) => {
        const onProgress = ({ total, loaded }: ProgressEvent) => {
          setUploadProgress(loaded / total);
          // callback({ type: 'UPLOAD_PROGRESS', value: loaded, max: total });
        };
        setUploadProgress(0);
        const promise = uploadVideo(videoUri, onProgress)
          .then(res => {
            trackEvent('video-upload-complete', { duration: context.duration });
            callback({ type: 'UPLOAD_COMPLETE' });
          })
          .catch(error => {
            trackEvent('video-upload-failure', {
              progress: uploadProgress,
              error: error,
            });
            Sentry.captureException(error);
            callback({ type: 'UPLOAD_FAIL', error });
          });
      },
    },
  });

  const handlePlaybackStatusUpdate = status => send('TIMING', { status });

  // const debouncedHandlePlaybackStatusUpdate = (handlePlaybackStatusUpdate, 50);

  const { duration, elapsed } = current.context;

  const playing = current.matches({ playback: { ready: 'playing' } });
  /** Uploading or finished uploading (100% state) */
  const uploading =
    current.matches({ upload: 'pending' }) ||
    current.matches({ upload: 'progress' }) ||
    current.matches({ upload: 'success' });

  const handleLoad = status => {
    send('LOADED', { video: videoRef.current, status });
  };

  const handleVideoError = error => send('FAIL', { error });
  const handleTapVideo = () => {
    playing ? send('PAUSE') : send('PLAY');
  };

  const handleDismiss = () => {
    send(['DISMISS']);
  };

  interface IconProps {
    size: number;
    color: string;
    style: ViewStyle;
  }
  const playPauseIconProps: IconProps = {
    size: 96,
    color: colors.white,
    style: { alignSelf: 'center' },
  };
  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={handleTapVideo}>
        <Video
          ref={videoRef}
          source={{ uri: videoUri }}
          resizeMode={Video.RESIZE_MODE_CONTAIN}
          isLooping
          useNativeControls={false}
          shouldPlay
          onLoad={handleLoad}
          onError={handleVideoError}
          onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
          style={styles.video}
        />
      </TouchableWithoutFeedback>

      <View style={styles.overlayContainer} pointerEvents="box-none">
        <View style={styles.toolbar}>
          <CloseButtonX onPress={handleDismiss} disabled={uploading} />
          <ProgressButton
            inverted
            title={
              uploading
                ? S.ReviewVideo.sendToMentorButtonUploading
                : S.ReviewVideo.sendToMentorButton
            }
            onPress={() => send(['PAUSE', 'INIT_UPLOAD'])}
            disabled={uploading}
            style={{ marginHorizontal: rhythm[0] }}
            progress={!!uploadProgress ? uploadProgress : 0}
            showProgress={uploading}
            textStyle={{ color: colors.darkGrey }}
          />
        </View>

        <TouchableOpacity
          onPress={handleTapVideo}
          hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
        >
          {playing ? (
            <Foundation name="pause" {...playPauseIconProps} />
          ) : (
            <Feather name="play" {...playPauseIconProps} />
          )}
        </TouchableOpacity>

        <View style={styles.seekContainer}>
          <SeekBar position={elapsed} duration={duration} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: winWidth,
    width: winHeight + 50,
    justifyContent: 'center',
    top: 0,
    backgroundColor: colors.darkGrey,
  },
  video: {
    height: winWidth,
  },
  overlayContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    bottom: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: winWidth,
    width: winHeight,
  },
  toolbar: {
    padding: rhythm[1],
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  seekContainer: {
    width: winHeight,
    padding: rhythm[2],
  },
});
