import React from 'react';
import { Bubble, LeftRightStyle } from 'react-native-gifted-chat';
import { TextStyle } from 'react-native';
import { colors, fonts, fontSize, rhythm } from '../../designSystem';
import { VideoBubble } from './VideoBubble';
import { byPlatform } from '../../utils/platform';

export const RenderSystemMessage: React.FC = props => (
  <Bubble
    {...props}
    // @ts-ignore
    renderTime={() => null}
    renderTicks={() => null}
    textStyle={systemTextStyle}
    wrapperStyle={systemWrapperStyle}
  />
);

export const RenderBubble: React.FC = props => (
  <Bubble
    {...props}
    renderMessageVideo={props => <VideoBubble {...props} />}
    textStyle={bubbleTextStyle}
    wrapperStyle={bubbleWrapperStyle}
  />
);

export const bubbleFontSize = fontSize[1];

export const bubbleTextStyle: LeftRightStyle<TextStyle> = {
  left: {
    ...fonts.regular,
    fontSize: bubbleFontSize,
    color: colors.darkGrey,
  },
  right: {
    ...fonts.regular,
    fontSize: bubbleFontSize,
  },
};

const systemTextStyle: LeftRightStyle<TextStyle> = {
  left: {
    ...fonts.italic,
    fontSize: bubbleFontSize,
    color: colors.darkGrey,
  },
  right: {
    ...fonts.italic,
    fontSize: bubbleFontSize,
    color: colors.lightGrey,
  },
};

const maxWidth = byPlatform({ mobile: '100%', web: '60%' });
const bubbleWrapperStyle = {
  left: { maxWidth },
  right: {
    maxWidth,
    backgroundColor: colors.accent,
  },
};
const marginBottom = rhythm[2];

const systemWrapperStyle = {
  // marginLeft includes profile bubble 36 wide + 8 margin, manually measured
  left: { maxWidth, marginLeft: 36 + 8, marginBottom },
  right: {
    maxWidth,
    marginBottom,
    marginRight: 8, // from gifted chat manual measurements
    backgroundColor: colors.accentDarken,
  },
};
