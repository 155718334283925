import React from 'react';
import { ScrollView, Text } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import Constants from 'expo-constants';
import { Button } from '../../components/Button';
import { Container } from '../../components/Container';
import { SectionHeader } from '../../components/SectionHeader';
import { ToggleRow, SimpleRow } from '../../components/Rows';
import { useSettingsQuery } from '../../graphql/types';
import { rhythm } from '../../designSystem';
import { useSignOut } from '../../hooks/useSignOut';
import { trackEvent } from '../../utils/analytics';

export const SettingsScreen: React.FC = () => {
  const { navigate } = useNavigation();

  // const { data, loading, error, client } = useSettingsQuery();

  const { signOut } = useSignOut();

  return (
    <ScrollView>
      <Container style={{ flex: 1 }}>
        {/* <SectionHeader>Notifications</SectionHeader>
        <ToggleRow title='Chat notifications' value={chatNotifications} onValueChange={onUpdateChatNotifications} /> */}

        <SectionHeader>Guitar Mastery Intensive</SectionHeader>
        {/* TODO: how to contact support? */}
        <SimpleRow title="Privacy policy" onPress={() => navigate('Privacy')} />
        <Text style={{ marginBottom: rhythm[4] }}>
          v{Constants.manifest.version}
        </Text>
        <Button
          title="Sign out"
          onPress={() => {
            trackEvent('regular-click-sign-out');
            signOut();
          }}
        />
      </Container>
    </ScrollView>
  );
};
