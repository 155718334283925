import React from 'react';
import { StatusBar, View } from 'react-native';
import { ApolloProvider } from '@apollo/react-hooks';
import { AppLoading } from 'expo';
import { usePortraitLockExceptIpad } from './src/hooks/useMobileOrientationLock';
import { useFonts } from './src/hooks/useFonts';
import NavigationService from './src/NavigationService';
import { AppContainer, getPersistenceFunctions } from './src/router';
import FlashMessage from 'react-native-flash-message';
import { useClient } from './src/hooks/useClient';
import { ChatProvider } from './src/services/ChatContext';
import { initializeAmplitude } from './src/utils/analytics';
import { trackNavigationChange } from './src/utils/navigationUtils';
import {
  initializeSentry,
  isLoginPath,
  didLaunchMobileApp,
} from './src/utils/environment';
import { AudioSessionProvider } from './src/services/AudioSessionProvider';
import { ErrorMessage, missingDataError } from './src/components/ErrorMessage';

initializeSentry();
initializeAmplitude();

const App: React.FC = () => {
  usePortraitLockExceptIpad({ debugId: 'App.tsx', onlyOnMount: true });
  const fontLoading = useFonts({
    'Inter-Medium': require('./assets/Inter-hinted/Inter-Medium.ttf'),
    'Inter-Bold': require('./assets/Inter-hinted/Inter-Bold.ttf'),
  });

  // Detect iPad on web and launch mobile app if so
  if (isLoginPath() && didLaunchMobileApp()) {
    return <View></View>;
  }

  const { client, loading } = useClient();
  if (loading || fontLoading) return <AppLoading />;
  if (!client)
    return <ErrorMessage error={missingDataError('Apollo Client')} />;

  return (
    <ApolloProvider client={client}>
      <AudioSessionProvider />
      <StatusBar hidden={false} barStyle="light-content" />
      <ChatProvider>
        <AppContainer
          {...getPersistenceFunctions()}
          onNavigationStateChange={trackNavigationChange}
          ref={navigatorRef => {
            NavigationService.setTopLevelNavigator(navigatorRef);
          }}
        />
      </ChatProvider>
      <FlashMessage />
    </ApolloProvider>
  );
};

export default App;
