import React from 'react';
import { StyleSheet, SafeAreaView } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import gql from 'graphql-tag';

import { byPlatform } from '../utils/platform';
import { useExerciseSimpleQuery } from '../graphql/types';
import { ErrorMessage, MissingDataError } from '../components/ErrorMessage';
import { Loading } from '../components/Loading';
import { Soundslice } from '../components/Soundslice';
import { NavigationScreenType } from '../utils/navigationUtils';

export const EXERCISE_SIMPLE_QUERY = gql`
  query ExerciseSimple($exerciseId: Int!) {
    exercise(id: $exerciseId) {
      id
      level
      soundsliceEmbedUrl
    }
  }
`;

export const ExercisePreviewScreen: NavigationScreenType = () => {
  const navigation = useNavigation();

  const exerciseId = parseInt(navigation.getParam('exerciseId', 0));
  if (exerciseId <= 0 || exerciseId === NaN)
    throw new Error('Must have positive exercise id');

  const { data, loading, error } = useExerciseSimpleQuery({
    variables: {
      exerciseId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.exercise)
    return <MissingDataError type="Exercise Preview Screen" />;

  const { exercise } = data;

  return (
    <SafeAreaView style={styles.container}>
      <Soundslice url={exercise.soundsliceEmbedUrl} />
    </SafeAreaView>
  );
};

ExercisePreviewScreen.navigationOptions = ({ navigation }) => {
  return {
    title: navigation.getParam('subject', 'Soundslice Exercise'),
  };
};
const styles = StyleSheet.create({
  container: byPlatform({
    web: {
      flex: 1,
    },
    mobile: {
      flex: 1,
      justifyContent: 'space-between',
    },
  }),
});
