// FOR FUTURE USE

import React from 'react';
import { useNavigation } from 'react-navigation-hooks';
import { NavigationScreenType } from '../utils/navigationUtils';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';
import { byPlatform } from '../utils/platform';
// import {
//   WebViewMessage,
//   WebViewError,
//   WebViewNavigation,
// } from 'react-native-webview/lib/WebViewTypes';

const WebView = byPlatform({
  web: WebViewWeb,
  mobile: WebViewMobile,
});

// interface SoundsliceProps {
//   url: string;
//   onMessage?: (e: NativeSyntheticEvent<WebViewMessage>) => void;
//   onError?: (e: NativeSyntheticEvent<WebViewError>) => void;
//   onLoad?: (e: NativeSyntheticEvent<WebViewNavigation>) => void;
// }

export const WebViewFullScreen: NavigationScreenType = () => {
  const { getParam } = useNavigation();
  const uri = getParam('uri');
  if (!uri) throw new Error('URI required');
  return (
    <WebView
      source={{ uri }}
      style={{ flex: 1 }}
      javaScriptEnabled={true}
      domStorageEnabled={true}
      startInLoadingState={true}
      // onMessage={onMessage}
      // onError={onError}
      // onLoad={onLoad}
      allowsInlineMediaPlayback={true}
      allowsFullscreenVideo={true}
    ></WebView>
  );
};

WebViewFullScreen.navigationOptions = ({ navigation }) => {
  const title = navigation.getParam('title');
  return {
    title,
  };
};
