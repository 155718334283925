import React from 'react';
import { StyleSheet, View } from 'react-native';
import times from 'ramda/src/times';
import { Star } from './Badge';

interface StarRatingProps {
  rating: number;
  size: number;
  max?: number;
  color?: string;
}

export const StarRating: React.FC<StarRatingProps> = ({
  rating,
  size,
  max = 5,
  color = '#000',
}) => (
  <View style={styles.container}>
    {times(n => {
      const i = Math.min(max, Math.max(1, n + 1));

      return (
        <View key={n} style={styles.itemContainer}>
          <Star status={i > rating ? 'initial' : 'completed'} color={color} />
        </View>
      );
    }, max)}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  itemContainer: {
    marginHorizontal: 2,
  },
});
