import React from 'react';
import { Text, View, SafeAreaView } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { styles } from './styles';
import { rhythm } from '../../designSystem';

export const MagicLinkPendingScreen: React.FC = () => {
  const { getParam } = useNavigation();
  const email = getParam('email');

  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 1, alignItems: 'center', padding: rhythm[3] }}>
        <Text style={[styles.heroText, styles.heroTextLastLine]}>
          Check your email
        </Text>
        <Text style={styles.subtitleText}>
          <Text>
            To confirm your email address, tap the button in the email we sent
            to{' '}
          </Text>
          <Text style={styles.bold}>{email}</Text>
        </Text>
      </View>
      {/* TODO: openInbox doesn't work on Android */}
      {/* {Platform.OS === 'ios' && (
    <Button title="Open Email App" onPress={() => openInbox({})} />
  )} */}
    </SafeAreaView>
  );
};
