import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { AsyncStorage } from 'react-native';
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { disableFragmentWarnings } from 'graphql-tag';
import { HttpLink } from 'apollo-link-http';
import { from } from 'apollo-link';
import { ServerError } from 'apollo-link-http-common';

import { AUTH_TOKEN_KEY } from '../config';
import { graphQLUrl } from '../utils/environment';
import NavigationService from '../NavigationService';
import { resolvers, typeDefs } from './resolvers';

import introspectionResult from '../introspection-result';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
});

disableFragmentWarnings();

const DEBUG = __DEV__ && false;

export const createClient = async (): Promise<ApolloClient<
  NormalizedCacheObject
>> => {
  const cache = new InMemoryCache({ fragmentMatcher });

  const link = new HttpLink({
    uri: graphQLUrl(),
    fetch: (input: RequestInfo, init?: RequestInit) => {
      if (DEBUG) {
        const body = JSON.parse(init.body as string);
        console.log(
          `📡 ${body.operationName || ''}\n${body.query}\n`,
          body.variables,
          `\n⚙️ Headers ${JSON.stringify(init.headers, null, 2)}`
        );
      }

      return fetch(input, init);
    },
  });

  // https://medium.com/react-native-training/building-chatty-part-7-authentication-in-graphql-cd37770e5ab3
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let shouldLogout = false;

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        DEBUG && console.log({ message, locations, path });
        DEBUG && console.log(message);
        if (message === 'permission denied') {
          shouldLogout = true;
        }
      });

      if (shouldLogout) {
        DEBUG && console.log('should logout');
        AsyncStorage.removeItem(AUTH_TOKEN_KEY).then(() =>
          NavigationService.navigate('AuthLoading')
        );
      }
    }
    if (networkError) {
      DEBUG && console.log('[Network error]:');
      DEBUG && console.log({ networkError });
      if ((networkError as ServerError).statusCode === 401) {
        AsyncStorage.removeItem(AUTH_TOKEN_KEY).then(() =>
          NavigationService.navigate('AuthLoading')
        );
      }
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await AsyncStorage.getItem(AUTH_TOKEN_KEY);
    // return the headers to the context so httpLink can read them
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: token ? `JWT ${token}` : '',
        },
      };
    }

    return { headers };
  });

  const client = new ApolloClient({
    cache,
    link: from([authLink, errorLink, link]),
    resolvers,
    typeDefs,
  });

  const initData = {
    userSettings: {
      __typename: 'UserSettings',
      practiceReminder: {
        __typename: 'PracticeReminderSettings',
        enabled: null,
        date: null,
        notificationId: null,
      },
      chatNotifications: true,
    },
  };

  client.writeData({ data: initData });

  client.onClearStore(async () => cache.writeData({ data: initData }));

  return client;
};
