import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { rhythm, colors, fontSize, fonts } from '../designSystem';

interface SectionHeaderProps {
  children: string;
  accessory?: string;
}
export const SectionHeader: React.SFC<SectionHeaderProps> = ({
  children,
  accessory,
}) => (
  <View style={sectionHeaderStyles.container}>
    <Text style={sectionHeaderStyles.title}>{children}</Text>
    {!!accessory && (
      <Text style={sectionHeaderStyles.accessory}>{accessory}</Text>
    )}
  </View>
);

const sectionHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: rhythm[1],
  },
  title: {
    ...fonts.regular,
    fontSize: fontSize[2],
    color: colors.accentDarken,
  },
  accessory: {
    ...fonts.regular,
    fontSize: fontSize[1],
    color: colors.accentDarken,
    opacity: 0.7,
  },
});
