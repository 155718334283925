import { useState, useEffect } from 'react';
import * as Font from 'expo-font';

export const useFonts = (fonts: { [key: string]: string }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Font.loadAsync(fonts)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        console.error('error loading fonts');
        setLoading(false);
      });
  }, []);

  return loading;
};
