import React from 'react';
import { SafeAreaView, View, Text, StyleSheet, Platform } from 'react-native';
import { Button } from './Button';
import { fontSize, fonts, rhythm, colors } from '../designSystem';

interface InterstitialProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onPress?: () => void;
}

export const Interstitial: React.FC<InterstitialProps> = ({
  title,
  subtitle,
  buttonText,
  onPress,
}) => (
  <SafeAreaView style={styles.container}>
    <View style={styles.innerContainer}>
      {title && <Text style={styles.title}>{title}</Text>}
      {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
      {buttonText && <Button onPress={onPress} title={buttonText} />}
    </View>
  </SafeAreaView>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightGrey,
    ...(Platform.OS === 'web' && {
      paddingTop: rhythm[2],
    }),
  },
  innerContainer: {
    paddingHorizontal: rhythm[4],
  },
  title: {
    fontSize: fontSize[2],
    ...fonts.regular,
    marginBottom: rhythm[2],
    color: colors.darkGrey,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: fontSize[1],
    ...fonts.regular,
    marginBottom: rhythm[2],
    color: colors.darkGrey,
    textAlign: 'center',
  },
});
