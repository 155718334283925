//
// isEmail function from 'validator' source code to save space.
//

let emailUserUtf8Part = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;
let quotedEmailUserUtf8 = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
let defaultMaxEmailLength = 254;

function _isByteLength(str: string, min: number, max: number) {
  let len = encodeURI(str).split(/%..|./).length - 1;
  return len >= min && len <= max;
}

function _isFQDN(str: string): boolean {
  if (!str.length) {
    return false;
  }

  let parts = str.split('.');

  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 63) {
      return false;
    }
  }

  let tld = parts.pop();

  if (
    !parts.length ||
    !tld ||
    !/^([a-z\u00a1-\uffff]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)
  ) {
    return false;
  } // disallow spaces

  if (/[\s\u2002-\u200B\u202F\u205F\u3000\uFEFF\uDB40\uDC20]/.test(tld)) {
    return false;
  }

  for (let part, _i = 0; _i < parts.length; _i++) {
    part = parts[_i];

    if (!/^[a-z\u00a1-\uffff0-9-]+$/i.test(part)) {
      return false;
    } // disallow full-width chars

    if (/[\uff01-\uff5e]/.test(part)) {
      return false;
    }

    if (part[0] === '-' || part[part.length - 1] === '-') {
      return false;
    }
  }

  return true;
}

export function isEmail(str: string): boolean {
  if (str.length > defaultMaxEmailLength) {
    return false;
  }

  let parts = str.split('@');
  let domain = parts.pop();
  let user = parts.join('@');

  if (
    !user ||
    !domain ||
    !_isByteLength(user, 0, 64) ||
    !_isByteLength(domain, 0, 254)
  ) {
    return false;
  }

  if (!_isFQDN(domain)) {
    return false;
  }

  if (user[0] === '"') {
    user = user.slice(1, user.length - 1);
    return quotedEmailUserUtf8.test(user);
  }

  let pattern = emailUserUtf8Part;
  let user_parts = user.split('.');

  for (let _i = 0; _i < user_parts.length; _i++) {
    if (!pattern.test(user_parts[_i])) {
      return false;
    }
  }

  return true;
}
