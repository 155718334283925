import {
  useCreateMessageAttachmentThreadMutation,
  MessageAttachmentType,
  useStartMessageMutation,
  useCreateMessageAttachmentMutation,
  usePublishMessageMutation,
  useCompleteMessageAttachmentMutation,
  CreateMessageAttachmentThreadMutationResult,
} from './types';
import gql from 'graphql-tag';
import * as Sentry from 'sentry-expo';
import { trackError, trackEvent } from '../utils/analytics';

export const CREATE_ATTACHMENT_MESSAGE_THREAD = gql`
  mutation CreateMessageAttachmentThread(
    $relationshipId: Int!
    $subject: String = "Attachment"
    $exerciseProgressId: Int
  ) {
    createMessageThread(
      contents: "VideoThread"
      relationshipId: $relationshipId
      subject: $subject
      exerciseProgressId: $exerciseProgressId
    ) {
      messageThread {
        id
      }
    }
  }
`;

export const START_MESSAGE = gql`
  mutation StartMessage($messageThreadId: Int!, $exerciseId: Int) {
    startMessage(
      contents: ""
      messageThreadId: $messageThreadId
      exerciseId: $exerciseId
    ) {
      message {
        id
      }
    }
  }
`;
export const CREATE_MESSAGE_ATTACHMENT = gql`
  mutation CreateMessageAttachment($messageId: Int!) {
    createMessageAttachment(id: $messageId) {
      messageAttachment {
        id
        uploadData {
          url
          fields
        }
      }
    }
  }
`;

export const PUBLISH_MESSAGE = gql`
  mutation PublishMessage($messageId: Int!, $exerciseId: Int) {
    publishMessage(id: $messageId, exerciseId: $exerciseId) {
      ok
    }
  }
`;

export const COMPLETE_MESSAGE_ATTACHMENT = gql`
  mutation CompleteMessageAttachment($attachmentId: Int!) {
    completeMessageAttachment(id: $attachmentId) {
      ok
    }
  }
`;

export interface MessageAttachmentCreationResult {
  messageId: number;
  attachment: Pick<MessageAttachmentType, 'id' | 'uploadData'>;
}

type StartNewMessageMutationFunction = (_: {
  onCompleted: (result: MessageAttachmentCreationResult) => void;
}) => void;

interface CreateNewMessageAttachmentParams {
  messageThreadId: number;
  exerciseId?: number;
}

type UseCreateMessageAttachmentFunction = (
  _: CreateNewMessageAttachmentParams
) => StartNewMessageMutationFunction;

const createErrorCatcher = (action: string) =>
  function(err: any) {
    Sentry.captureException(`${action}: ${JSON.stringify(err)}`);
    console.error(`ERROR on ${action}:`, err);
  };

/**
 *
 * 1. create message thread (or use student/mentor main thread)
 * 2. startMessage (or more realistically, startMessage), using thread id
 * 3. createMessageAttachment, using messageId
 *  (at this point ziggeo requires a messageAttachmentId to render)
 * 4. completeMessageAttachment
 * 5. publishMessage (or completeMessage)
 */

export const useCreateMessageAttachmentOnExistingThread: UseCreateMessageAttachmentFunction = ({
  messageThreadId,
  exerciseId,
}) => {
  const [startMessage] = useStartMessageMutation({
    onError: createErrorCatcher('start message'),
  });

  const [createMessageAttachment] = useCreateMessageAttachmentMutation({
    onError: createErrorCatcher('create attachment'),
  });

  return ({ onCompleted }) =>
    startMessage({
      variables: {
        messageThreadId,
        exerciseId,
      },
    }).then(result => {
      // Got the message's id, now create an associated attachment
      if (
        !result ||
        !result.data ||
        !result.data.startMessage ||
        !result.data.startMessage.message
      ) {
        throw new Error('Expected well-formed new message');
      }
      const messageId = parseInt(result.data.startMessage.message.id);
      createMessageAttachment({
        variables: {
          messageId,
        },
      }).then(result => {
        // Attachment was created and we have its info, return all info to consumer
        if (
          !result ||
          !result.data ||
          !result.data.createMessageAttachment ||
          !result.data.createMessageAttachment.messageAttachment
        ) {
          throw new Error('Expected well-formed new message');
        }
        const attachment =
          result.data.createMessageAttachment.messageAttachment;
        onCompleted({
          attachment,
          messageId,
        });
      });
    });
};

export const useCreateNewMessageThread = () => {
  const [createMessageThread] = useCreateMessageAttachmentThreadMutation();
  return ({ relationshipId, subject, exerciseProgressId }) => {
    return new Promise<number>((resolve, reject) => {
      createMessageThread({
        variables: {
          relationshipId,
          subject,
          exerciseProgressId,
        },
      })
        .then(result => {
          if (
            !result ||
            !result.data ||
            !result.data.createMessageThread ||
            !result.data.createMessageThread.messageThread
          ) {
            throw new Error('Expected well-formed message thread result');
          }
          const messageThreadId = parseInt(
            result.data.createMessageThread.messageThread.id
          );
          resolve(messageThreadId);
        })
        .catch(err => trackError(err));
    });
  };
};

export interface FinalizeSubmissionOptions {
  options?: {
    isZiggeoSubmission?: boolean;
  };
}
/**
 * After update is complete, publish & complete the message, trigger notification, etc.
 */
export function useFinalizeSubmissionMutation({
  options = {},
}: FinalizeSubmissionOptions) {
  // Don't use the completeAttachment mutation for ziggeo submissions
  // since that is taken care of by ziggeo's hooks
  const doComplete = options && options.isZiggeoSubmission ? false : true;

  const [publishMessageMutation] = usePublishMessageMutation();
  const [completeAttachmentMutation] = useCompleteMessageAttachmentMutation();

  const publishMessage = (messageId: number) =>
    publishMessageMutation({
      variables: {
        messageId,
      },
      // refetchQueries: ['ChatThread'],
    });

  const completeAttachment = (attachmentId: number) =>
    completeAttachmentMutation({
      variables: {
        attachmentId,
      },
      // refetchQueries: ['ChatAllThreads'],
    });

  const finalizeSubmissionMutation = ({ messageId, attachmentId }) => {
    const promiseArray: Promise<any>[] = [publishMessage(messageId)];
    trackEvent('prep-publish-message-mutation', { messageId });
    if (doComplete) {
      trackEvent('prep-complete-attachment-mutation', { attachmentId });
      promiseArray.push(completeAttachment(attachmentId));
    }
    return Promise.all(promiseArray).catch(err => trackError(err));
  };

  return finalizeSubmissionMutation;
}
