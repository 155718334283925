import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { colors } from '../designSystem';

interface CloseButtonXProps {
  onPress: () => void;
  disabled?: boolean;
}

export const CloseButtonX = (props: CloseButtonXProps) => (
  <TouchableOpacity
    onPress={() => props.onPress()}
    disabled={props.disabled}
    hitSlop={{ top: 20, left: 20, bottom: 20, right: 20 }}
  >
    <Feather name="x" size={32} color={colors.white} />
  </TouchableOpacity>
);
