import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { FontAwesome, Feather } from '@expo/vector-icons';

import { Section } from './Section';
import { SectionHeader } from './SectionHeader';
import { ListCard } from './ListCard';
import { TechniqueListItem } from '../dataLoader';
import { LessonInfoFragment as Lesson } from '../graphql/types';
import { colors } from '../designSystem';
import { TechniqueCard } from './TechniqueCard';

export const isLessonClickable = (lesson: Lesson): boolean =>
  !!lesson.exercises &&
  (lesson.exercises.some(ex => ex && !!ex.lastProgress) ||
    lesson.exercises.every(ex => ex && ex.optional));

interface TechniquesListProps {
  techniques: TechniqueListItem[];
  restrictAccess?: boolean;
  onCompleteItem?: (lesson: Lesson) => void;
}

export const TechniquesList: React.FC<TechniquesListProps> = ({
  techniques,
  onCompleteItem,
  restrictAccess = false,
}) => {
  const { navigate } = useNavigation();
  return (
    <Section>
      <SectionHeader>Techniques</SectionHeader>
      {techniques.map(technique => {
        const disabled = restrictAccess && !isLessonClickable(technique.lesson);

        if (technique.type === 'lesson') {
          return (
            <TechniqueCard
              key={technique.lesson.id}
              item={technique}
              onPress={onCompleteItem}
              disabled={disabled}
            />
          );
        }

        if (technique.type === 'lessonIntro') {
          return (
            <TouchableOpacity
              key={technique.moduleTitle + ':' + technique.lessonGroupTitle}
              onPress={() => {
                navigate('LessonIntroScreen', {
                  lessonGroupVideos: JSON.stringify(technique.videos),
                });
              }}
            >
              <ListCard
                header={'INTRODUCTION'}
                title={
                  technique.moduleTitle + ': ' + technique.lessonGroupTitle
                }
                inverse
              >
                <FontAwesome
                  name="youtube-play"
                  size={24}
                  color={colors.white}
                />
              </ListCard>
            </TouchableOpacity>
          );
        }
      })}
    </Section>
  );
};
