import React, { useState } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
  Alert,
} from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { Loading } from '../Loading';
import { colors, rhythm } from '../../designSystem';
import { FontAwesome } from '@expo/vector-icons';
import { bubbleFontSize } from './RenderBubble';
import { trackFormattedEvent, Zone } from '../../utils/analytics';

const carouselHeight = 144;
const padding = rhythm[3];
const margin = 0;
const itemHeight = carouselHeight / 2 - margin;
const itemWidth = rhythm[5];

interface AdHocSubmissionButtonProps {
  customStyles?: StyleProp<ViewStyle>;
  threadSubject: string;
}
export const AdHocSubmissionButton: React.FC<AdHocSubmissionButtonProps> = ({
  customStyles,
  threadSubject,
}) => {
  const navigation = useNavigation();
  const createAdHocSubmissionThread = () => {
    // for direct (non-practice session) video submissions, e.g. when browsing

    trackFormattedEvent(Zone.ExerciseScreen, 'create-ad-hoc-submission');
    navigation.navigate('RecordVideoScreen', {
      threadSubject: threadSubject,
      exitScreen: 'ChatScreen',
      exerciseId: -1, // required to keep params explicit
    });
  };

  return (
    <TouchableOpacity
      style={[styles.carouselActionButton, customStyles || {}]}
      onPress={createAdHocSubmissionThread}
      disabled={false}
    >
      <FontAwesome
        name="plus"
        size={bubbleFontSize * 1.5}
        color={colors.white}
      />
    </TouchableOpacity>
  );
};

interface CarouselActionsProps {
  isAddThreadEnabled: boolean;
  threadSubject: string;
}
export const CarouselActions: React.FC<CarouselActionsProps> = ({
  isAddThreadEnabled,
  threadSubject,
}) => {
  const backgroundColor = colors.accent;
  return isAddThreadEnabled ? (
    <View style={styles.carouselActionsWrapper}>
      <AdHocSubmissionButton
        customStyles={{
          backgroundColor,
        }}
        threadSubject={threadSubject}
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  carouselActionsWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: itemWidth,
    padding,
    marginLeft: rhythm[2],
  },
  carouselActionButton: {
    backgroundColor: colors.accent,
    flex: 1,
    width: itemWidth,
    height: itemHeight,
    borderRadius: rhythm[1],
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: margin,
    marginRight: margin,
  },
});
