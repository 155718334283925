import React from 'react';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import { Linking } from 'expo';
import { rhythm, colors, fonts, fontSize } from '../designSystem';
import { trackEvent } from '../utils/analytics';

interface HighTicketExpiredUpsellProps {
  containerStyle?: ViewStyle;
}

export const HighTicketExpiredUpsell: React.FC<HighTicketExpiredUpsellProps> = ({
  containerStyle,
}) => (
  <View style={{ ...containerStyle, padding: rhythm[2] }}>
    <Text style={styles.subtitleText}>
      Your student-mentor relationship is expired, so chat and video submission
      are disabled. If you're interested in more mentorship, we have an advanced
      program.{' '}
      <Text
        onPress={() => {
          trackEvent('click-advanced-program-inquiry');
          Linking.openURL(
            'mailto:contact@guitarmasteryintensive.com?subject=Advanced program inquiry'
          );
        }}
        style={{
          ...styles.subtitleText,
          textDecorationLine: 'underline',
        }}
      >
        Send us an email to find out more!
      </Text>
    </Text>
  </View>
);

const styles = StyleSheet.create({
  subtitleText: {
    fontSize: fontSize[1],
    color: colors.white,
    marginBottom: rhythm[2],
    textAlign: 'center',
    ...fonts.regular,
  },
});
