import React from 'react';
import { Button } from './Button';

interface UploadButtonProps {
  onPress: () => void;
  disabled?: boolean;
}

export const UploadButton = (props: UploadButtonProps) => (
  <Button
    inverted={true}
    title={'Upload from Camera Roll'}
    onPress={() => props.onPress()}
    disabled={props.disabled}
  />
);
