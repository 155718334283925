import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  StatusBar,
  View,
  StyleSheet,
  Platform,
  SafeAreaView,
  ButtonProps,
} from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import gql from 'graphql-tag';
import { useKeepAwake } from 'expo-keep-awake';

import { ExerciseScreenStrings as S } from '../../strings';
import { byPlatform } from '../../utils/platform';
import { exerciseProgress, exerciseTitle } from '../../graphql/fragments';
import {
  useExerciseQuery,
  useReportProgressMutation,
} from '../../graphql/types';
import { Button } from '../../components/Button';
import { ErrorMessage, missingDataError } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { Soundslice, Ref as SoundsliceRef } from '../../components/Soundslice';
import { Fade } from '../../components/Fade';
import * as Sentry from 'sentry-expo';
import { isRelationshipActive } from '../../utils/dateUtils';
import { useIsLessonBrowser } from '../../hooks/useIsLessonBrowser';
import { trackFormattedEvent, Zone, trackError } from '../../utils/analytics';
import { resolveExerciseTitle } from '../../graphql/resolvers';
import { booleanParam } from '../../utils/navigationUtils';
import { usePortraitLockExceptIpad } from '../../hooks/useMobileOrientationLock';
import { colors, rhythm } from '../../designSystem';
import { Metronome, MetronomeButton } from '../../components/Metronome';
import { WebView as WebViewMobile } from 'react-native-webview';
import { default as WebViewWeb } from 'react-native-web-webview';
import { useOnBlur } from '../../hooks/useOnBlur';

export const EXERCISE_QUERY = gql`
  query Exercise($exerciseId: Int!) {
    lastSegment {
      id
    }
    studentMentorRelationships {
      id
      endedAt
    }
    exercise(id: $exerciseId) {
      id
      level
      ...ExerciseTitle
      soundsliceEmbedUrl
      lastProgress {
        id
        ...ExerciseProgress
      }
    }
  }
  ${exerciseTitle}
  ${exerciseProgress}
`;

export const ExerciseScreen: React.FC = () => {
  const navigation = useNavigation();
  useKeepAwake();
  usePortraitLockExceptIpad({
    debugId: 'ExerciseScreen',
    onlyWhenFocused: true,
    isFocused: navigation.isFocused(),
  });

  const exerciseId = navigation.getParam('exerciseId', null);
  const isReviewExerciseFlow = booleanParam(
    navigation.getParam('isReviewExerciseFlow', false)
  );

  const isLessonBrowser = useIsLessonBrowser();

  const [
    reportProgress,
    { loading: reportProgressLoading },
  ] = useReportProgressMutation({
    refetchQueries: ['LastSegment'],
    awaitRefetchQueries: true,
  });

  const { data, loading, error } = useExerciseQuery({
    variables: {
      exerciseId,
    },
  });
  const [destroyMetronome, setDestroyMetronome] = useState(false);
  /**
   * Make sure metronome is "un destroyed" and available when starting up
   */
  useEffect(() => {
    if (navigation.isFocused()) setDestroyMetronome(false);
  }, [navigation.isFocused()]);
  const [showMetronome, setShowMetronome] = useState(false);
  const toggleMetronome = () => setShowMetronome(curr => !curr);
  const [soundsliceLoading, setSoundsliceLoading] = useState(true);

  const soundslicePlayer = useRef<SoundsliceRef>(null);

  useOnBlur(() => stopAudioVideo());
  const onSoundsliceLoad = useCallback(e => {
    setSoundsliceLoading(false);
  }, []);
  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;
  if (!data || !data.exercise || !data.studentMentorRelationships)
    return <ErrorMessage error={missingDataError('Exercise Screen')} />;

  const { exercise, lastSegment, studentMentorRelationships } = data;

  const stopAudioVideo = () => {
    if (soundslicePlayer && soundslicePlayer.current) {
      const js = { method: 'pause' };

      try {
        soundslicePlayer.current.postMessage(JSON.stringify(js));
      } catch (err) {
        console.log('error', err);

        Sentry.captureException(err);
      }
    }
    setDestroyMetronome(true);
  };

  const onComplete = () => {
    stopAudioVideo();
    const navigationParams = {
      exerciseId: exercise.id,
      isReviewExerciseFlow,
    };
    trackFormattedEvent(Zone.ExerciseScreen, 'complete-item', navigationParams);
    navigation.navigate('ExerciseRatingScreen', navigationParams);
  };
  const onReviewed = () => {
    if (!lastSegment)
      throw new Error('review should only happen on a segment!');
    stopAudioVideo();
    const progressVariables = {
      exerciseId: parseInt(exercise.id),
      segmentId: parseInt(lastSegment.id),
    };

    const preReportTime = new Date().getTime();
    reportProgress({
      variables: progressVariables,
    })
      .then(_ => {
        // console.log('after report', new Date().getTime());
        const executionTime = new Date().getTime() - preReportTime;
        trackFormattedEvent(Zone.ExerciseScreen, 'mark-reviewed', {
          variables: progressVariables,
          executionTime,
        });
        navigation.navigate('PracticeSessionScreen');
      })

      .catch(err => {
        trackError(err);
        navigation.navigate('PracticeSessionScreen');
      });
  };
  const directSubmitVideo = () => {
    stopAudioVideo();
    // for direct (non-practice session) video submissions, e.g. when browsing
    trackFormattedEvent(Zone.ExerciseScreen, 'submit-video');

    navigation.navigate('RecordVideoScreen', {
      threadSubject: resolveExerciseTitle(data.exercise),
      exerciseId,
      exitScreen: isLessonBrowser ? 'LessonsScreen' : 'PracticeSessionScreen',
    });
  };
  const canSubmitVideo =
    studentMentorRelationships &&
    studentMentorRelationships[0] &&
    isRelationshipActive(studentMentorRelationships[0]);

  const ActionButtonsContainer: React.FC = ({ children }) =>
    Platform.OS === 'web' ? (
      <View style={styles.buttonsContainer}>
        <View style={{ flexDirection: 'row' }}>{children}</View>
      </View>
    ) : (
      <Fade padded>
        <View style={{ flexDirection: 'row' }}>{children}</View>
      </Fade>
    );

  const PracticeSessionActionButtons: React.FC = () => (
    <ActionButtonsContainer>
      <MetronomeButton onPress={toggleMetronome} disabled={soundsliceLoading} />
      <Button
        disabled={soundsliceLoading || reportProgressLoading}
        onPress={isReviewExerciseFlow ? onReviewed : onComplete}
        style={{ flex: 1 }}
        title={
          isReviewExerciseFlow ? S.Button.reviewFlow : S.Button.techniqueFlow
        }
      />
    </ActionButtonsContainer>
  );

  const LessonBrowserActionButton: React.FC = () =>
    canSubmitVideo ? (
      <ActionButtonsContainer>
        <MetronomeButton
          onPress={toggleMetronome}
          disabled={soundsliceLoading}
        />
        <Button
          disabled={soundsliceLoading || reportProgressLoading}
          onPress={directSubmitVideo}
          style={{ flex: 1 }}
          title={'Submit a New Video'}
        />
      </ActionButtonsContainer>
    ) : null;

  const ActionButtons = isLessonBrowser
    ? LessonBrowserActionButton
    : PracticeSessionActionButtons;

  // MetronomeButton height + top/bottom margin
  const metronomeOffsetHeight = rhythm[5] + rhythm[2] * 2;

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar hidden />
      {!destroyMetronome && (
        <Metronome
          doShow={showMetronome}
          bottomOffset={metronomeOffsetHeight}
          leftOffset={rhythm[2]}
        />
      )}
      <Soundslice
        ref={soundslicePlayer}
        url={exercise.soundsliceEmbedUrl}
        onLoad={onSoundsliceLoad}
      />
      <ActionButtons />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: byPlatform({
    web: {
      flex: 1,
    },
    mobile: {
      flex: 1,
      justifyContent: 'space-between',
    },
  }),
  buttonsContainer: {
    marginTop: -30,
    marginHorizontal: rhythm[2],
    marginBottom: rhythm[2],
  },
});
