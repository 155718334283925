import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { rhythm, colors } from '../../designSystem';
import { bubbleFontSize } from './RenderBubble';

interface RecordVideoButtonProps {
  onPress: () => void;
}

export const RecordVideoButton: React.FC<RecordVideoButtonProps> = props => {
  return (
    <View style={{ alignSelf: 'center', paddingLeft: rhythm[1] }}>
      <TouchableOpacity
        onPress={props.onPress}
        hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
      >
        <FontAwesome
          name="camera"
          size={bubbleFontSize * 1.25}
          color={colors.defaultBlue}
        />
      </TouchableOpacity>
    </View>
  );
};
